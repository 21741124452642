/*
* Mixin to generate unique key for looped components
* 1. Import the mixin and add to the component
* 2. To be used over index because indecies will be shuffled and cause issues
* 3. Use uniqueKey_generate and pass the interable value to the method
  i.e.  v-for="dog in dogs"
        :key="uniqueKey_generate(dog)"
*/

const UNIQUE_KEY_PROP = '__unique_key_prop__';
const KEY_PREFIX = `__key_prefix__${Date.now()}_`;
let uid = 0;

export default {
    methods: {
        uniqueKey_generate(obj) {
            if (obj !== null && typeof obj === 'object') {
                if (UNIQUE_KEY_PROP in obj) {
                    return obj[UNIQUE_KEY_PROP];
                }

                const value = KEY_PREFIX + uid++;

                Object.defineProperty(obj, UNIQUE_KEY_PROP, { value });

                return value;
            }

            return obj;
        },
    },
};

<template>
    <div>
        <Radio
            v-for="option in options"
            :key="option[valueProp]"
            :model-value="modelValue"
            :val="option[valueProp]"
            :label="option[labelProp]"
            :error="option.error"
            :disabled="option.disabled"
            :required="required"
            :name="name"
            @update:model-value="$emit('update:modelValue', option[valueProp])"
        />
    </div>
</template>

<script>
import Radio from './Radio.vue';

export default {
    name: 'DsRadioGroup',

    compatConfig: { MODE: 3 },

    components: {
        Radio,
    },

    props: {
        /**
         * Same as radio value
         */
        modelValue: [String, Number, Object],

        /**
         * Required value for validation
         */
        required: Boolean,

        /**
         * Name value for validation
         */
        name: String,

        /**
         * A list of options to render
         */
        options: {
            type: Array,
            required: true,
        },

        /**
         * Value key if not default
         */
        valueProp: {
            type: String,
            default: 'value',
        },

        /**
         * Label key if not default
         */
        labelProp: {
            type: String,
            default: 'label',
        },
    },

    emits: ['update:modelValue'],
};
</script>

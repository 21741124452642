<template>
    <RouterLink
        v-slot="{ href, navigate, isActive, isExactActive }"
        :to="to"
        custom
    >
        <TabHeaderItem
            v-bind="$attrs"
            :active="exact ? isExactActive : isActive"
            @click="navigate"
        >
            <a :href="href" class="tab-header-link-link">
                <slot>
                    <Icon v-if="icon" :name="icon" />

                    {{ label }}
                </slot>
            </a>
        </TabHeaderItem>
    </RouterLink>
</template>

<script>
import TabHeaderItem from './TabHeaderItem.vue';
import { Icon } from '../Icon';

export default {
    name: 'DsTabHeaderLink',

    components: {
        TabHeaderItem,
        Icon,
    },

    inheritAttrs: false,

    props: {
        to: [String, Object],

        exact: Boolean,

        icon: String,

        label: String,
    },
};
</script>

<style lang="scss" scoped>
@import "../../styles/common";

.tab-header-link-link {
    display: flex;
    align-items: center;
    gap: $spacing-100;
    color: inherit;

    &:hover {
        text-decoration: none;
    }
}
</style>

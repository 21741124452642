<template>
    <component
        :is="as"
        class="list-item"
        :class="[`border-${borderStyle}`, { clickable: isClickable }]"
    >
        <div v-if="$slots.leading" class="leading-slot">
            <!-- @slot Leading slot for icon or avatar to display before the list item text. -->
            <slot name="leading" />
        </div>

        <div class="list-item-info">
            <div class="item-text">
                <div v-if="title || $slots.title" class="title one-line">
                    <slot name="title">
                        {{ title }}
                    </slot>
                </div>

                <div
                    v-if="description || $slots.description"
                    class="description"
                    :class="[{ 'one-line': hasShortDescription }]"
                >
                    <slot name="description">
                        {{ description }}
                    </slot>
                </div>

                <div v-if="hasShortDescription" class="description one-line">
                    <slot name="shortDescription">
                        {{ shortDescription }}
                    </slot>
                </div>
            </div>

            <div v-if="$slots.trailing" class="trailing-slot">
                <!-- @slot Checkbox or other to display after list item text. -->
                <slot name="trailing" />
            </div>
        </div>
    </component>
</template>

<script>
export default {
    name: 'DsListItem',

    compatConfig: { MODE: 3 },

    props: {
        /**
         * Root element type
         */
        as: {
            type: String,
            default: 'div',
        },

        /**
         * Main text to show on the list item
         */
        title: {
            type: String,
            default: '',
        },

        /**
         * Description text to display. This will truncate after two lines (or one line if shortDescription is used).
         */
        description: {
            type: String,
            default: '',
        },

        /**
         * Another line of description text. This will truncate to one line.
         */
        shortDescription: {
            type: String,
            default: '',
        },

        /**
         * The style in which to display the border below the list item.
         */
        borderStyle: {
            type: String,
            default: 'partial',
            options: ['none', 'partial', 'full'],
        },

        /**
         * Show interactive hover state
         */
        clickable: Boolean,
    },

    computed: {
        isClickable() {
            return this.clickable || this.as === 'a';
        },

        hasShortDescription() {
            return !!this.shortDescription || !!this.$slots.shortDescription;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .list-item {
        --checkbox-padding: 0;
        --checkbox-align-items: flex-start;
        --icon-color: #{$color-ink};

        display: flex;
        align-items: stretch;
        padding: var(--list-item-padding, #{$spacing-200});
        width: 100%;
        background-color: transparent;

        &.clickable {
            cursor: pointer;
            text-decoration: none;

            &:hover {
                background-color: $color-gray-050;
            }
        }
    }

    .leading-slot {
        @include padding-end($spacing-200);

        min-width: px-to-rem(56px);
    }

    .item-text {
        overflow: hidden;
    }

    .list-item-info {
        display: flex;
        align-items: center;
        flex: 1;
        min-width: 0;
        justify-content: space-between;
    }

    .border-full {
        border-bottom: solid 1px $color-ink-200;
    }

    .border-partial {
        @include padding-end(0, true);

        padding-bottom: 0;

        .leading-slot {
            padding-bottom: var(--list-item-padding, #{$spacing-200});
        }

        .list-item-info {
            @include padding-end(var(--list-item-padding, #{$spacing-200}));

            padding-bottom: var(--list-item-padding, #{$spacing-200});
            border-bottom: solid 1px $color-ink-200;
        }
    }

    .title {
        color: var(--list-item-title-color, #{$color-ink});
        font-size: $font-size-md;

        &:not(:only-child) {
            margin-bottom: $spacing-050;
        }
    }

    .description {
        @include ellipsis-multiline(2);
        color: var(--list-item-description-color, #{$color-gray});
        font-size: $font-size-sm;
        margin: 0;
        line-height: 1.5;
    }

    .one-line {
        @include ellipsis;
        display: block;
    }

    .trailing-slot {
        @include margin-start($spacing-200);
        height: 100%;
    }
</style>

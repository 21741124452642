<template>
    <div :class="['chip', { 'with-interaction': withInteraction }]">
        <div v-if="$slots.leading" class="leading-slot">
            <!-- @slot Leading content to display before the main text -->
            <slot name="leading" />
        </div>

        <div class="text">
            <!-- @slot Main text to display in the chip -->
            <slot name="default" />
        </div>

        <div v-if="$slots.trailing" class="trailing-slot">
            <!-- @slot Trailing content to display after the main text. -->
            <slot name="trailing" />
        </div>

        <div v-else-if="remove" class="remove-icon">
            <Icon name="x-circle-fill" />
        </div>
    </div>
</template>

<script>
import { Icon } from '../Icon';

export default {
    name: 'DsChip',

    compatConfig: { MODE: 3 },

    components: {
        Icon,
    },

    props: {
        /**
         * Shows an x icon within the chip.
         */
        remove: Boolean,

        /**
         * Adds interaction effects, eg. hover, pressed, etc.
         */
        withInteraction: Boolean,
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    $default-chip-background-color: $color-ink-100;
    $default-chip-text-color: $color-text-normal;
    $chip-border-radius: $border-radius * 3;

    .chip {
        @include transition(all);

        height: px-to-rem(24px);
        max-width: 100%;
        display: inline-flex;
        align-items: center;
        font-size: $font-size-xs;
        border-radius: $chip-border-radius;
        padding: 0 $spacing-050;
        background-color: var(--chip-background-color, $default-chip-background-color);
        color: var(--chip-text-color, $default-chip-text-color);
        overflow: hidden;
        position: relative;
    }

    .with-interaction {
        cursor: pointer;

        &:before {
            @include transition(opacity);

            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.1);
            opacity: 0;
            border-radius: $chip-border-radius;
        }

        &:hover {
            &:before {
                opacity: 1;
            }
        }

        &:active {
            box-shadow: $elevation-z2;

            &:before {
                opacity: 0;
            }
        }
    }

    .outline {
        background-color: transparent;
        border: solid 1px $color-gray-400;
    }

    .text {
        @include ellipsis;
        @include prevent-select;
        text-overflow: var(--text-overflow, ellipsis);

        line-height: 1.5;
        margin: 0 $spacing-050;
        z-index: 1;
    }

    .leading-slot {
        @include margin-end($spacing-050);

        --icon-size: #{px-to-rem(16px)};

        line-height: 0;
        z-index: 1;
    }

    .trailing-slot,
    .remove-icon {
        @include margin-start($spacing-050);

        line-height: 0;
        z-index: 1;
    }

    .remove-icon {
        --icon-size: #{px-to-rem(12px)};
        --icon-color: var(--chip-text-color, #{$color-ink-600});

        &:hover, &:focus, &:active {
            --icon-color: var(--chip-text-color, #{$color-ink-800});
        }
    }
</style>

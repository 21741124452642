<template>
    <TabHeader class="tab-links">
        <TabHeaderLink
            v-for="tab in tabs"
            :key="tab.link"
            :to="tab.link"
            :label="tab.label"
            :icon="tab.icon"
            :data-qa="tab.label"
            :exact="exact"
        />
    </TabHeader>
</template>

<script>
import mitt from 'mitt';
import TabHeader from './TabHeader.vue';
import TabHeaderLink from './TabHeaderLink.vue';

export default {
    name: 'DsTabLinks',

    compatConfig: { MODE: 3 },

    components: {
        TabHeader,
        TabHeaderLink,
    },

    provide() {
        return { emitter: this.emitter };
    },

    props: {
        /**
         * List of tabs to render. Tabs contain { link, label, exact } strings.
         */
        tabs: Array,

        /**
         * Set to true to exclusively match for active class.
         */
        exact: Boolean,
    },

    watch: {
        tabs: {
            handler() {
                this.emitter.emit('TAB_HEADER_TABS_UPDATED');
            },
            deep: true,
        },
    },

    beforeCreate() {
        this.emitter = mitt();
    },

    beforeUnmount() {
        this.emitter.off('*');
    },
};
</script>

<style lang="scss" scoped>
    .tab-links-link {
        color: inherit;

        &:hover {
            text-decoration: none;
        }
    }
</style>

<template>
    <div class="pulse-graphic">
        <div class="animated-pulse" />
    </div>
</template>

<script>
export default {
    name: 'DsPulseGraphic',

    compatConfig: { MODE: 3 },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    @keyframes pulseAnim {
        0% {
            opacity: 0;
            border-radius: px-to-rem(10px);
            width: px-to-rem(20px);
            height: px-to-rem(20px);
        }

        25% {
            border-radius: px-to-rem(6px);
            width: px-to-rem(12px);
            height: px-to-rem(12px);
            opacity: 1;
        }

        50% {
            border-radius: px-to-rem(32px);
            width: px-to-rem(56px);
            height: px-to-rem(56px);
            opacity: 0;
        }

        100% {
            opacity: 0;
        }
    }

    .pulse-graphic {
        position: absolute;
        z-index: 1;
        pointer-events: none;
    }

    .animated-pulse {
        position: absolute;
        left: 0px;
        top: 0px;
        transform: translate(-50%, -50%);
        @include animation('pulseAnim');
        animation-duration: 2s;
        animation-iteration-count: infinite;
        background-color: var(--pulse-color, #{$color-emerald});
    }
</style>

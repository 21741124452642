function hexToRgb(hex) {
    hex = hex.replace(/#/, '');
    hex = hex.length === 3 ? hex + hex : hex;

    const rgb = [];

    for (let i = 0; i < hex.length; i += 2) {
        rgb.push(parseInt(hex.substr(i, 2), 16));
    }

    const [r, g, b] = rgb;

    return { r, g, b };
}

function hexToHsl(hex) {
    let { r, g, b } = hexToRgb(hex);

    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = (max + min) / 2;
    let s = (max + min) / 2;
    const l = (max + min) / 2;

    if (max === min) {
        h = 0;
        s = 0;
    } else {
        const d = max - min;

        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max) {
        case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
        case g:
            h = (b - r) / d + 2;
            break;
        case b:
            h = (r - g) / d + 4;
            break;
        default:
            break;
        }

        h /= 6;
    }

    return {
        h: Math.round(h * 360),
        s: Math.round(s * 100),
        l: Math.round(l * 100),
    };
}

function hslToHex(h, s, l) {
    let r = 0;
    let g = 0;
    let b = 0;

    h = parseFloat(h) / 360;
    s = parseFloat(s) / 100;
    l = parseFloat(l) / 100;

    if (s === 0) {
        r = l;
        g = l;
        b = l;
    } else {
        const hue2rgb = (p, q, t) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;

            return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;

        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    const rgb = [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];

    const componentToHex = (c) => {
        const hex = c.toString(16);

        return hex.length === 1 ? `0${hex}` : hex;
    };

    return `#${componentToHex(rgb[0])}${componentToHex(rgb[1])}${componentToHex(rgb[2])}`;
}

export {
    hexToRgb,
    hexToHsl,
    hslToHex,
};

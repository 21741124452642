<template>
    <component
        :is="as"
        :type="buttonType"
        class="button ds-button"
        :class="classes"
        :disabled="disabled || loading ? true : undefined"
    >
        <Spinner v-if="loading" class="button-loading-spinner" />

        <div v-if="hasLeading" class="leading">
            <!-- @slot Leading content such as an Icon. -->
            <slot name="leading">
                <Icon :name="leadingIcon" />
            </slot>
        </div>

        <div v-if="hasLeading || hasTrailing || loading">
            <!-- @slot Label content of the button. -->
            <slot />
        </div>

        <slot v-else />

        <div v-if="hasTrailing" class="trailing">
            <!-- @slot Trailing content such as an Icon. -->
            <slot name="trailing">
                <Icon :name="trailingIcon" />
            </slot>
        </div>
    </component>
</template>

<script>
import { Icon } from '../Icon';
import { Spinner } from '../Spinner';

/**
 * The Button component serves as the core for other button components such as the FilledButton, OutlineButton and TextButton.  It encapsulates the different behaviors, semantics and the styling variations of the buttons.
 */
export default {
    name: 'DsButton',

    compatConfig: { MODE: 3 },

    components: {
        Icon,
        Spinner,
    },

    props: {
        /**
         * Name of the element type to render the Button as.  Defaults to a button element, but can be used to render an anchor for links that look like buttons.
         */
        as: {
            type: String,
            default: 'button',
        },

        /**
         * The type attribute used by the rendered button, i.e. button, submit, reset.  Defaults to button.  This will not be used if the `as` prop is overriden.
         */
        type: String,

        /**
         * The button variant.  This will be used to determine the styling of the button.  Defaults to 'filled'.
         */
        variant: {
            type: String,
            default: 'filled',
            validator: (variant) => ['filled', 'outline', 'text', 'icon', 'custom'].includes(variant),
        },

        /**
         * Styles as a gray variant of the text/filled button.
         */
        gray: Boolean,

        /**
         * Styles as a destructive variant.
         */
        destructive: Boolean,

        /**
         * Styles as a dense variant.
         */
        dense: Boolean,

        /**
         * Styles as a block variant that horizontally fills available container space.
         */
        block: Boolean,

        /**
         * Renders as a loading indicator instead of text.
         */
        loading: Boolean,

        /**
         * Disables the button.
         */
        disabled: Boolean,

        /**
         * Name of the icon to render in the leading slot.
         */
        leadingIcon: String,

        /**
         * Name of the icon to render in the trailing slot.
         */
        trailingIcon: String,
    },

    computed: {
        classes() {
            const {
                variant,
                destructive,
                dense,
                block,
                gray,
                loading,
                hasLeading,
                hasTrailing,
            } = this;

            return {
                'ds-button--filled': variant === 'filled',
                outline: variant === 'outline',
                'ds-button--outline': variant === 'outline',
                'text-button': variant === 'text',
                'ds-button--text': variant === 'text',
                'ds-button--icon': variant === 'icon',
                negative: destructive,
                'ds-button--destructive': destructive,
                gray,
                'ds-button--gray': gray,
                dense,
                'ds-button--dense': dense,
                block,
                'ds-button--block': block,
                'with-leading': hasLeading,
                'with-trailing': hasTrailing,
                loading,
            };
        },

        buttonType() {
            if (this.as !== 'button') {
                return null;
            }

            if (this.type === undefined) {
                return 'button';
            }

            return this.type || null;
        },

        hasLeading() {
            return this.$slots.leading || this.leadingIcon;
        },

        hasTrailing() {
            return this.$slots.trailing || this.trailingIcon;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .ds-button.with-leading,
    .ds-button.with-trailing {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .ds-button.with-leading {
        @include padding-start(.6875rem);
    }

    .ds-button.with-trailing {
        @include padding-end(.6875rem);
    }

    .leading,
    .trailing {
        --icon-size: 1rem;
        width: var(--icon-size);
        height: var(--icon-size);
    }

    .leading {
        @include margin-end(.375rem);
    }

    .trailing {
        @include margin-start(.375rem);
    }

    .loading {
        position: relative;
    }

    .loading > *:not(.button-loading-spinner) {
        opacity: 0;
    }

    $spinner-size: px-to-rem(26px);

    .button-loading-spinner {
        --spinner-color: var(--button-spinner-color, var(--primary-color, #{$color-blue}));
        --spinner-size: #{$spinner-size};
        position: absolute;
        left: calc(50% - $spinner-size / 2);
        top: calc(50% - $spinner-size / 2);
    }
</style>

<template>
    <Editable
        :is-editing="isEditing"
        class="editable"
        :disabled="disabled"
        :icon-edit="iconEdit"
        :always-show-icon="alwaysShowIcon"
        :has-placeholder="hasPlaceholder"
        :hide-icon="hideIcon"
        :text="text"
        @save="save"
        @cancel="cancel"
        @edit="edit"
        @try-edit="tryEdit"
    >
        <template #dimensions>
            <span
                v-show="!hideDimensions"
                :style="styleObject"
            >
                <span ref="dimensions" class="dimensions invisible">{{ text || placeholder }}</span>
                <span ref="pipeSpace" :style="styleObject">| |</span>
                <span ref="pipe" :style="styleObject">|</span>
            </span>
        </template>

        <template #not-editing>
            <span
                ref="slotContainer"
                tabindex="0"
                class="not-editing-wrapper"
                :class="{ 'icon-edit': iconEdit && !hasPlaceholder, invalid }"
                @focus="handleFocus"
            >
                <slot />

                <span
                    v-if="hasPlaceholder"
                    class="placeholder"
                    :style="[styleObject, { fontWeight: '400' }]"
                >
                    {{ placeholder }}
                </span>
            </span>
        </template>

        <template #editing>
            <input
                ref="input"
                v-model="text"
                :name="name"
                type="text"
                :placeholder="text || placeholder"
                :maxlength="maxlength"
                :pattern="computedPattern"
                :style="[styleObject, hasPlaceholder ? { fontWeight: '400' } : {}]"
                @keyup.enter="save"
                @keyup.esc="cancel"
                @change="onChange"
            />
        </template>
    </Editable>
</template>

<script>
import dom from '../../mixins/dom';
import Editable from './Editable.vue';

export default {
    name: 'DsEditableText',

    compatConfig: { MODE: 3 },

    components: {
        Editable,
    },

    mixins: [dom],

    props: {
        disabled: Boolean,
        hideIcon: {
            type: Boolean,
            default: false,
        },
        minlength: {
            type: Number,
            default: 0,
        },
        maxlength: {
            type: Number,
            default: 232,
        },
        name: String,
        modelValue: [String, Number],
        iconEdit: Boolean,
        alwaysShowIcon: Boolean,
        placeholder: String,
        nullable: Boolean,
        pattern: String,
        submitted: Boolean,
    },

    emits: [
        'cancel',
        'edit',
        'update:modelValue',
    ],

    data() {
        return {
            isEditing: false,
            text: null,
            previousText: null,
            hideDimensions: true,
            trailingAndLeadingSpaces: 0,
            invalid: false,
            fontSize: '',
            fontWeight: '',
            fontFamily: '',
            height: '0px',
            width: '0px',
        };
    },

    computed: {
        hasPlaceholder() {
            return Boolean(this.placeholder && !this.text);
        },

        computedPattern() {
            let pattern = this.pattern || '.*';

            if (this.minlength > 0) {
                pattern = `.{${this.minlength},}`;
            }

            return pattern;
        },

        styleObject() {
            return {
                fontSize: this.fontSize,
                fontWeight: this.fontWeight,
                fontFamily: this.fontFamily,
                height: this.height,
                width: this.width,
            };
        },

        spaceWidth() {
            const { width: pipeSpaceWidth } = this.dom_getElementWidthAndHeight(this.$refs.pipeSpace);
            const { width: pipeWidth } = this.dom_getElementWidthAndHeight(this.$refs.pipe);

            return pipeSpaceWidth - 2 * pipeWidth + 1;
        },
    },

    watch: {
        modelValue(newValue) {
            this.text = newValue;
            this.previousText = newValue;
            this.checkInvalid();
        },

        text: {
            handler() {
                this.setDimensions();
            },
            immediate: true,
        },

        submitted() {
            this.checkInvalid();
        },
    },

    created() {
        this.text = this.modelValue;
        this.previousText = this.modelValue;
    },

    mounted() {
        const { fontSize, fontWeight, fontFamily } = window.getComputedStyle(this.$refs.slotContainer.children[0]);

        this.fontSize = fontSize;
        this.fontWeight = fontWeight;
        this.fontFamily = fontFamily;

        this.$nextTick(this.setDimensions);
    },

    methods: {
        onChange({ target }) {
            if (target && target.value) {
                this.text = target.value;
                this.save();
            }
        },

        handleFocus() {
            this.tryEdit();
        },

        selectText() {
            const textLength = this.text ? this.text.length : 0;

            this.$nextTick(() => {
                if (this.$refs.input.setSelectionRange) {
                    this.$refs.input.setSelectionRange(0, textLength);
                }
            });
        },

        cancel() {
            this.$emit('cancel');
            this.text = this.previousText;
            this.isEditing = false;
        },

        tryEdit() {
            const empty = !this.text || this.text.length === 0;

            if (!this.iconEdit && !this.disabled || (this.iconEdit && this.hasPlaceholder && empty)) {
                this.edit();
            }
        },

        edit() {
            this.$emit('edit');
            this.openEditable();
        },

        openEditable() {
            this.setDimensions();
            this.isEditing = true;

            this.$nextTick(() => {
                this.$refs.input.focus();
                this.selectText();
            });
        },

        save() {
            if (this.text || this.nullable) {
                this.previousText = this.text;
                this.$emit('update:modelValue', this.text);
            }

            this.cancel();
        },

        setDimensions() {
            const dimensionElement = this.$refs.dimensions;

            if (!dimensionElement) {
                return;
            }

            this.hideDimensions = false;

            this.$nextTick(() => {
                const { width, height } = this.dom_getElementWidthAndHeight(dimensionElement);

                this.hideDimensions = true;

                const trailingAndLeadingSpaces = dimensionElement.textContent.replace(/\S+.+\S/ig, '').length;
                const widthOfSpaces = trailingAndLeadingSpaces * this.spaceWidth;

                this.height = height === 0 ? 'auto' : `${height}px`;
                this.width = `${width + widthOfSpaces + 24}px`;
            });
        },

        checkInvalid() {
            this.invalid = this.submitted && !this.$refs.input.checkValidity();
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .editable {
        overflow: hidden;
        --editable-max-width: var(--editable-text-max-width, 100%);
        --editable-min-width: var(--editable-text-min-width, auto);
    }

    .input-container {
        max-width: 100%;
    }

    input {
        border: 0;
        background-color: transparent;
        padding: 0;
        margin: 0;
        flex: 1;
        max-width: 100%;

        @include placeholder {
            color: $color-text-disabled;
        }
    }

    .invisible {
        position: absolute;
    }

    .dimensions {
        @include ellipsis;
    }

    .not-editing-wrapper {
        @include prevent-select;
        @include ellipsis;

        min-width: 0;
        flex: 1;

        &.invalid {
            border-bottom: 1px solid $color-red;
        }
    }

    .icon-edit {
        &:hover {
            cursor: default;
        }
    }

    .placeholder {
        color: $color-text-disabled;
        font-weight: 400;
    }
</style>

<style lang="scss">
    @import "../../styles/common";

    .not-editing-wrapper > * {
        @include ellipsis;
    }
</style>

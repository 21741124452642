<template>
    <transition name="fade" @after-leave="$emit('after-leave')">
        <div
            v-show="active"
            :class="['toast', { 'has-action': action, stacked: hasLongActionText }]"
            @click="close"
        >
            <div>
                <div v-if="headline" class="toast-headline">
                    {{ headline }}
                </div>
                {{ message }}
            </div>

            <a v-if="action" class="toast-action" @click="action">{{ actionText }}</a>
        </div>
    </transition>
</template>

<script>
const DEFAULT_TIMEOUT = 5000;
const LONG_ACTION_TEXT_CHAR_COUNT = 14;

export default {
    name: 'DsToast',

    compatConfig: { MODE: 3 },

    props: {
        actionText: {
            type: String,
            default: '',
        },

        headline: {
            type: String,
            default: '',
        },

        message: {
            type: String,
            default: '',
        },

        action: Function,
    },

    emits: ['after-leave'],

    data() {
        return {
            active: false,
            timeout: DEFAULT_TIMEOUT,
            timer: null,
        };
    },

    computed: {
        calculatedTimeout() {
            let { timeout } = this;

            if (timeout === DEFAULT_TIMEOUT) {
                if (this.action) {
                    timeout += 3000;
                }

                if (this.headline) {
                    timeout += 2000;
                }
            }

            return timeout;
        },

        hasLongActionText() {
            return this.action && this.actionText.length > LONG_ACTION_TEXT_CHAR_COUNT;
        },
    },

    mounted() {
        this.active = true;

        this.timer = setTimeout(() => {
            this.close();
        }, this.calculatedTimeout);
    },

    beforeUnmount() {
        clearTimeout(this.timer);
    },

    methods: {
        close() {
            this.active = false;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .toast {
        @include prevent-select;
        display: grid;
        box-shadow: $toast-box-shadow;
        background-color: var(--toast-background, #{$toast-background-color});
        max-width: var(--toast-max-width, #{$toast-max-width});
        color: var(--toast-color, #{$toast-color});
        border-radius: $border-radius;
        padding: $spacing-200;
        margin-top: $spacing-200;
        z-index: $zindex-toast;

        &.has-action {
            grid-template-columns: auto auto;
        }

        &.stacked {
            grid-template-columns: auto;

            .toast-action {
                @include margin(0 0 0 auto);

                @include text-align-end;
                padding: $spacing-100;
                height: auto;
            }
        }

        > * {
            align-self: center;
        }
    }

    .toast-headline {
        font-size: $font-size-md;
    }

    .toast-action {
        @include margin-end(-$spacing-200);

        display: flex;
        align-items: center;
        color: var(--toast-action-color, #{$toast-action-color});
        height: calc(100% + #{$spacing-400});
        padding: 0 $spacing-200;
    }
</style>

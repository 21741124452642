<template>
    <div class="tooltip-container">
        <div
            ref="reference"
            class="tooltip-reference"
            @mouseover="onMouseover"
            @mouseleave="onMouseleave"
            @click="onClick"
        >
            <slot name="reference" />
        </div>

        <section
            ref="tooltip"
            class="tooltip"
            :class="[{ 'open': isOpenInternal, disabled, wrap }]"
        >
            <div data-popper-arrow class="arrow" />
            <slot />
        </section>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { createPopper } from '@popperjs/core';

export default {
    name: 'DsTooltip',

    compatConfig: { MODE: 3 },

    props: {
        isOpen: {
            type: Boolean,
            default: null,
        },

        /**
         * Position of the tooltip relative to its reference
         */
        position: {
            type: String,
            default: 'bottom',
            options: ['top', 'right', 'bottom', 'left'],
        },

        /**
         * Boolean to be able to disable a tooltip from being able to open
         */
        disabled: Boolean,

        /**
         * Boolean to force tooltips to wrap with max width
         */
        wrap: Boolean,
    },

    emits: ['close'],

    setup(props, { emit }) {
        const tooltip = ref(null);
        const reference = ref(null);
        const isOpenInternal = ref(false);
        const popperJs = ref(null);

        const isControlled = computed(() => props.isOpen === true || props.isOpen === false);

        function close() {
            isOpenInternal.value = false;

            if (!popperJs.value) {
                return;
            }

            popperJs.value.update();
        }

        onClickOutside(
            tooltip,
            (event) => {
                if (!isOpenInternal.value) {
                    return;
                }

                if (isControlled.value) {
                    emit('close', event);
                } else {
                    close();
                }
            },
            { ignore: [reference] },
        );

        return {
            tooltip,
            reference,
            isOpenInternal,
            popperJs,
            isControlled,
            close,
        };
    },

    watch: {
        isOpen: {
            handler(isOpen) {
                if (isOpen) {
                    this.open();
                } else {
                    this.close();
                }
            },
            immediate: true,
        },
    },

    mounted() {
        this.popperJs = createPopper(this.$refs.reference, this.$refs.tooltip, {
            placement: this.position,
            modifiers: [
                {
                    name: 'eventListeners',
                    options: {
                        scroll: true,
                        resize: true,
                    },
                },
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
            ],
        });
    },

    beforeUnmount() {
        if (this.popperJs && typeof this.popperJs.destroy === 'function') {
            this.popperJs.destroy();
        }

        this.popperJs = null;
    },

    methods: {
        open() {
            this.isOpenInternal = true;

            if (!this.popperJs) {
                return;
            }

            this.popperJs.update();
        },

        onMouseover() {
            if (!this.isControlled) {
                this.open();
            }
        },

        onMouseleave() {
            if (!this.isControlled) {
                this.close();
            }
        },

        onClick() {
            if (!this.isControlled) {
                this.open();
            }
        },

        onBodyClick() {
            if (this.isControlled) {
                this.$emit('close');
            } else {
                this.close();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .tooltip-container {
        display: inline-block;
        position: relative;
    }

    .tooltip-reference {
        display: inline-block;
    }

    .tooltip {
        @include popover($tooltip-background-color);
        color: $tooltip-color;
        font-weight: $font-weight-semibold;
        text-align: center;
        white-space: nowrap;
        padding: $spacing-200;

        @media($small) {
            border-radius: $border-radius;
        }

        &.wrap {
            width: var(--tooltip-width, #{$tooltip-width});
            white-space: normal;
        }

        &.disabled {
            visibility: hidden;
        }
    }

    :slotted(.popover-content) {
        padding: $spacing-200;
    }
</style>

<template>
    <div v-show="show" class="inner-tab">
        <!-- @slot Slot holding tab content -->
        <slot />
    </div>
</template>

<script>
export default {
    name: 'DsTab',

    compatConfig: { MODE: 3 },

    inject: {
        emitter: {
            default() {
                return {
                    on() {},
                    off() {},
                };
            },
        },
    },

    props: {
        /**
         * Label to render in the tab.
         */
        label: {
            type: String,
            default: '',
        },

        /**
         * Denotes whether the tab is initially active.
         */
        selected: Boolean,

        /**
         * String to insert in the data-qa attribute of the tab header.
         */
        dataQa: {
            type: String,
            default: '',
        },

        /**
         * Function to call when tab is activated.
         */
        action: Function,

        /**
         * Name of icon to render before tab label.
         */
        icon: String,

        /**
         * String list of classes to apply to the tab such as error
         */
        classList: String,
    },

    data() {
        return {
            show: false,
        };
    },

    created() {
        if (this.$parent && typeof this.$parent.register === 'function') {
            this.$parent.register(this);

            if (this.selected) {
                this.$parent.$data.activeTab = this;
                this.show = true;
            }

            this.emitter.on('activateTab', this.activateTab);
        }
    },

    beforeUnmount() {
        this.emitter.off('activateTab', this.activateTab);
    },

    methods: {
        activateTab(tab) {
            this.show = tab === this;

            if (this.show && tab.action) {
                tab.action();
            }
        },
    },
};
</script>

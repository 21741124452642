<template>
    <div v-show="loading" class="animated-background">
        <div
            v-for="({ height, type, boxes }, i) in computedRows"
            :key="i"
            class="row"
            :style="{ height }"
            :class="[type]"
        >
            <div
                v-for="(box, j) in boxes"
                :key="j"
                class="box"
                :class="[box.type]"
                :style="{ flex: `${box} 0 0` }"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'DsPlaceholder',

    compatConfig: { MODE: 3 },

    props: {
        /**
         * Array of row objects with height of row and box columns
         */
        rows: {
            type: Array,
            default: () => [],
        },

        /**
         * Show the loading placeholder or not
         */
        loading: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        computedRows() {
            return this.rows.reduce((newRows, row, i, rows) => {
                const { height } = row;

                newRows.push({
                    boxes: row.boxes.reduce((newBoxes, box, j, boxes) => {
                        newBoxes.push(box);

                        if (j !== boxes.length - 1) {
                            newBoxes.push({ type: 'divider' });
                        }

                        return newBoxes;
                    }, []),
                    height,
                });

                if (i !== rows.length - 1) {
                    newRows.push({ type: 'divider' });
                }

                return newRows;
            }, []);
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .box {
        overflow: hidden;
        height: 100%;

        &.divider {
            width: 1rem;

            @media($medium) {
                background-color: var(--placeholder-background, #{$color-gray-050});
            }
        }
    }

    .row {
        display: flex;
        height: 1rem;
        flex: 0 1 auto;

        &.divider {
            background-color: var(--placeholder-background, #{$color-gray-050});
        }
    }

    @keyframes shine {
        0% { background-position: -450px 0; }
        100% { background-position: 500px 0; }
    }

    .animated-background {
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: shine;
        animation-timing-function: linear;
        background: $placeholder-background;
        background-size: 950px 100px;
        border-radius: var(--placeholder-border-radius, 0);
        width: var(--placeholder-width, 100%);
        position: relative;
    }
</style>

import { reactive, ref } from 'vue';
import { createToastGlobal } from './components/Toast';
import { createErrorBannerGlobal } from './components/ErrorBanner';
import { createConfirmDialogGlobal } from './components/Modal';
import defaultMessages from './messages';
import {App} from "vue";

const registerComponents = (app:App<unknown>, options:any) => {
    const { components } = options;

    if (components) {
        components.forEach((component:any) => {
            app.component(component.name, component);
        });
    }
};

const requireIcons = (icons:string[]) => {
    icons ??= [];

    for (const icon of icons) {
        import(`./assets/icons/${icon}.svg`);
    }
};

const requireIllustrations = (illustrations:string[]) => {
    illustrations ??= [];

    for (const illustration of illustrations) {
        import(`./assets/images/illustrations/${illustration}.svg`);
    }
};

const createI18n = () => {
    const i18n = reactive({
        messages: defaultMessages,
        setMessages(messages:any){
            i18n.messages = messages;
        }
    });

    return i18n;
};

const addPrototypes = (app:App, options:Record<string,any> = {}) => {
    app.config.globalProperties.$designSystem = {
        i18n: createI18n(),
        onOverlayActive: options.onOverlayActive,
        onOverlayInactive: options.onOverlayInactive,
    };

    app.provide('useNewIllustrations', ref(options.useNewIllustrations));

    createToastGlobal(app);
     createErrorBannerGlobal(app);
    createConfirmDialogGlobal(app);
};

const install = (app:App, options:any = {}) => {
    registerComponents(app, options);
    addPrototypes(app, options);
};

export function createDesignSystem(options:any={}) {
    const designSystem = {
        install(app:App) {
            install(app, options);
        },
    };

    return designSystem;
}
export const DesignSystem = createDesignSystem;

// Reexport all public components
export * from './components';

// Reexport all public mixins
export * from './mixins';

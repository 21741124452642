<template>
    <div class="input-field" :class="{ 'no-label': !label }">
        <input
            :id="name"
            ref="input"
            :type="localType"
            :name="name"
            :class="[
                {
                    dirty: dirty,
                    hidden,
                    submitted,
                    'has-leading': $slots.leading,
                    'has-trailing': hasTrailing
                },
                inputClassList
            ]"
            :autofocus="autofocus"
            :required="required"
            :placeholder="placeholder"
            :maxlength="maxlength"
            :min="min"
            :max="max"
            :pattern="computedPattern"
            :value="value"
            :readonly="readonly"
            :step="step"
            :autocomplete="autocompleteValue"
            :tabindex="tabindex"
            @input="updateInput($event.target.value)"
            @focus="$emit('focus')"
            @blur="handleBlur"
            @select="handleSelect"
            @keydown="$emit('keydown', $event)"
            @keyup="$emit('keyup', $event)"
        />

        <label v-if="label" :for="name">{{ label }}</label>

        <span v-if="showError" class="error-text">
            <slot name="error" />
        </span>

        <span v-if="$slots.help" class="assistive-text">
            <slot name="help" />
        </span>

        <div v-if="$slots.leading" class="leading">
            <!-- @slot Leading icon -->
            <slot name="leading" />
        </div>

        <div v-if="hasTrailing" class="trailing">
            <!-- @slot Trailing icon -->
            <slot name="trailing">
                <div v-if="type === 'password'" class="toggle-password" @click="togglePassword">
                    <Icon :name="isPasswordShownAsText ? 'eye-hide' : 'eye-show'" />
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
import { Icon } from '../Icon';

import '../../assets/icons/eye-hide.svg';
import '../../assets/icons/eye-show.svg';

export default {
    name: 'DsInputField',

    compatConfig: { MODE: 3 },

    components: {
        Icon,
    },

    props: {
        /**
         * Label to render above the input field when active
         */
        label: {
            type: String,
            default: '',
        },

        /**
         * Placeholder text for when the input is empty
         */
        placeholder: {
            type: String,
            default: '',
        },

        /**
         * HTML input element name
         */
        name: {
            type: String,
            default: '',
        },

        /**
         * Control hiding the input
         */
        hidden: Boolean,

        /**
         * Prevent user from typing in the input
         */
        readonly: Boolean,

        tabindex: Number,

        /**
         * Required field for validation purposes
         */
        required: Boolean,

        /**
         * Number input usage to control how much to increase/decrease with buttons. Also controls how the value is validated.  If set to "any", allows decimal numbers.
         */
        step: [String, Number],

        /**
         * Controls visualization of rendering form validation error states
         */
        submitted: Boolean,

        /**
         * Forces invalid form field
         */
        invalid: Boolean,

        /**
         * Form validation pattern regex
         */
        pattern: {
            type: String,
            default: '',
        },

        /**
         * Input HTML type
         */
        type: {
            type: String,
            default: 'text',
            options: ['text', 'email', 'password', 'number', 'submit', 'search', 'url', 'tel'],
        },

        /**
         * Control trimming the text value from the input on change to vmodel
         */
        trim: Boolean,

        /**
         * Min length allowed on the input for validation purposes
         */
        minlength: {
            type: Number,
            default: 0,
        },

        /**
         * Max length allowed on the input, will prevent user from being able to enter more
         */
        maxlength: {
            type: Number,
            default: 232,
        },

        /**
         * Min number allowed for number input
         */
        min: Number,

        /**
         * Max number allowed for number input
         */
        max: Number,

        /**
         * Input value
         */
        modelValue: [String, Number],

        /**
         * Auto focus on this input containing component renders
         */
        autofocus: Boolean,

        /**
         * Control whether you allow users to have the ability to autofill/autocomplete this input from things like Google
         */
        autocomplete: {
            type: [Boolean, String],
            default: false,
        },

        /**
         * Intercept before emitting the update:modelValue event
         */
        interceptOnUpdate: Function,

        /**
         * String list of classes to apply to the input element
         */
        inputClassList: {
            type: String,
            default: '',
        },
    },

    emits: [
        'blur',
        'focus',
        'keydown',
        'keyup',
        'selected',
        'update:modelValue',
    ],

    data() {
        return {
            isPasswordShownAsText: false,
            dirty: false,
            value: this.modelValue,
        };
    },

    computed: {
        showError() {
            return Boolean(this.$slots.error && this.submitted);
        },

        autocompleteValue() {
            if (typeof this.autocomplete === 'boolean') {
                if (this.autocomplete) {
                    return 'on';
                }

                return 'off';
            }

            return this.autocomplete;
        },

        hasTrailing() {
            return this.$slots.trailing || this.type === 'password';
        },

        localType() {
            if (this.isPasswordShownAsText) {
                return this.type === 'password' ? 'text' : 'password';
            }

            return this.type;
        },

        computedPattern() {
            let pattern = this.pattern || '.*';

            if (this.minlength > 0) {
                pattern = `.{${this.minlength},}`;
            }

            return pattern;
        },
    },

    watch: {
        modelValue(modelValue) {
            if (modelValue !== this.parseValue(this.value)) {
                this.setValue(modelValue);
            }
        },

        invalid() {
            this.checkInvalid();
        },
    },

    created() {
        this.setValue(this.parseValue(this.modelValue));
    },

    mounted() {
        this.checkInvalid();
    },

    methods: {
        parseValue(value) {
            if (this.type !== 'number') {
                return value;
            }

            if (value === '' || value === undefined || value === null) {
                return value;
            }

            const number = Number(value);

            if ((this.min && number < this.min) || (this.max && this.max < number)) {
                return '';
            }

            return number;
        },

        togglePassword() {
            this.isPasswordShownAsText = !this.isPasswordShownAsText;

            this.$nextTick(() => {
                this.$refs.input.focus();
            });
        },

        handleBlur(event) {
            this.$emit('blur', event);
            const { value } = this;

            if (this.type === 'number') {
                if (value === 0 || value === '') {
                    this.value = undefined;

                    this.$nextTick(() => {
                        this.value = value;
                    });

                    return;
                }

                if (this.modelValue !== this.value) {
                    this.value = this.modelValue;
                }
            }
        },

        updateInput(value) {
            const updateInputCallback = (val) => {
                this.setValue(val);
                this.$emit('update:modelValue', this.parseValue(this.value));
            };

            if (this.interceptOnUpdate && typeof this.interceptOnUpdate === 'function') {
                this.interceptOnUpdate(value, updateInputCallback);
            } else {
                updateInputCallback(value);
            }
        },

        setValue(value) {
            this.value = (this.trim && value) ? value.trim() : value;
            this.dirty = value === 0 || Boolean(value) || (this.type === 'number' && value === '');
        },

        checkInvalid() {
            if (this.$refs.input) {
                if (this.invalid) {
                    this.$refs.input.setCustomValidity('invalid');
                } else {
                    this.$refs.input.setCustomValidity('');
                }
            }
        },

        handleSelect({ target } = { target: { selectionStart: null, selectionEnd: null } }) {
            const { selectionStart, selectionEnd } = target;

            this.$emit('selected', { selectionStart, selectionEnd });
        },

        input_focus() {
            this.$refs.input.focus();
        },

        input_reportValidity() {
            if (!this.$refs.input || typeof this.$refs.input.reportValidity !== 'function') {
                return;
            }

            this.$refs.input.reportValidity();
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .input-field {
        @include input-field;

        label {
            &.active {
                @include pill-label;
            }
        }

        input {
            @include input-text;
        }

        input.has-leading {
            @include padding-start($spacing-500);
        }

        input.has-trailing {
            @include padding-end($spacing-500);
        }

        input.has-leading:not(:focus):not(.dirty) + label {
            @include position-start($spacing-500);
        }

        .trailing {
            @include position-end($spacing-050);
            padding: $spacing-100;
            position: absolute;
            --icon-color: #{$color-ink-700};
        }

        .leading {
            @include position-start($spacing-050);
            padding: $spacing-100;
            position: absolute;
            --icon-color: #{$color-ink-700};
        }
    }

    .toggle-password {
        --icon-color: #{$color-ink-600};

        cursor: pointer;

        &:hover {
            --icon-color: #{$color-gray-900};
        }
    }
</style>

<template>
    <div class="infinite-scroll">
        <div v-show="showPlaceholder" ref="placeholder" class="infinite-scroll-placeholder">
            <slot />
        </div>
    </div>
</template>
<script>
import debounce from 'lodash.debounce';

import {
    isDocument,
    getScrollParent,
    distanceToTopEdge,
    distanceToBottomEdge,
} from '../../utils/dom';

export default {
    name: 'DsInfiniteScroll',

    compatConfig: { MODE: 3 },

    props: {
        /**
         * Boolean to be set by parent after each load to let InfiniteScroll know if there is more data to fetch
         */
        noMoreData: {
            type: Boolean,
            required: true,
        },

        /**
         * Boolean to be set by parent after each load to let InfiniteScroll know when the parent is loading
         */
        loading: {
            type: Boolean,
            required: true,
        },

        /**
         * Initial limit for request, InfiniteScroll will optimize limit for screen height
         */
        limit: {
            type: Number,
            required: true,
            sync: true, // must be synced
        },

        /**
         * Controls the scrolling direction of infinite scroll
         */
        scrollDirection: {
            type: String,
            default: 'down',
            validator: (scrollDirection) => ['down', 'up'].includes(scrollDirection),
        },

        /**
         * Distance from the last list item to the bottom of window or scroll container (whichever is less) to trigger load
         */
        threshold: {
            type: Number,
            default: 200,
            dimension: 'pixels',
        },

        /**
         * Debounce delay for load function
         */
        debounceDelay: {
            type: Number,
            default: 300,
            dimension: 'seconds',
        },

        /**
         * Disable InfiniteScroll at any time, maintains enabled state
         */
        disabled: Boolean,
    },

    emits: [
        'load',
        'update:limit',
    ],

    data() {
        return {
            scroller: null,
            placeholder: null,
            placeholderHeight: 0,
            localLoading: this.loading,
            isFirstLoad: true,
        };
    },

    computed: {
        showPlaceholder() {
            return (!this.disabled && this.localLoading) || (!this.disabled && !this.noMoreData);
        },
    },

    watch: {
        disabled(value) {
            this.$nextTick(() => {
                if (value) {
                    window.removeEventListener('scroll', this.handleScroll, true);
                } else {
                    window.addEventListener('scroll', this.handleScroll, true);
                    this.initializeInfiniteScroll();
                }
            });
        },

        loading(value) {
            this.localLoading = value;
        },
    },

    created() {
        if (this.debounceDelay > 0) {
            this.load = debounce(this.load, this.debounceDelay);
        }
    },

    mounted() {
        if (!this.disabled) {
            this.initializeInfiniteScroll();
        }

        window.addEventListener('scroll', this.handleScroll, true);
    },

    unmounted() {
        window.removeEventListener('scroll', this.handleScroll, true);
    },

    methods: {
        initializeInfiniteScroll() {
            if (this.$slots.default) {
                const { placeholder } = this.$refs;

                this.placeholder = placeholder.children.length === 1
                    ? placeholder.querySelector('.placeholder')
                    : placeholder.children[0];
            }

            if (this.placeholder?.nodeName) {
                const { marginTop, marginBottom } = window.getComputedStyle(this.placeholder, null);

                this.placeholderHeight = this.placeholder.offsetHeight
                        + parseInt(marginTop, 10)
                        + parseInt(marginBottom, 10);
            } else {
                this.placeholderHeight = 0;
            }

            this.scroller = getScrollParent(this.placeholder || this.$parent.$el);
            this.calculateOptimalLimit();

            if (this.isFirstLoad) {
                this.isFirstLoad = false;
                this.load();
            }
        },

        handleScroll({ target }) {
            const isTargetDocument = isDocument(target);
            const isTarget = this.isTarget(target);

            if (!this.localLoading && !this.noMoreData && (isTarget || isTargetDocument) && this.isWithinThreshold()) {
                this.localLoading = true;
                this.load();
            }
        },

        load() {
            this.$emit('load');
        },

        calculateOptimalLimit() {
            let optimalLimit = 0;
            const BUFFER = 100;
            const { distanceToScrollerEdge, distanceToWindowEdge } = this.getEdgeDistances();
            const availableDistance = isDocument(this.scroller)
                ? distanceToWindowEdge
                : Math.min(distanceToWindowEdge, distanceToScrollerEdge);

            optimalLimit = Math.ceil((availableDistance + BUFFER) / this.placeholderHeight);

            if (!Number.isFinite(optimalLimit)) {
                optimalLimit = 1;
            }

            if (optimalLimit && optimalLimit > this.limit) {
                this.$emit('update:limit', optimalLimit);
            }
        },

        getEdgeDistances() {
            if (this.scrollDirection === 'up') {
                return {
                    distanceToScrollerEdge: distanceToTopEdge(this.placeholder, this.scroller, true),
                    distanceToWindowEdge: distanceToTopEdge(this.placeholder, document, true),
                };
            }

            return {
                distanceToScrollerEdge: distanceToBottomEdge(this.placeholder, this.scroller, true),
                distanceToWindowEdge: distanceToBottomEdge(this.placeholder, document, true),
            };
        },

        isTarget(target) {
            return target === this.scroller || (isDocument(target) && isDocument(this.scroller));
        },

        isWithinThreshold() {
            const { distanceToScrollerEdge, distanceToWindowEdge } = this.getEdgeDistances();

            if (isDocument(this.scroller)) {
                return distanceToWindowEdge >= -this.threshold;
            }

            const netDistanceToEdge = Math.min(distanceToWindowEdge, distanceToScrollerEdge);

            return netDistanceToEdge >= -this.threshold;
        },
    },
};
</script>

<style lang="scss" scoped>
    .infinite-scroll,
    .infinite-scroll-placeholder {
        width: 100%;
    }
</style>

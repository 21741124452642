<template>
    <OutlineButton
        v-bind="$attrs"
        class="button-toggle-option"
        :dense="dense"
        :class="[{ selected, 'button-block': block }]"
    >
        <slot />
    </OutlineButton>
</template>

<script>
import OutlineButton from './OutlineButton.vue';

export default {
    name: 'DsButtonToggleOption',

    components: { OutlineButton },

    props: {
        selected: Boolean,
        block: Boolean,
        dense: Boolean,
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .button-toggle-option {
        --icon-size: 1rem;
    }

    .selected {
        background-color: $color-gray-800;
        color: $color-paper;
        border-color: $color-gray-800;

        &:hover, &:focus {
            background-color: $color-gray-900;
        }

        &:active {
            background-color: $color-gray-900;
            color: $color-paper;
        }
    }

    .button-block {
        width: 100%;
    }
</style>

<template>
    <div class="progress-bar">
        <div class="bar-container">
            <div
                v-if="indeterminate"
                class="bar-indeterminate"
            />
            <div
                v-else
                class="bar"
                :style="{ width: progressPercentage }"
            />
        </div>

        <div class="info">
            <div
                v-if="label"
                class="label-value"
            >
                <!-- eslint-disable vue/no-v-html -->
                <span
                    v-if="!hideLabelValue"
                    class="value"
                    v-html="progress"
                />

                <span class="label" v-html="label" />
                <!-- eslint-enable vue/no-v-html -->
            </div>

            <!-- eslint-disable vue/no-v-html -->
            <div
                v-if="max && showProgress && !indeterminate"
                class="progress"
                v-html="progressPercentage"
            />
            <!-- eslint-enable vue/no-v-html -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'DsProgressBar',

    compatConfig: { MODE: 3 },

    props: {
        /**
         * Value of the progress bar current status
         */
        progress: Number,

        /**
         * Progress bar maximum possible value
         */
        max: {
            type: Number,
            default: 100,
        },

        /**
         * Boolean used to display an indeterminate/infinite progress
         */
        indeterminate: Boolean,

        /**
         * Boolean used to show the progress bar percentage value
         */
        showProgress: Boolean,

        /**
         * Boolean used to hide the progress bar label\'s value number
         */
        hideLabelValue: Boolean,

        /**
         * String used to display the progress bar value label
         */
        label: String,
    },

    computed: {
        progressUnit() {
            return this.max / 100;
        },

        progressPercentage() {
            return `${+((this.progress / this.progressUnit).toFixed(2))}%`;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .progress-bar {
        margin-bottom: $spacing-200;
        width: var(--progress-bar-width, auto);
    }

    .bar-container {
        background-color: $progress-background;
        height: var(--progress-bar-height, #{$progress-bar-height});
        border-radius: calc( var(--progress-bar-height, #{$progress-bar-height}) / 2);
        overflow: hidden;
    }

    .bar {
        @include transition(width);
        background-color: var(--progress-bar-color, #{$progress-color});
    }

    .bar,
    .bar-indeterminate {
        height: 100%;
    }

    .info {
        margin-top: $spacing-050;
        display: flex;
        color: $color-text-subtle;
        font-size: $font-size-lg;
    }

    .value {
        color: $progress-value-color;
    }

    .label {
        font-size: $font-size-xs;
    }

    .progress {
        @include margin-start(auto);
    }

    .bar-indeterminate {
        position: relative;

        &:before,
        &:after {
            position: absolute;
            display: block;
            content: '';
            height: inherit;
            bottom: 0;
            left: 0;
            top: 0;
            right: auto;
            width: auto;
            will-change: left, right;
            background-color: var(--progress-bar-color, #{$progress-color});
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }

        &:before {
            animation-name: indeterminate;
        }

        &:after {
            animation-name: indeterminate-short;
        }
    }

    @keyframes indeterminate {
        0% {
            left: -90%;
            right: 100%;
        }

        60% {
            left: -90%;
            right: 100%;
        }

        to {
            left: 100%;
            right: -35%;
        }
    }

    @keyframes indeterminate-short {
        0% {
            left: -200%;
            right: 100%;
        }

        60% {
            left: 107%;
            right: -8%;
        }

        to {
            left: 107%;
            right: -8%;
        }
    }

</style>

<template>
    <label
        class="toggle-switch"
        :class="{
            'disabled': readonly,
            'no-label': !hasLabels,
            'reverse-positioning': labelPositionFlip
        }"
    >
        <span v-if="hasLabels" class="label">
            {{ modelValue ? labelOn : labelOff }}
        </span>

        <div class="toggle">
            <input
                type="checkbox"
                :value="modelValue"
                :disabled="readonly"
                class="visually-hidden"
                :class="{ 'checked': modelValue }"
                @change="toggle"
            />

            <span class="slider" />
        </div>
    </label>
</template>

<script>
export default {
    name: 'DsToggleSwitch',

    compatConfig: { MODE: 3 },

    props: {
        /**
         * Make the switch read only.
         */
        readonly: Boolean,

        /**
         * Whether the switch is turned on or off.
         */
        modelValue: Boolean,

        /**
         * The label to show when the switch is on.
         */
        labelOn: String,

        /**
         * The label to show when the switch is off.
         */
        labelOff: String,

        /**
         * Put the toggle label on the other side. Positions are reversed for RTL.
         */
        labelPositionFlip: Boolean,
    },

    emits: ['update:modelValue'],

    computed: {
        hasLabels() {
            return !!this.labelOn && !!this.labelOff;
        },
    },

    methods: {
        toggle() {
            this.$emit('update:modelValue', !this.modelValue);
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    input {
        &.checked + .slider {
            background-color: var(--toggle-switch-bar-active, #{$toggle-switch-background-color});

            &:before {
                background-color: var(--toggle-switch-handle-active, #{$toggle-switch-color});

                left: calc( 100% - var(--toggle-switch-height, #{$toggle-switch-height}) );
            }
        }

        &:disabled.checked + .slider {
            background-color: var(--toggle-switch-bar-active, #{$toggle-switch-background-color-on-disabled});

            &:before {
                background-color: var(--toggle-switch-handle-active, #{$toggle-switch-color-on-disabled});
                border-color: var(--toggle-switch-bar-active, #{$toggle-switch-background-color-on-disabled});
            }
        }
    }

    .toggle-switch {
        @include prevent-select;
        display: inline-flex;
        align-items: center;
        flex-direction: row;
        height: 2rem;
        min-width: 5rem;
        width: auto;
        cursor: pointer;

        &.no-label {
            min-width: auto;
        }

        &.disabled {
            cursor: not-allowed;

            .slider {
                cursor: not-allowed;
                background-color: var(--toggle-switch-bar-inactive, #{$toggle-switch-background-color-off-disabled});

                &:before {
                    box-shadow: none;
                    border: 1px solid $color-ink-200;
                    background-color: var(--toggle-switch-handle-disabled, #{$color-paper});
                }
            }
        }

        .label {
            @include padding-end($spacing-150);

            color: var(--toggle-switch-color, #{$toggle-switch-label-color});
        }

        .toggle {
            position: relative;
            display: inline-block;
            width: var(--toggle-slider-width, #{$toggle-slider-width});
            height: var(--toggle-slider-height, #{$toggle-slider-height});
            margin: var(--toggle-switch-margin, 0);
        }

        &:not(.disabled) .toggle:hover .slider:before,
        &:focus-within .slider:before {
            box-shadow: $elevation-z4;
        }
    }

    .toggle-switch.reverse-positioning {
        flex-direction: row-reverse;
    }

    .toggle-switch.reverse-positioning .label {
        @include padding-end(0);
        @include padding-start($spacing-150);
    }

    .slider {
        @include transition(background-color);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: var(--toggle-switch-bar-inactive, #{$toggle-slider-background-color});
        border-radius: calc( var(--toggle-slider-height, #{$toggle-slider-height}) / 2);
        cursor: pointer;

        &:before {
            @include transition(left, box-shadow);
            content: "";
            position: absolute;
            border-radius: 100%;
            box-shadow: $elevation-z1;
            height: var(--toggle-switch-height, #{$toggle-switch-height});
            width: var(--toggle-switch-height, #{$toggle-switch-height});
            left: 0;
            bottom: calc( var(--toggle-switch-height, #{$toggle-switch-height}) / -2 + var(--toggle-slider-height, #{$toggle-slider-height}) / 2);
            background-color: var(--toggle-switch-handle-inactive, #{$toggle-switch-thumb-color});
        }
    }

    .disabled {
        cursor: not-allowed;
    }
</style>

<script>
import { h, markRaw, unref } from 'vue';

const cache = {};

export default {
    name: 'DsIllustration',
    methods: {
        loadSvg(reload = false) {
            const {
                name,
                svg,
                loaded,
                useNewIllustrations
            } = this;

            if (name && (reload || !svg)) {
                if (cache[name] == null) {
                    let Illustration;
                    // support both ref and raw value to be compatible with 3.3+ and 3.2-
                    if (unref(useNewIllustrations) === true) {

                        const normalizedName = name.replace(/(-color|-gray)$/, '');

                        try {
                            Illustration = import(`../../assets/images/illustrations-new/${normalizedName}.svg?component`);
                        } catch (e) {
                            Illustration = import('../../assets/images/illustrations-new/not-found.svg?component');
                        }
                    } else {
                        Illustration = import(`../../assets/images/illustrations/${name}.svg?component`);
                    }

                    Illustration.then((loaded) => {
                        cache[name] = markRaw(loaded.default);
                        this.svg = cache[name];
                    });
                } else {
                    this.svg = cache[name];
                }
            }
        }
    },

    compatConfig: { MODE: 3 },

    inject: ['useNewIllustrations'],

    props: {
        /**
         * The name of the illustration.
         */
        name: {
            type: String,
            default: '',
        },

        /**
         * The size variant. This will be used to determine the size of the illustration.
         */
        size: {
            type: String,
            default: 'medium',
            validator: (size) => ['small', 'medium', 'large', 'mega'].includes(size),
        },
    },

    data() {
        return {
            svg: cache[this.name],
        };
    },

    watch: {
        name: {
            immediate: true,
            handler(value, oldValue) {
                this.loadSvg(value && oldValue);
            },
        },
        size() {
            this.loadSvg(true);
        },
    },

    computed: {
        imageSize() {
            if (this.size === 'small') {
                return '48';
            }

            if (this.size === 'medium') {
                return '72';
            }

            if (this.size === 'large') {
                return '96';
            }

            if (this.size === 'mega') {
                return '144';
            }

            return this.size;
        },

        viewBox() {
            const { useNewIllustrations } = this;
            if (unref(useNewIllustrations) === true) {
                return '0 0 48 48';
            } else {
                return '0 0 120 100';
            }
        }
    },

    render() {
        const {
            name,
            svg,
            imageSize,
            viewBox,
            useNewIllustrations,
        } = this;

        if (!name || !svg) {
            return null;
        }

        const props = unref(useNewIllustrations) === true ? {
            height: imageSize,
            width: imageSize
        } : {};

        return h(svg, {
            viewBox,
            ...props,
        });
    },
};
</script>

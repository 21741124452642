<template>
    <div
        ref="dropzone"
        class="file-upload"
        :class="{
            'success': success || fileName,
            'dragging': fileBeingDragged,
            'clickable': !fileName,
        }"
        @click="browse"
        @drop.prevent="handleDrop"
        @dragenter.prevent="handleDragenter"
        @dragleave.prevent="handleDragleave"
    >
        <section class="no-pointer-events">
            <div v-show="!uploadStarted && !fileName" class="circle">
                <Icon
                    class="icon-upload"
                    name="upload-cloud"
                />
            </div>

            <div v-show="uploadStarted" class="file-type">
                {{ fileType }}
            </div>
        </section>

        <section v-show="!uploadStarted && !fileName" id="initial-info" class="no-pointer-events">
            <h4 class="label semibold">
                {{ label || $designSystem.i18n.messages['upload.label'] }}
            </h4>
            <h6 class="instructions">
                {{ instructions || $designSystem.i18n.messages['upload.instructions'] }}
            </h6>
            <h6 class="allowedFilesLabel">
                {{ allowedFilesLabel || $designSystem.i18n.messages['upload.allowable'] }}
            </h6>
        </section>

        <section v-show="uploadStarted" id="info" class="no-pointer-events">
            <form ref="fileForm">
                <input
                    ref="fileInput"
                    type="file"
                    :accept="accept"
                    @change="handleSelection"
                />
            </form>

            <div id="details">
                <span>
                    <span v-show="!error" class="file-name">
                        <span class="short-name">{{ shortFileName }}</span>
                        <span v-show="fileType">.{{ fileType }}</span>
                    </span>
                    <span v-show="error" class="error-message">{{ errorMessage }}</span>
                </span>
                <span class="file-size">{{ fileSizeLabel }}</span>
            </div>

            <div id="progress-bar-container">
                <ProgressBar
                    :indeterminate="!complete && !error"
                    :value="100"
                />
            </div>
            <section v-show="uploadStarted" id="status">
                <div v-show="success">
                    <Icon
                        class="icon-checkmark"
                        name="check"
                    />
                    <span>{{ uploadCompleteLabel || $designSystem.i18n.messages['upload.complete.label'] }}</span>
                </div>

                <div v-show="error">
                    <Icon
                        class="icon-alert-circle"
                        name="alert-circle"
                    />
                </div>
            </section>
        </section>

        <section v-show="uploadStarted || fileName" class="header">
            <section id="reset">
                <Icon
                    v-show="uploadStarted || fileName"
                    class="icon-close hover-icon"
                    name="x"
                    @click.stop="reset"
                />
            </section>
        </section>
    </div>
</template>

<script>
import { Icon } from '../Icon';
import { ProgressBar } from '../ProgressBar';

import dom from '../../mixins/dom';
import windowMixin from '../../mixins/windowMixin';

import '../../assets/icons/check.svg';
import '../../assets/icons/upload-cloud.svg';
import '../../assets/icons/alert-circle.svg';
import '../../assets/icons/x.svg';

export default {
    name: 'DsFileUploadInput',

    compatConfig: { MODE: 3 },

    components: {
        Icon,
        ProgressBar,
    },

    mixins: [dom, windowMixin],

    props: {
        /**
         * File accept type
         */
        accept: {
            type: String,
            default: '*',
        },

        /**
         * Instructions for the user
         */
        instructions: String,

        /**
         * Upload file label
         */
        label: String,

        /**
         * Allowed files label
         */
        allowedFilesLabel: String,

        /**
         * Text to display after upload completes
         */
        uploadCompleteLabel: String,

        /**
         * Maximum file size in bytes
         */
        maxFileSizeBytes: {
            type: Number,
            default: 0,
        },

        /**
         * value of the file uploaded
         */
        modelValue: [Object, File],
    },

    emits: [
        'error',
        'update:modelValue',
    ],

    data() {
        return {
            uploadStarted: false,
            complete: false,
            success: false,
            error: false,
            errorMessage: '',
            fileSize: 0,
            fileName: '',
            file: this.modelValue,
            fileBeingDragged: false,
        };
    },

    computed: {
        fileSizeLabel() {
            return this.formatBytes(this.fileSize);
        },

        shortFileName() {
            return (/[.]/.exec(this.fileName)) ? this.fileName.replace(/.[^.]+$/, '') : '';
        },

        fileType() {
            const fileName = this.fileName.toLowerCase();

            return (/[.]/.exec(fileName))
                ? /[^.]+$/.exec(fileName)[0]
                : '';
        },
    },

    created() {
        this.window_addEventListener('drop', this.preventDropDefaults);
        this.window_addEventListener('dragover', this.preventDropDefaults);
    },

    beforeUnmount() {
        this.window_removeEventListener('drop', this.preventDropDefaults);
        this.window_removeEventListener('dragover', this.preventDropDefaults);
    },

    methods: {
        browse() {
            if (!this.complete && !this.fileName) {
                this.$refs.fileInput.click();
            }
        },

        handleDrop({ dataTransfer, target }) {
            this.reset();

            if (target === this.$refs.dropzone) {
                this.fileBeingDragged = false;
                this.handleSelectedFiles(dataTransfer);
            }
        },

        handleSelection({ target }) {
            if (target) {
                this.handleSelectedFiles(target);
            }
        },

        handleSelectedFiles({ files = [], items = [] }) {
            const fileCount = files.length || items.length;
            const file = files[0] || items[0] || null;

            if (fileCount > 1) {
                this.$emit('error', 'multipleFiles');
            } else if (file) {
                if (file.kind === 'file') {
                    this.filesChanged(file.getAsFile());
                } else {
                    this.filesChanged(file);
                }
            } else {
                this.$emit('error', 'fileNotDefined');
            }
        },

        handleDragenter({ target }) {
            if (target === this.$refs.dropzone) {
                this.fileBeingDragged = true;
            }
        },

        handleDragleave({ target }) {
            if (target === this.$refs.dropzone) {
                this.fileBeingDragged = false;
            }
        },

        filesChanged(file) {
            this.validateFile(file);

            if (!this.error) {
                this.uploadStarted = true;
                const { name, size } = file;

                this.fileName = name;
                this.fileSize = size;
                this.sendFile(file);
            }
        },

        preventDropDefaults(e) {
            e.preventDefault();
        },

        validateFile(file) {
            this.error = this.validateFileSize(file) || this.validateFileType(file);
        },

        validateFileSize({ size }) {
            const error = size > this.maxFileSizeBytes && this.maxFileSizeBytes > 0;

            if (error) {
                this.$emit('error', 'fileSize');
            }

            return error;
        },

        validateFileType({ type, name }) {
            if (this.accept === '*') {
                return false;
            }

            const [group, fileType] = type.split('/');
            const allTypesGroups = this.getFileGroupsThatAcceptAllTypes();

            if (group && allTypesGroups.includes(group)) {
                return false;
            }

            const acceptedFileTypes = this.getAcceptedFileTypes();

            if (fileType && acceptedFileTypes.includes(fileType)) {
                return false;
            }

            const fallbackType = (/[.]/.exec(name)) ? /[^.]+$/.exec(name)[0] : '';

            if (fallbackType && acceptedFileTypes.includes(fallbackType)) {
                return false;
            }

            this.$emit('error', 'fileType');

            return true;
        },

        getFileGroupsThatAcceptAllTypes(accept = this.accept) {
            const reAllTypesGroup = /(?:^|[^,|\s])+?(?=\/\*{1})/gi;

            return accept.match(reAllTypesGroup) || [];
        },

        getAcceptedFileTypes(accept = this.accept) {
            const reType = /([^.|/|*])+?(?=,|$)/gi;

            return accept.match(reType) || [];
        },

        formatBytes(bytes) {
            if (bytes === 0) {
                return '0KB';
            }
            const k = 1000;
            const sizes = ['B', 'KB', 'MB', 'GB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / k ** i).toFixed(0)) + sizes[i] || 0;
        },

        reset() {
            this.fileName = '';
            this.fileSize = 0;
            this.complete = false;
            this.success = false;
            this.error = false;
            this.errorMessage = '';
            this.uploadStarted = false;
            this.$refs.fileInput.value = null;
            this.$emit('update:modelValue', null);
        },

        sendFile(file) {
            this.$emit('update:modelValue', file);
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    $progress-bar-height: px-to-rem(2);

    .file-upload {
        @include prevent-select;
        background: $color-paper;
        position: relative;

        border: $upload-border-style;
        border-radius: $border-radius;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: $spacing-200;
        overflow-x: hidden;

        &.clickable {
            cursor: pointer;
        }

        &.dragging {
            box-shadow: inset 0 0 0 1px $upload-border-color,
                inset 0 0 30px $upload-border-color;
        }

        .no-pointer-events {
            pointer-events: none;
        }

        input[type=file] {
            display: none;
        }

        .icon-upload {
            --icon-size: #{px-to-rem(48px)};
            --icon-color: #{$upload-icon-color};
        }

        .icon-close {
            --icon-size: #{px-to-rem(24px)};

            color: $upload-border-color;
        }

        #progress-bar-container {
            height: $progress-bar-height;
            position: relative;

            --progress-bar-height: #{$progress-bar-height};
        }

        #status {
            margin-top: $spacing-100;
        }

        #status > div {
            display: flex;
            justify-content: center;
        }

        #status div span {
            line-height: 1.5rem;
        }

        #status .icon-checkmark {
            --icon-color: #{$color-blue};
        }

        #status .icon-alert-circle {
            --icon-color: #{$upload-error-color};
        }

        #info {
            font-size: $font-size-sm;
            max-width: 100%;
        }

        #details {
            display: flex;
            align-items: center;
            margin-bottom: $spacing-100;

            > span:first-of-type {
                @include margin-end($spacing-100);

                flex: 1;
                display: flex;
                align-items: center;
            }

            .file-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .short-name {
                @include margin-end(-$spacing-025);
            }

            .error-message {
                color: $upload-error-color;
            }

            .file-size {
                color: $upload-formats-color;
            }
        }

        .header {
            position: absolute;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: $spacing-200;
            width: 100%;
        }

        img {
            width: 2rem;
            height: 2rem;
            border-radius: $border-radius;
        }

        .file-type {
            background-color: $upload-file-type-background-color;
            border-radius: $border-radius;
            margin-bottom: $spacing-100;
            font-size: $font-size-xs;
            height: px-to-rem(40px);
            width: px-to-rem(40px);
            text-transform: uppercase;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        &.success {
            .file-type {
                background-color: $upload-cta-color;
                color: $upload-file-type-color;
            }
        }

        #initial-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .label {
                margin-bottom: $spacing-200;
            }

            .instructions {
                font-size: $font-size-sm;
                color: $upload-cta-color;
                margin-bottom: $spacing-050;
            }

            .allowedFilesLabel {
                font-size: $font-size-sm;
                color: $upload-formats-color;
            }
        }
    }
</style>

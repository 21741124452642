<template>
    <Collapsible :is-open="isOpen" :class="{ bottom: position === 'bottom' }">
        <div class="error-banner-container">
            <div class="error-banner">
                <div class="error-banner-message">
                    <div v-if="critical" class="error-banner-icon critical">
                        <Icon name="alert-triangle" />
                    </div>

                    <div v-else-if="showIcon" class="error-banner-icon">
                        <Icon name="alert-circle" />
                    </div>

                    <!-- eslint-disable vue/no-v-html -->
                    <span v-html="message" />
                    <!-- eslint-enable vue/no-v-html -->
                </div>

                <div class="error-banner-actions">
                    <TextButton @click="$emit('close')">
                        {{ closeLabel }}
                    </TextButton>

                    <TextButton v-if="actionLabel" @click="$emit('action')">
                        {{ actionLabel }}
                    </TextButton>
                </div>
            </div>
        </div>
    </Collapsible>
</template>

<script>
import { TextButton } from '../Button';
import { Collapsible } from '../Collapsible';
import { Icon } from '../Icon';

import '../../assets/icons/alert-circle.svg';
import '../../assets/icons/alert-triangle.svg';

export default {
    name: 'DsErrorBanner',

    compatConfig: { MODE: 3 },

    components: {
        Collapsible,
        Icon,
        TextButton,
    },

    props: {
        isOpen: Boolean,
        position: {
            type: String,
            default: 'top',
            validator: (position) => ['top', 'bottom'].includes(position),
        },
        message: String,
        showIcon: Boolean,
        critical: Boolean,
        actionLabel: String,
        closeLabel: String,
    },

    emits: [
        'action',
        'close',
    ],
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .collapsible {
        position: sticky;
        z-index: $zindex-modal - 1;
        max-width: #{px-to-rem(700px)};
        margin: 0 auto;

        &.bottom {
            --collapsible-inner-padding: 0 $spacing-200;
            z-index: $zindex-toast;
            position: fixed;
            top: auto !important;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;

            .error-banner {
                border-radius: $border-radius $border-radius 0 0;
                border: 1px solid $error-banner-border-color;
                border-bottom: none;
            }
        }
    }

    .error-banner-container {
        @include container;
        --container-max-width: #{px-to-rem(700px)};
        overflow: hidden;
    }

    .error-banner {
        @include prevent-select;
        align-items: center;
        background-color: $error-banner-background-color;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        border-bottom: 1px solid $error-banner-border-color;
        border-left: 1px solid $error-banner-border-color;
        border-right: 1px solid $error-banner-border-color;
        color: $error-banner-color;
        display: flex;
        justify-content: space-between;
        padding: $spacing-200;

        @media($small) {
            flex-direction: column;
            border-radius: 0;
        }
    }

    .error-banner-message {
        display: flex;
        align-items: center;

        .error-banner-icon {
            @include margin-end($spacing-300);

            align-self: flex-start;

            &.critical {
                --icon-color: #{$color-red};
            }
        }
    }

    .error-banner-actions {
        @include margin-start($spacing-200);

        flex-shrink: 0;
        display: flex;

        @media($small) {
            @include margin-start(0);
            margin-top: $spacing-200;
        }
    }
</style>

<template>
    <ToastGroup>
        <Toast
            v-for="toast in toasts"
            :key="toast.id"
            :message="toast.message"
            :headline="toast.headline"
            :action-text="toast.actionText"
            :action="toast.action"
            @after-leave="handleAfterLeave(toast)"
        />
    </ToastGroup>
</template>

<script>
import ToastGroup from './ToastGroup.vue';
import Toast from './Toast.vue';

export default {
    name: 'DsToastContainer',

    compatConfig: { MODE: 3 },

    components: {
        ToastGroup,
        Toast,
    },

    data() {
        return {
            toasts: [],
            nextId: 1,
        };
    },

    created() {
        this.$toastEmitter.on('open', this.handleGlobalOpen);
    },

    beforeUnmount() {
        this.$toastEmitter.off('open', this.handleGlobalOpen);
    },

    methods: {
        handleGlobalOpen(options) {
            this.toasts.push({
                ...options,
                id: `toast_${this.nextId}`,
            });
            this.nextId += 1;
        },

        handleAfterLeave(toast) {
            const index = this.toasts.findIndex((x) => x.id === toast.id);

            this.toasts.splice(index, 1);
        },
    },
};
</script>

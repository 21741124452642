<template>
    <div class="inline-alert" :class="type">
        <Icon v-if="leadingIcon" class="inline-alert-icon" :name="leadingIconName" />

        <span class="inline-alert-message">
            <!-- @slot Primary message of inline alert -->
            <slot />

            <div v-if="$slots.footer" class="inline-alert-footer">
                <!-- @slot Slot for footer calls to action. Primary action should be gray variant of FilledButton or an external link. Secondary action should be gray variant of TextButton. -->
                <slot name="footer" />
            </div>
        </span>

        <div v-if="$slots.trailing" class="inline-alert-trailing">
            <!-- @slot Slot for trailing calls to action. Should be gray variant of FilledButton -->
            <slot name="trailing" />
        </div>

        <IconButton
            v-else-if="$attrs.onClose"
            class="inline-alert-close-button"
            name="x"
            @click="handleClose"
        />
    </div>
</template>

<script>
/* eslint-disable vue/require-explicit-emits */
import { IconButton } from '../Button';
import { Icon } from '../Icon';

const INFO_TYPE = 'info';
const SUBTLE_TYPE = 'subtle';
const WARNING_TYPE = 'warning';
const CRITICAL_TYPE = 'critical';
const GOOD_TYPE = 'good';

export default {
    name: 'DsInlineAlert',

    compatConfig: { MODE: 3 },

    components: { Icon, IconButton },

    props: {
        /**
         * As boolean, shows default icon for given type. As string, renders custom icon
         */
        leadingIcon: {
            type: [Boolean, String],
            default: false,
        },

        /**
         * Indicates the level of severity of the alert
         * @values info, subtle, warning, critical, good
         */
        type: {
            type: String,
            default: 'info',
            validator: (type) => [INFO_TYPE, SUBTLE_TYPE, WARNING_TYPE, CRITICAL_TYPE, GOOD_TYPE].includes(type),
        },
    },

    computed: {
        leadingIconName() {
            if (typeof this.leadingIcon === 'string') {
                return this.leadingIcon;
            }

            switch (this.type) {
            case INFO_TYPE:
            case SUBTLE_TYPE:
                return 'info-circle';
            case WARNING_TYPE:
                return 'alert-triangle';
            case CRITICAL_TYPE:
                return 'alert-octagon';
            case GOOD_TYPE:
                return 'check-circle';
            default:
                return 'info-circle';
            }
        },
    },

    methods: {
        handleClose() {
            /**
             * Emits 'close' when close button is clicked
             */
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../styles/common";

.inline-alert {
    display: inline-flex;
    background-color: var(--inline-alert-background-color, $color-blue-050);
    border-radius: calc($border-radius / 2);
    color: var(--inline-alert-color, #{$color-text-normal});
}

.info {
    --inline-alert-background-color: #{$color-blue-050};
    --inline-alert-icon-color: #{$color-blue-300};
}

.subtle {
    --inline-alert-background-color: #{$color-gray-100};
    --inline-alert-icon-color: #{$color-ink-600};
}

.warning {
    --inline-alert-background-color: #{$color-orange-050};
    --inline-alert-icon-color: #{$color-orange-500};
}

.critical {
    --inline-alert-background-color: #{$color-red-050};
    --inline-alert-icon-color: #{$color-red-500};
}

.good {
    --inline-alert-background-color: #{$color-green-050};
    --inline-alert-icon-color: #{$color-green-400};
}

.inline-alert-icon {
    --icon-color: var(--inline-alert-icon-color);

    @include margin($spacing-150 0 $spacing-150 $spacing-150);
}

.inline-alert-message {
    display: flex;
    flex-direction: column;
    padding: $spacing-150;
    font-size: $font-size-md;
    line-height: $line-height-lg;
}

.inline-alert-footer {
    margin-top: $spacing-100;

    & > :not(:first-child) {
        @include margin-start($spacing-100);
    }
}

.inline-alert-trailing {
    align-self: flex-start;
    margin: $spacing-150;
}

.inline-alert-close-button {
    align-self: flex-start;
    margin: $spacing-050;
    --icon-color: #{$color-ink-800};
}
</style>

<script>
let columnIdSeed = 1;

export default {
    name: 'DsTableGridColumn',

    compatConfig: { MODE: 3 },

    props: {
        /**
         * Prioritize the width of this column, collapsing others
         */
        expand: Boolean,

        /**
         * Label of the column to display in header
         */
        label: {
            type: String,
            default: '',
        },

        /**
         * Apply number styling and alignment to the column
         */
        numeric: Boolean,

        /**
         * Property name to use in the data object
         */
        prop: {
            type: String,
            default: '',
        },

        /**
         * Toggle to be able to select this column or not
         */
        selectable: Boolean,

        /**
         * Toggle to be able to sort by this column or not
         */
        sortable: Boolean,

        /**
         * Width in px of the column
         */
        width: {
            type: String,
            default: '',
        },

    },

    data() {
        return {
            column: {},
        };
    },

    created() {
        this.column = {
            id: `column-${columnIdSeed++}`,
            label: this.label,
            property: this.prop,
            selectable: this.selectable,
            sortable: this.sortable,
            width: this.width || (this.expand ? 256 : 80),
            expand: this.expand,
            numeric: this.numeric,
        };
    },

    mounted() {
        if (this.$slots.default) {
            this.column.scopedSlots = this.$slots;
        }

        if (this.$parent && typeof this.$parent.register === 'function') {
            this.$parent.register(this.column);
        }
    },

    render() {
        return null;
    },
};
</script>

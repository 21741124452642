<template>
    <div class="input-field" :class="{ 'no-label': !label }">
        <textarea
            :id="name"
            ref="input"
            :value="value"
            :name="name"
            :class="[{ dirty: isDirty, hidden, submitted, monospace, 'auto-grow': autoGrow }, inputClassList]"
            :placeholder="placeholder"
            :required="required"
            :readonly="readonly"
            :autofocus="autofocus"
            :maxlength="maxlength"
            :autocomplete="autocompleteValue"
            @input="handleInput"
            @blur="$emit('blur', $event)"
            @select="handleSelect"
        />

        <label :for="name">{{ label }}</label>

        <div v-if="$slots.help" class="assistive-text">
            <slot name="help" />
        </div>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';

const HEIGHT_DEBOUNCE_DELAY = 300;

export default {
    name: 'DsTextAreaField',

    compatConfig: { MODE: 3 },

    props: {
        /**
         * Label to render above the input field when active
         */
        label: {
            type: String,
            default: '',
        },

        /**
         * Placeholder text for when the input is empty
         */
        placeholder: {
            type: String,
            default: '',
        },

        /**
         * HTML input element name
         */
        name: {
            type: String,
            default: '',
        },

        /**
         * Control hiding the input
         */
        hidden: Boolean,

        /**
         * Prevent user from typing in the input
         */
        readonly: Boolean,

        /**
         * Required field for validation purposes
         */
        required: Boolean,

        /**
         * Controls visualization of rendering form validation error states
         */
        submitted: Boolean,

        /**
         * Forces invalid form field
         */
        invalid: Boolean,

        /**
         * Control trimming the text value from the input on change to vmodel
         */
        trim: Boolean,

        /**
         * Input value
         */
        modelValue: String,

        /**
         * Auto focus on this input containing component renders
         */
        autofocus: Boolean,

        /**
         * Control whether you allow users to have the ability to autofill/autocomplete this input from things like Google
         */
        autocomplete: {
            type: [Boolean, String],
            default: false,
        },

        /**
         * String list of classes to apply to the input element
         */
        inputClassList: {
            type: String,
            default: '',
        },

        /**
         * Automatically resize the height to fit the text inside
         */
        autoGrow: Boolean,

        /**
         * Maximum length of the text area field
         */
        maxlength: {
            type: Number,
            default: 2000,
        },

        /**
         * Display textarea value in monospace.
         */
        monospace: Boolean,
    },

    emits: [
        'blur',
        'selected',
        'update:modelValue',
    ],

    data() {
        return {
            value: this.modelValue,
        };
    },

    computed: {
        autocompleteValue() {
            const { autocomplete } = this;

            if (autocomplete === true) {
                return 'on';
            }

            if (autocomplete === false) {
                return 'off';
            }

            return autocomplete;
        },

        isDirty() {
            return Boolean(this.value);
        },
    },

    watch: {
        modelValue(modelValue) {
            this.updateValue(modelValue);
        },

        invalid() {
            this.checkInvalid();
        },
    },

    created() {
        this.adjustHeight = debounce(this.adjustHeight, HEIGHT_DEBOUNCE_DELAY);

        this.updateValue(this.modelValue);
    },

    mounted() {
        this.checkInvalid();
        this.adjustHeight();
    },

    methods: {
        adjustHeight() {
            if (!this.autoGrow) {
                return;
            }

            this.$nextTick(() => {
                const { input } = this.$refs;

                if (input) {
                    input.style.height = 'auto';
                    input.style.height = `${input.scrollHeight + 8}px`;
                }
            });
        },

        handleInput($event) {
            this.adjustHeight();

            this.updateValue($event.target.value);
            this.$emit('update:modelValue', this.value);
        },

        updateValue(value) {
            this.value = (this.trim && value) ? value.trim() : value;
        },

        checkInvalid() {
            if (this.$refs.input) {
                if (this.invalid) {
                    this.$refs.input.setCustomValidity('invalid');
                } else {
                    this.$refs.input.setCustomValidity('');
                }
            }
        },

        handleSelect({ target } = { target: { selectionStart: null, selectionEnd: null } }) {
            const { selectionStart, selectionEnd } = target;

            this.$emit('selected', { selectionStart, selectionEnd });
        },

        // Legacy method to focus the input, not used internally but consumers may be using it via ref
        input_focus() {
            this.$refs.input.focus();
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .input-field {
        @include input-field;

        textarea {
            @include input-text;

            &.monospace {
                font: $font-family-monospace;
                resize: both;
            }
        }
    }
</style>

<template>
    <div
        :class="['multiselect', { active, searchable, error, readonly, multiple }]"
        @mousedown.stop.prevent="activate"
    >
        <Dropdown
            ref="dropdown"
            :is-open="active"
            :small-inline="searchable"
            :pin-bottom="pinBottom"
            @close="deactivate"
        >
            <div class="input-container">
                <div ref="multiTag" class="multiselect-tags">
                    <slot
                        v-for="(option, index) in visibleOptions"
                        name="selection"
                        :option="option"
                        :index="index"
                        :remove="() => removeOption(index)"
                    >
                        <Chip
                            :key="index"
                            :remove="!readonly"
                            :with-interaction="!readonly"
                            @mousedown.stop.prevent="removeOption(index)"
                        >
                            {{ option[labelProp] }}
                            <em v-if="hasHelpText(option)">({{ option[helpTextProp] }})</em>
                        </Chip>
                    </slot>

                    <div v-show="searchableMultiselectActive" class="input-search">
                        <Icon v-show="searchable" name="search" />
                    </div>

                    <span v-if="searchable" :class="['input-span', { showSearchIcon }]">
                        <input
                            ref="input"
                            type="search"
                            class="search-input"
                            :autocomplete="autocomplete"
                            :tabindex="tabIndex"
                            :value="active ? search : selectedLabel"
                            :required="required"
                            @focus.stop.prevent="activate"
                            @keydown.up.prevent="dropdownKeys_arrowUp"
                            @keydown.down.prevent="dropdownKeys_arrowDown"
                            @keydown.left.prevent="arrowLeft"
                            @keydown.right.prevent="arrowRight"
                            @keydown.enter.prevent.stop.self="dropdownKeys_enter"
                            @keydown.delete.stop="removeLast"
                            @keydown.esc.stop="blur"
                            @input="updateSearch($event.target.value)"
                            @blur.prevent="blur"
                        />

                        <label v-if="multiple" :class="{ 'pill-label': visibleOptions.length, required }">{{ placeholder }}</label>

                        <label v-else :class="{ 'static-label': hasSelection, required, showSearchIcon }">{{ placeholder }}</label>
                    </span>

                    <span v-else class="multiselect-single">
                        <input
                            ref="input"
                            readonly
                            type="text"
                            class="focus-input"
                            :tabindex="tabIndex"
                            :value="hasSelection ? selectedLabel : ''"
                            :required="required"
                            @focus.stop.prevent="activate"
                            @keydown.up.prevent="dropdownKeys_arrowUp"
                            @keydown.down.prevent="dropdownKeys_arrowDown"
                            @keydown.left.prevent="arrowLeft"
                            @keydown.right.prevent="arrowRight"
                            @keydown.enter.prevent.stop.self="dropdownKeys_enter"
                            @keydown.delete.stop="removeLast"
                            @keydown.esc.stop="blur"
                            @blur.prevent="blur"
                        />

                        <label :class="{ 'static-label': hasSelection, required, showSearchIcon }">{{ placeholder }}</label>

                        <span v-if="hasSelection" class="selected-label">{{ selectedLabel }}</span>
                    </span>
                </div>

                <div class="input-chevron">
                    <Icon v-show="!loading" name="chevron-down" />
                    <Spinner v-show="loading" :size="24" />
                </div>

                <!-- Readonly on the non-searchable input above prevents keyboard from
                popping on mobile, but it also makes HTML5 validation skip checking
                the input. This shadow input is purely to allow HTML5 validation so
                we can keep the readonly on the main input. -->

                <input
                    style="display: none"
                    :value="hasSelection ? selectedLabel : ''"
                    :required="required && !searchable"
                />
            </div>

            <template #menu>
                <div>
                    <MultiselectMenu
                        ref="dropdownMenu"
                        v-model:pointer="dropdownKeys_pointer"
                        :model-value="selection"
                        :add-text="addText"
                        :allow-add="allowAdd"
                        :value-prop="valueProp"
                        :label-prop="labelProp"
                        :disabled-option-prop="disabledOptionProp"
                        :show-option-modifier-prop="showOptionModifierProp"
                        :options="options"
                        :categorized="categorized"
                        :categories-label="categoriesLabel"
                        :filter="filter"
                        :multiple="multiple"
                        :show-blank="showBlank"
                        :help-text="helpText"
                        :help-text-prop="helpTextProp"
                        :limit="limit"
                        :show-empty-options-label="!searchable"
                        @add="add"
                        @select-category="categorySelected"
                        @update-visible-options="updateVisibleOptions"
                        @category-view="categoryView"
                        @update:model-value="handleSelection"
                    >
                        <template #optionModifier>
                            <slot name="optionModifier" />
                        </template>

                        <template #option="slotProps">
                            <slot name="option" v-bind="slotProps" />
                        </template>
                    </MultiselectMenu>

                    <div v-if="showNoResults" class="no-results">
                        {{ noResultsLabel || $designSystem.i18n.messages['select.empty.results'] }}
                    </div>
                </div>
            </template>

            <div v-if="$slots.help" class="assistive-text">
                <slot name="help" />
            </div>
        </Dropdown>
    </div>
</template>

<script>
import isEqual from 'lodash.isequal';

import dropdownKeys from '../../mixins/dropdownKeys';
import dom from '../../mixins/dom';

import { Icon } from '../Icon';
import { Chip } from '../Chip';
import { Dropdown } from '../Dropdown';
import { Spinner } from '../Spinner';
import MultiselectMenu from './MultiselectMenu.vue';

import '../../assets/icons/chevron-down.svg';

export default {
    name: 'DsMultiselect',

    compatConfig: { MODE: 3 },

    components: {
        Icon,
        Chip,
        Dropdown,
        Spinner,
        MultiselectMenu,
    },

    mixins: [dom, dropdownKeys],

    props: {
        /**
         * Adds a add button to your multiselect
         */
        allowAdd: Boolean,

        /**
         * The text shown when add button is shown in multiselect (when allowAdd is true)
         */
        addText: String,

        /**
         * Show supplemental text under the option label (when helpText value is present in option)
         */
        helpText: Boolean,

        /**
         * Property for helpText option if not the default of "helpText"
         */
        helpTextProp: {
            type: String,
            default: 'helpText',
        },

        /**
         * When true it allows for categories within the multiselect
         */
        categorized: Boolean,

        /**
         * Only bind the value
         */
        bindValueOnly: Boolean,

        /**
         * Label for categories when categorized is true
         */
        categoriesLabel: String,

        /**
         * Force the error state to show if parent is controlling it
         */
        forceInvalid: Boolean,

        /**
         * Limit of selectable items in the multiselect
         */
        limit: {
            type: Number,
            default: 99999,
        },

        /**
         * Displays spinner if component is loading
         */
        loading: Boolean,

        /**
         * Ability to select multiple items in your dropdown
         */
        multiple: Boolean,

        /**
         * Options for the multiselect
         */
        options: {
            type: Array,
            default: () => [],
        },

        /**
         * Placeholder to show when nothing is selected
         */
        placeholder: String,

        /**
         * Allow searchability of the multiselect
         */
        searchable: Boolean,

        /**
         * Show blank menu option
         */
        showBlank: Boolean,

        /**
         * Show search icon on a searchable multiselect
         */
        showSearchIcon: Boolean,

        /**
         * value of the multiselect
         */
        modelValue: [Object, Array, String, Number],

        /**
         * Label if not the default of "label"
         */
        labelProp: {
            type: String,
            default: 'label',
        },

        /**
         * Value if not the default of "value"
         */
        valueProp: {
            type: String,
            default: 'value',
        },

        /**
         * Option property for disabled option if not the default of "disabledOption"
         */
        disabledOptionProp: {
            type: String,
            default: 'disabledOption',
        },

        /**
         * Option property to show an option modifier if present
         */
        showOptionModifierProp: {
            type: String,
            default: 'showOptionModifier',
        },

        /**
         * Text to display where there are no results
         */
        noResultsLabel: String,

        /**
         * Required field for validation purposes
         */
        required: Boolean,

        /**
         * Controls visualization of rendering form validation error states
         */
        submitted: Boolean,

        /**
         * Pin the bottom of the menu to the bottom of the viewport for really long menus
         */
        pinBottom: Boolean,

        /**
         * Prevents the user from changing the selection
         */
        readonly: Boolean,

        /**
         * Semantic description of field for autocompletion in browsers
         */
        autocomplete: {
            type: String,
            default: 'nofill',
        },
    },

    emits: [
        'add',
        'blur',
        'categoryView',
        'selectCategory',
        'updateSearch',
        'update:modelValue',
    ],

    data() {
        return {
            active: false,
            closeOnBodyClick: true,
            hasLoaded: false,
            search: '',
        };
    },

    computed: {
        error() {
            return this.forceInvalid || this.required && this.submitted && !this.hasSelection;
        },

        filter() {
            return this.searchable ? this.search : '';
        },

        hasSelection() {
            const {
                selection,
                bindValueOnly,
                multiple,
            } = this;

            if ((bindValueOnly && !multiple && selection !== undefined && selection !== null)
                    || (multiple && selection && selection.length)) {
                return true;
            }

            return Boolean(selection && Object.keys(selection).length);
        },

        searchableMultiselectActive() {
            return this.showSearchIcon && this.active;
        },

        selectedLabel() {
            const {
                selection,
                multiple,
                searchable,
                placeholder,
                labelProp,
                helpTextProp,
            } = this;

            if (multiple && !searchable) {
                return placeholder;
            }

            if (this.hasHelpText(selection)) {
                return selection && selection[labelProp]
                    ? `${selection[labelProp]} (${selection[helpTextProp]})`
                    : '';
            }

            return selection && selection[labelProp] || '';
        },

        showNoResults() {
            return this.searchable
                    && this.options.length === 0
                    && !this.loading
                    && this.hasLoaded
                    && this.search !== '';
        },

        visibleOptions() {
            return this.multiple && this.selection ? this.selection.slice(0, this.limit) : [];
        },

        tabIndex() {
            return this.readonly ? -1 : 0;
        },

        flattenedOptions() {
            return this.options.reduce((flatOptions, option) => {
                if (option.groups) {
                    option.groups.forEach(({ options = [] }) => {
                        options.forEach((subOption) => {
                            flatOptions.push(subOption);
                        });
                    });
                } else if (option.options) {
                    option.options.forEach((option2) => {
                        flatOptions.push(option2);
                    });
                } else if (option.category) {
                    flatOptions.push(option.category);
                } else {
                    flatOptions.push(option);
                }

                return flatOptions;
            }, []);
        },

        selection() {
            const {
                modelValue,
                bindValueOnly,
                multiple,
                valueProp,
                flattenedOptions,
            } = this;

            if (bindValueOnly) {
                if (!modelValue) {
                    return multiple ? [] : null;
                }

                return multiple
                    ? (flattenedOptions.filter((option) => modelValue.includes(option[valueProp])) || [])
                    : flattenedOptions.find((option) => modelValue === option[valueProp]) || null;
            }

            if (multiple) {
                return [...modelValue] || [];
            }

            return modelValue || {};
        },
    },

    watch: {
        loading(loading, old) {
            this.hasLoaded = old && !loading;
        },

        visibleOptions: {
            handler() {
                this.$refs.dropdown.schedulePopper();
            },
            deep: true,
        },
    },

    methods: {
        hasHelpText(option) {
            return Boolean(this.helpText && option && option[this.helpTextProp]);
        },

        add() {
            this.$emit('add', this.filter);
            this.active = false;
        },

        activate() {
            if (!this.readonly) {
                this.active = true;
                this.$refs.input.focus();
            }
        },

        arrowLeft() {
            if (this.dom_isRtl()) {
                this.$refs.dropdownMenu.arrowForward();
            } else {
                this.$refs.dropdownMenu.arrowBack();
            }
        },

        arrowRight() {
            if (this.dom_isRtl()) {
                this.$refs.dropdownMenu.arrowBack();
            } else {
                this.$refs.dropdownMenu.arrowForward();
            }
        },

        blur(e) {
            this.deactivate();
            this.$emit('blur', e);
        },

        categorySelected(category) {
            this.$emit('selectCategory', category);
            this.deactivate();
        },

        categoryView(categoryView) {
            this.$emit('categoryView', categoryView);
        },

        deactivate() {
            this.active = false;
            this.dropdownKeys_resetPointer();

            if (this.$refs.input){
                this.$refs.input.blur();
            }


            this.search = '';
            if(this.$refs.dropdownMenu) {
                this.$refs.dropdownMenu.backToCategory();
            }
        },

        updateSelection(selection) {
            const {
                bindValueOnly,
                multiple,
                valueProp,
                modelValue,
            } = this;
            let updatedModelValue;

            if (bindValueOnly && selection) {
                updatedModelValue = multiple
                    ? selection.map((s) => s[valueProp])
                    : selection[valueProp];
            } else {
                updatedModelValue = selection;
            }

            if (!isEqual(updatedModelValue, modelValue)) {
                this.$emit('update:modelValue', updatedModelValue);
            }
        },

        handleSelection(selection) {
            this.updateSelection(selection);

            if (!this.multiple || this.selection?.length === this.limit) {
                this.deactivate();
            }

            this.search = '';
        },

        onEnter(option) {
            this.$refs.dropdownMenu.onEnter(option);
        },

        removeOption(index) {
            if (this.readonly) {
                return;
            }

            const selection = this.selection.filter((_, i) => i !== index);

            this.updateSelection(selection);
        },

        removeLast() {
            if (this.search.length === 0 && this.multiple && this.selection.length > 0) {
                this.removeOption(this.selection.length - 1);
            }
        },

        // TODO: Convert to controlled to avoid this being used by refs
        toggle() {
            this.active = !this.active;
        },

        updateSearch(query) {
            this.search = query;
            this.$emit('updateSearch', query);
        },

        updateVisibleOptions(options) {
            this.dropdownKeys_optionsModel = options;
        },
    },
};
</script>

<style lang="scss">
    @import "../../styles/common";

    .multiselect.multiple .multiselect-tags .chip {
        @include margin($spacing-050 $spacing-100 $spacing-050 0);
    }
</style>

<style lang="scss" scoped>
    @import "../../styles/common";

    .multiselect {
        @include input-field;
        --section-header-margin-bottom: 0;
        --dropdown-minwidth: 100%;

        min-height: var(--input-height, #{$input-height});
        flex-direction: column;
        padding-top: 0;
        cursor: pointer;

        &.searchable {
            cursor: text;

            .input-chevron {
                cursor: text;
                --icon-cursor: text;
            }

            .input-search {
                height: var(--input-height, 2.5rem);
                width: var(--input-height, 2.5rem);
                --icon-size: 50%;
                --icon-color: #{$color-ink-600};
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: text;
                --icon-cursor: text;
            }
        }

        &.readonly {
            .input-container {
                @include input-readonly;
            }

            label {
                @include input-readonly-label;
            }
        }

        &.multiple {
            .multiselect-tags {
                @include padding($spacing-050 $spacing-400 $spacing-050 $spacing-100);

                align-items: center;

                input {
                    height: auto;
                    padding: 0 $spacing-100;
                    border-radius: 0;
                }
            }
        }

        .input-container {
            background-color: var(--input-background, #{$color-paper});
            border-radius: $input-border-radius;
            border: var(--multiselect-input-border, $input-border);
            min-height: var(--input-height, #{$input-height});
            position: relative;
            display: flex;
            flex-direction: column;

            &.invalid {
                @include selected-input-border($color-red);
            }
        }

        .multiselect-tags {
            flex: 1;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            padding: 0 $spacing-400 0 0;
        }

        label {
            @include label;
            display: flex;

            &.required:after {
                @include input-label-required;
            }
        }

        input {
            --input-background: transparent;
            height: 100%;
        }

        .static-label {
            @include multiselect-label-selected;
            top: -1;
        }

        .pill-label {
            @include pill-label;
        }

        .input-span {
            flex: 1;
        }

        &.active {
            .input-container {
                @include selected-input-border($color-blue);

                label {
                    @include multiselect-label-selected;
                    color: $color-blue;
                }

                .pill-label {
                    @include multiselect-label-selected;
                }

                .search-input {
                    width: 100%;
                }

                .showSearchIcon .search-input {
                    --input-padding-left: 0;
                }

                .input-chevron {
                    --icon-color: #{$color-blue};
                }
            }
        }

        .multiselect-single {
            @include prevent-select;
            @include padding-start(var(--input-padding, #{$input-padding}));

            overflow: auto;
            display: flex;
            align-items: center;
            min-height: var(--input-height, #{$input-height});
            font-size: var(--input-font-size, #{$input-font-size});
            flex: 1;
            white-space: nowrap;

            .focus-input {
                @include hidden-input;
                padding: 0;
            }
        }

        .placeholder {
            color: $input-placeholder-color;
            font-size: $font-size-sm;
        }

        .search-input {
            border: none;
        }

        .search-input::-webkit-search-decoration,
        .search-input::-webkit-search-cancel-button,
        .search-input::-webkit-search-results-button,
        .search-input::-webkit-search-results-decoration {
            display: none;
        }

        &.error {
            .input-container {
                @include selected-input-border($input-error-color);

                label {
                    color: $input-error-color;
                }

                .input-chevron {
                    --icon-color: #{$input-error-color};
                }
            }

            .assistive-text {
                color: $input-error-color;
            }
        }
    }

    .no-results {
        padding: $spacing-200;
        color: $color-text-disabled;
        font-style: italic;
    }
</style>

<template>
    <Modal
        :is-open="isOpen"
        dialog
        class="confirm-modal"
        :size="size"
    >
        <h4 v-if="title" class="title">
            {{ title }}
        </h4>

        <p class="message" :class="{ 'message-without-title': !title }">
            <slot name="message">
                {{ message }}
            </slot>
        </p>

        <div>
            <slot />
        </div>

        <section class="actions">
            <slot name="cancel">
                <TextButton
                    v-if="!confirmOnly"
                    gray
                    class="cancel-button"
                    @click.stop="cancel"
                >
                    {{ cancelLabel || $designSystem.i18n.messages['modal.cancel.button'] }}
                </TextButton>
            </slot>

            <slot name="confirm">
                <TextButton
                    :destructive="destructive"
                    class="confirm-button"
                    @click.stop="confirm"
                >
                    {{ confirmLabel }}
                </TextButton>
            </slot>
        </section>
    </Modal>
</template>

<script>
import Modal from './Modal.vue';
import { TextButton } from '../Button';

export default {
    name: 'DsConfirmDialog',

    compatConfig: { MODE: 3 },

    components: {
        Modal,
        TextButton,
    },

    props: {
        /**
         * Controls the open state of the modal.
         */
        isOpen: Boolean,

        /**
         * Title of the modal
         */
        title: {
            type: String,
            default: '',
        },

        /**
         * Message contained in the modal
         */
        message: String,

        /**
         * The label for the confirm button
         */
        confirmLabel: String,

        /**
         * The label for the cancel button
         */
        cancelLabel: String,

        /**
         * Omits the cancel button
         */
        confirmOnly: Boolean,

        /**
         * Styles the dialog to be destructive
         */
        destructive: Boolean,

        /**
         * Determines the size of the modal
         */
        size: {
            type: String,
            default: 'xs',
            options: ['xs', 'sm', 'med'],
        },
    },

    emits: [
        'cancel',
        'confirm',
    ],

    methods: {
        cancel() {
            this.$emit('cancel');
        },

        confirm() {
            this.$emit('confirm');
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .title {
        font-weight: $font-weight-regular;
        line-height: $line-height-lg;
        margin-bottom: $spacing-200;
    }

    .message {
        color: $color-text-subtle;
        font-size: $font-size-md;
        line-height: $line-height-lg;
        margin: 0;
    }

    .message-without-title {
        color: $color-text-normal;
    }

    .actions {
        display: flex;
        justify-content: flex-end;
        gap: $spacing-100;
        margin: $spacing-200 (-$spacing-100) (-$spacing-100);
    }
</style>

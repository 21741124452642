<script>
import dom from '../../mixins/dom';

export default {
    name: 'DsBodyClickHandler',

    compatConfig: { MODE: 3 },

    mixins: [dom],

    props: {
        target: HTMLElement,
        enabled: {
            type: Boolean,
            default: true,
        },
    },

    emits: ['click'],

    watch: {
        enabled(enabled) {
            if (enabled) {
                this.addEventListener();
            } else {
                this.removeEventListener();
            }
        },
    },

    mounted() {
        if (this.enabled) {
            this.addEventListener();
        }
    },

    beforeUnmount() {
        this.removeEventListener();
    },

    methods: {
        addEventListener() {
            setTimeout(() => {
                window.addEventListener('click', this.handleClick);

                if (this.dom_isTouchDevice) {
                    window.addEventListener('touchend', this.handleClick);
                }
            });
        },

        removeEventListener() {
            setTimeout(() => {
                window.removeEventListener('click', this.handleClick);

                if (this.dom_isTouchDevice) {
                    window.removeEventListener('touchend', this.handleClick);
                }
            });
        },

        handleClick(e) {
            const target = this.target || this.$el;

            if (!this.dom_clickedTarget(e, target)) {
                this.$emit('click', e);
            }
        },
    },

    render() {
        return typeof this.$slots.default === 'function' ? this.$slots.default({}) : this.$slots.default;
    },
};
</script>

<template>
    <a
        class="inline-alert-footer-link"
        :href="href"
        rel="noopener noreferrer"
        target="_blank"
    >
        <!-- @slot External hyperlink text -->
        <slot />
        <Icon class="inline-alert-footer-link-icon" name="external-link" />
    </a>
</template>

<script>
import { Icon } from '../Icon';

export default {
    name: 'DsInlineAlertFooterLink',

    compatConfig: { MODE: 3 },

    components: { Icon },

    props: {
        /**
         * URL user will be taken to when clicked
         */
        href: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../styles/common";

.inline-alert-footer-link {
    display: flex;
    align-items: center;
    --icon-size: 1rem;
}

.inline-alert-footer-link-icon {
    @include margin-start($spacing-100);
}
</style>

<template>
    <div class="overlay" />
</template>

<script>
import overlays from '../../mixins/overlays';

export default {
    compatConfig: { MODE: 3 },

    mixins: [overlays],

    mounted() {
        this.overlays_setActive(true);
    },

    beforeUnmount() {
        this.overlays_setActive(false);
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .overlay {
        @include overlay-background;

        --modal-zindex: 800;
    }
</style>

<template>
    <div
        :class="['select-field', { active, 'has-selected': hasSelected, readonly, error: showInvalid }]"
    >
        <Dropdown
            :is-open="active"
            :pin-bottom="pinBottom"
            @close="close"
        >
            <div
                class="select-container"
                :class="{ 'no-label': !label }"
            >
                <select
                    tabindex="-1"
                    :required="required"
                    :name="name"
                    class="visually-hidden"
                    @change="onChange"
                >
                    <option value="" :selected="invalid">
                        {{ invalid }}
                    </option>
                    <option value="selected" :selected="!invalid" />
                    <option
                        v-for="option in dropdownOptions"
                        :key="option[valueProp]"
                        :value="option[valueProp]"
                    />
                </select>

                <label
                    v-if="label"
                    :for="name"
                    :class="{ required }"
                >
                    {{ label }}
                </label>

                <div class="select-input">
                    <input
                        ref="input"
                        type="text"
                        readonly="true"
                        :value="selectedLabel"
                        :name="name"
                        :tabindex="tabIndex"
                        :class="{ invalid: showInvalid }"
                        :placeholder="placeholder"
                        @keydown.up.prevent="dropdownKeys_arrowUp"
                        @keydown.down.prevent="dropdownKeys_arrowDown"
                        @keydown.tab="dropdownKeys_enter"
                        @keydown.enter.prevent.stop.self="dropdownKeys_enter"
                        @keydown.esc.stop="close"
                        @focus.stop.prevent="open"
                        @mousedown.stop.prevent="toggle"
                        @blur.prevent="close"
                    />

                    <div class="input-chevron" @mousedown.stop.prevent="toggle">
                        <Spinner v-if="loading" :size="24" />

                        <Icon v-else name="chevron-down" />
                    </div>
                </div>

                <span v-if="showError" class="error-text">
                    <!-- @slot Slot holding error text -->
                    <slot name="error" />
                </span>

                <span v-if="$slots.help" class="assistive-text">
                    <slot name="help" />
                </span>
            </div>

            <template #menu>
                <div>
                    <div v-show="loading" class="loading">
                        <Spinner />
                    </div>

                    <ul v-show="!loading" class="dropdown-menu-list">
                        <li
                            v-for="(option, i) in dropdownOptions"
                            v-show="dropdownOptions.length > 0"
                            :id="option[valueProp] || option"
                            :key="i"
                            class="dropdown-menu-item"
                            :class="[option.className ? option.className : '', { 'selected': isSelectedOption(option), 'highlight': i === dropdownKeys_pointer, dense }]"
                            :data-qa="option[labelProp]"
                            @mousedown.prevent.stop="select(option)"
                            @mouseover="dropdownKeys_pointer = i"
                        >
                            {{ option[labelProp] || option }}
                        </li>

                        <li v-show="dropdownOptions.length === 0">
                            <span class="status empty">
                                {{ noResultsLabel || $designSystem.i18n.messages['select.empty.results'] }}
                            </span>
                        </li>
                    </ul>
                </div>
            </template>
        </Dropdown>
    </div>
</template>

<script>
import dropdownKeys from '../../mixins/dropdownKeys';
import { Dropdown } from '../Dropdown';
import { Icon } from '../Icon';
import { Spinner } from '../Spinner';

import '../../assets/icons/chevron-down.svg';

export default {
    name: 'DsSelectField',

    compatConfig: { MODE: 3 },

    components: {
        Dropdown,
        Icon,
        Spinner,
    },

    mixins: [dropdownKeys],

    props: {
        /**
         * The vmodel value that is selected
         */
        modelValue: [String, Number, Object, Boolean],

        /**
         * Array of value label options
         */
        options: {
            type: Array,
            default: () => [],
        },

        /**
         * Label to render above the select field when active
         */
        label: {
            type: String,
            default: '',
        },

        /**
         * HTML select element name
         */
        name: {
            type: String,
            default: '',
        },

        /**
         * Placeholder text for when the select is empty
         */
        placeholder: {
            type: String,
            default: '',
        },

        /**
         * Prevents the user from changing the selection
         */
        readonly: {
            type: Boolean,
            default: false,
        },

        /**
         * Shows a loading spinner inside the select field and menu
         */
        loading: Boolean,

        /**
         * Required field for validation purposes
         */
        required: Boolean,

        /**
         * Controls visualization of rendering form validation error states
         */
        submitted: Boolean,

        /**
         * Force the error state to show if parent is controlling it
         */
        forceInvalid: Boolean,

        /**
         * Show selected label even if value is null/false/empty
         */
        allowNull: Boolean,

        /**
         * By default the vmodel output is the full selected object from options, but with this it\'s just the value itself
         */
        bindValueOnly: Boolean,

        /**
         * The name of the property in your options array that contains the label to display
         */
        labelProp: {
            type: String,
            default: 'label',
        },

        /**
         * The name of the property in your options array that contains the value
         */
        valueProp: {
            type: String,
            default: 'value',
        },

        /**
         * Text to display where there are no results
         */
        noResultsLabel: String,

        /**
         * If you want to compare selected properties against more than one value field
         */
        selectProperties: Array,

        /**
         * Pin the bottom of the menu to the bottom of the viewport for really long menus
         */
        pinBottom: Boolean,

        /**
         * Enables dense mode
         */
        dense: Boolean,
    },

    emits: ['update:modelValue'],

    data() {
        return {
            active: false,
            selected: this.modelValue,
            firstTimeOpen: false,
        };
    },

    computed: {
        showError() {
            return Boolean(this.$slots.error && this.submitted);
        },

        showInvalid() {
            return this.submitted && this.invalid;
        },

        hasSelected() {
            return this.selected || this.allowNull;
        },

        dropdownOptions() {
            return this.options || [];
        },

        selectedLabel() {
            if (this.hasSelected) {
                if (typeof this.selected === 'object' || this.bindValueOnly) {
                    const selected = this.dropdownOptions.find((option) => this.isSelectedOption(option));

                    return selected && selected[this.labelProp] || '';
                }

                return this.selected;
            }

            return '';
        },

        selectedIndex() {
            if (this.hasSelected) {
                if (typeof this.selected === 'object' || this.bindValueOnly) {
                    return this.dropdownOptions.findIndex((option) => this.isSelectedOption(option));
                }

                return this.dropdownOptions.findIndex((option) => {
                    if (typeof option === 'object') {
                        return option[this.valueProp] === this.selected;
                    }

                    return option === this.selected;
                });
            }

            return -1;
        },

        invalid() {
            return (this.required && !this.hasSelected) || this.forceInvalid;
        },

        tabIndex() {
            return this.readonly ? -1 : 0;
        },
    },

    watch: {
        dropdownOptions: {
            handler(dropdownOptions) {
                this.dropdownKeys_optionsModel = dropdownOptions;
            },
            deep: true,
        },

        modelValue: {
            handler(modelValue) {
                this.selected = modelValue;
            },
            deep: true,
        },
    },

    created() {
        this.dropdownKeys_optionsModel = this.dropdownOptions;

        if (this.selectedIndex !== -1) {
            this.$nextTick(() => {
                this.dropdownKeys_pointer = this.selectedIndex;
            });
        }
    },

    methods: {
        isSelectedOption(option) {
            if (!this.selectProperties) {
                if (!this.bindValueOnly) {
                    return this.hasSameProperty(option) || this.selected === option;
                }

                return this.selected === option[this.valueProp];
            }

            return this.selectProperties.every((property) => this.hasSameProperty(option, property));
        },

        hasSameProperty(option, property = this.valueProp) {
            if (option && this.selected && option[property] !== undefined && this.selected[property] !== undefined) {
                const value = option[property];
                const currentValue = this.selected[property];

                return currentValue === value || (currentValue == null && value == null);
            }

            return false;
        },

        onChange({ target }) {
            if (target && target.value) {
                const changedOption = this.dropdownOptions.find((dropdownOption) => dropdownOption[this.valueProp] === target.value);

                if (changedOption) {
                    this.select(changedOption);
                }
            }
        },

        onEnter(option) {
            this.select(option);
        },

        toggle() {
            if (this.active) {
                this.close();
            } else {
                this.open();
            }
        },

        open() {
            if (!this.readonly) {
                this.active = true;
                this.$refs.input.focus();

                if (!this.firstTimeOpen && this.dropdownKeys_pointer !== -1) {
                    this.firstTimeOpen = true;

                    this.$nextTick(() => {
                        this.dropdownKeys_scrollTo(this.dropdownKeys_pixelsToPointerTop());
                    });
                }
            }
        },

        close() {
            this.active = false;
        },

        select(option) {
            const newValue = this.bindValueOnly ? option[this.valueProp] : option;

            if (this.selected !== newValue) {
                this.selected = newValue;

                /**
                 * Triggers input vmodel value changes
                 */
                this.$emit('update:modelValue', newValue);
            }

            this.close();
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .empty {
        padding: 0 $spacing-200;
    }

    .select-field {
        --dropdown-minwidth: 100%;

        position: relative;
        min-height: $input-height;
        width: 100%;

        input {
            @include ellipsis;
        }

        .error-text {
            display: none;
        }

        &.readonly {
            input {
                @include input-readonly;
            }

            label {
                @include input-readonly-label;
            }
        }

        &.has-selected {
            label {
                @include select-label;
            }
        }

        &.active {
            label {
                @include input-label-selected;
                color: $color-blue;
            }

            input {
                @include selected-input-border($color-blue);
            }

            .input-chevron {
                --icon-color: #{$color-blue};
            }
        }

        &.error {
            input {
                @include selected-input-border($select-field-error-color);
            }

            label {
                color: $color-red;
            }

            .input-chevron {
                --icon-color: #{$color-red};
            }

            .error-text {
                display: block;

                ~ .assistive-text {
                    display: none;
                }
            }
        }
    }

    .select-container {
        @include input-field;

        input {
            @include padding-end($spacing-400, true);
            cursor: pointer;

            &.invalid {
                @include selected-input-border($select-field-error-color);
            }
        }

        label {
            @include label;
            z-index: 1;

            &.required:after {
                @include input-label-required;
            }
        }
    }

    .select-input {
        position: relative;
        width: 100%;

        input {
            @include prevent-select;
        }
    }

    .dropdown-menu-item {
        @include prevent-select;

        &.dense {
            padding: $spacing-100 $spacing-200;
            font-size: $font-size-sm;
        }
    }

    .dropdown-menu {
        min-width: 100%;
        top: 100%;
    }

    .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $spacing-200 0;
    }
</style>

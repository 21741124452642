<template>
    <span>
        <span v-if="showHighlight">
            <!-- the below spans must all be on the same to ensure whitespace displays properly -->
            {{ prefixValue }}<span class="matching">{{ matchingValue }}</span>{{ suffixValue }}
        </span>

        <span v-else>
            {{ content }}
        </span>
    </span>
</template>

<script>
export default {
    name: 'DsTextHighlighter',

    compatConfig: { MODE: 3 },

    props: {
        /**
         * String part to be highlighted
         */
        highlight: {
            type: String,
            default: '',
        },

        /**
         * Full string value to be displayed
         */
        content: {
            type: String,
            required: true,
        },
    },

    computed: {
        showHighlight() {
            return Boolean(this.highlight)
                    && this.matchingValue.length > 0;
        },

        prefixEnd() {
            return this.highlight && this.content ? this.content.toLowerCase().indexOf(this.highlight.toLowerCase()) : -1;
        },

        suffixStart() {
            const prefixEndIndex = this.prefixEnd;

            return prefixEndIndex > -1 ? prefixEndIndex + this.highlight.length : -1;
        },

        prefixValue() {
            const prefixEndIndex = this.prefixEnd;

            return prefixEndIndex > 0 ? this.content.substring(0, prefixEndIndex) : '';
        },

        matchingValue() {
            const prefixEndIndex = this.prefixEnd;
            const suffixStartIndex = this.suffixStart;

            return prefixEndIndex > -1 && suffixStartIndex > -1 ? this.content.substring(prefixEndIndex, suffixStartIndex) : '';
        },

        suffixValue() {
            const suffixStartIndex = this.suffixStart;

            return suffixStartIndex > -1 ? this.content.substring(suffixStartIndex) : '';
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .matching {
        color: var(--text-highlighter-matching-color, #{$color-blue});
        font-weight: var(--text-highlighter-matching-weight, #{$font-weight-semibold});
    }
</style>

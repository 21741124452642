<template>
    <div>
        <h4 class="ds-modal-header-title">
            <slot name="title">
                {{ title }}
            </slot>
        </h4>

        <p v-if="subtitle || $slots.subtitle" class="ds-modal-header-subtitle">
            <slot name="subtitle">
                {{ subtitle }}
            </slot>
        </p>
    </div>
</template>

<script>
export default {
    name: 'DsModalHeaderTitle',

    compatConfig: { MODE: 3 },

    props: {
        /**
         * Title to display
         */
        title: String,

        /**
         * Subtitle to display
         */
        subtitle: String,
    },
};
</script>

<style lang="scss" scoped>
@import "../../styles/common";

.ds-modal-header-title {
    margin: 0;
    max-height: px-to-rem(24);
}

.ds-modal-header-subtitle {
    margin: 0;
    color: $color-text-subtle;
    font-size: $font-size-xs;
}
</style>

<template>
    <Popover
        v-bind="$attrs"
        :is-open="show"
        hide-arrow
    >
        <template #reference>
            <div class="reference">
                <span v-show="show" class="pulse-container">
                    <PulseGraphic />
                </span>

                <!-- @slot Reference target element -->
                <slot name="reference" />
            </div>
        </template>

        <div class="popover-content" @click.stop.prevent>
            <section class="top">
                <span
                    v-if="hasClose"
                    class="close-container"
                    @click.stop.prevent="cancel"
                >
                    <Icon name="x" />
                </span>

                <div v-if="title" class="guide-header">
                    {{ title }}
                </div>
                <div v-if="bodyText" class="guide-body">
                    {{ bodyText }}
                </div>
            </section>

            <div
                class="guide-footer"
                data-qa="guide-confirm-button"
                @click.stop.prevent="confirm"
            >
                {{ confirmText }}
            </div>
        </div>
    </Popover>
</template>

<script>
import { Popover } from '../Popover';
import PulseGraphic from '../PulseGraphic/PulseGraphic.vue';
import { Icon } from '../Icon';

export default {
    name: 'DsGuide',

    compatConfig: { MODE: 3 },

    components: {
        Popover,
        PulseGraphic,
        Icon,
    },

    props: {
        /**
         * Title text
         */
        title: {
            type: String,
            default: '',
        },

        /**
         * Body text
         */
        bodyText: {
            type: String,
            default: '',
        },

        /**
         * Confirmation button text
         */
        confirmText: {
            type: String,
            required: true,
        },

        /**
         * Should visually display the guide
         */
        show: Boolean,

        /**
         * Should display an x in the upper right to close it out
         */
        hasClose: Boolean,
    },

    emits: [
        'cancel',
        'confirm',
    ],

    methods: {
        cancel() {
            /**
             * Called on x clicked
             */
            this.$emit('cancel');
        },

        confirm() {
            /**
             * Called on confirm button clicked
             */
            this.$emit('confirm');
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .popover .popover-content {
        padding: 0;
        color: $color-paper;
        overflow: hidden;
    }

    .popover-container {
        --popover-background-color: #{$color-gray-900};
    }

    .pulse-container {
        @include position(absolute 0 auto auto 0);
        @include margin(var(--pulse-container-margin, 0 0 0 $spacing-400));
    }

    .top {
        padding: $spacing-200;
        position: relative;
    }

    .close-container {
        @include transition(opacity);
        @include float-end;
        @include position(relative, (-$spacing-100) (-$spacing-100) auto auto);
        @include margin-start($spacing-100);

        line-height: 0;
        cursor: pointer;

        &:hover {
            opacity: 0.6;
        }
    }

    .reference {
        position: relative;
    }

    .guide-header {
        margin-bottom: $spacing-200;
        font-weight: $font-weight-semibold;
        font-size: $font-size-md;
    }

    .guide-body {
        font-size: $font-size-md;
        word-break: break-word;
    }

    .guide-footer {
        @include transition(background-color);

        background-color: $color-ink-800;
        color: $color-paper;
        width: 100%;
        text-align: center;
        font-size: $font-size-md;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        padding: $spacing-125 $spacing-200;
        font-weight: $font-weight-semibold;
        cursor: pointer;

        &:hover {
            background-color: $color-ink;
        }
    }
</style>

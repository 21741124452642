<template>
    <div class="checkbox-group">
        <Checkbox
            v-for="(box, i) in displayBoxes"
            :key="i"
            :model-value="box.checked"
            :label="box.label"
            :error="box.error"
            :disabled="box.disabled"
            :required="box.required"
            :color="box.color"
            :border-color="box['border-color']"
            :icon-color="box['icon-color']"
            @update:model-value="handleChange(box.value)"
        >
            <div v-if="$slots[`checkbox${i}`]">
                <!-- @slot Label content for each checkbox -->
                <slot :name="`checkbox${i}`" />
            </div>
        </Checkbox>

        <Checkbox
            v-if="selectAll || $slots.selectAll"
            :label="selectAll ? selectAll[labelProp] : ''"
            :error="selectAll ? selectAll.error : false"
            :disabled="selectAll ? selectAll.disabled : false"
            :required="selectAll ? selectAll.required : false"
            :partial="someSelected"
            :model-value="allSelected"
            @update:model-value="handleSelectAll"
        >
            <div v-if="$slots.selectAll">
                <!-- @slot Label content for select all -->
                <slot name="selectAll" />
            </div>
        </Checkbox>
    </div>
</template>

<script>
import Checkbox from './Checkbox.vue';

export default {
    name: 'DsCheckboxGroup',

    compatConfig: { MODE: 3 },

    components: {
        Checkbox,
    },

    props: {
        /**
         * Collection of checkboxes to display in the group
         */
        boxes: {
            type: Array,
            required: true,
        },

        /**
         * Represents the label property to display next to the checkboxes in the group
         */
        labelProp: {
            type: String,
            default: 'label',
        },

        /**
         * Determines which value property to pass into the checkboxes in the group
         */
        valueProp: {
            type: String,
            default: 'value',
        },

        /**
         * An object that describes the state of the "selectAll" checkbox
         */
        selectAll: {
            type: Object,
            default: null,
        },

        /**
         * An array of checkbox objects that determines the state of each checkbox in the group
         */
        modelValue: {
            type: [Array, String],
            required: true,
            default: () => [],
        },

        /**
         * Required field for validation purposes
         */
        required: Boolean,
    },

    emits: ['update:modelValue'],

    computed: {
        allSelected() {
            return this.modelValue.length === this.boxes.length;
        },

        someSelected() {
            const selectionCount = this.modelValue.length;

            return selectionCount > 0 && selectionCount < this.boxes.length;
        },

        displayBoxes() {
            const {
                boxes,
                modelValue,
                valueProp,
                labelProp,
            } = this;

            return boxes.map((box) => {
                const boxValue = box[valueProp];

                return {
                    ...box,
                    value: boxValue,
                    label: box[labelProp],
                    checked: modelValue.includes(box[valueProp]),
                };
            });
        },
    },

    methods: {
        handleChange(boxValue) {
            const { modelValue } = this;

            const updated = this.displayBoxes
                .map((x) => x.value)
                .filter((x) => {
                    if (boxValue === x) {
                        return !modelValue.includes(x);
                    }

                    return modelValue.includes(x);
                });

            this.$emit('update:modelValue', updated);
        },

        handleSelectAll() {
            const updated = this.someSelected || this.allSelected
                ? []
                : this.displayBoxes.map((x) => x.value);

            this.$emit('update:modelValue', updated);
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .checkbox-group {
        --checkbox-padding: var(--single-checkbox-padding, #{$spacing-200 $spacing-100});
        --checkbox-hover-background: var(--checkbox-group-hover-background, transparent);
    }
</style>

<template>
    <div class="color-field">
        <div
            class="color-field-input"
            :class="{ 'active': isOpen }"
            @click="openPopover"
        >
            <span
                class="color-preview"
                :style="{ 'background-color': modelValue }"
            />

            <input
                ref="hexInput"
                v-model="valueInput"
                class="color-text"
                type="text"
                :submitted="true"
                :maxlength="7"
                :pattern="hexRegex"
                @keydown.enter.prevent="select"
            />

            <Icon name="x" @click.stop="resetValue" />
        </div>

        <Popover
            :is-open="isOpen"
            position="bottom"
            @close="closePopover"
        >
            <div class="popover-content">
                <ColorPicker v-model="valueInput" />
            </div>
        </Popover>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';

import { Popover } from '../Popover';
import { Icon } from '../Icon';
import ColorPicker from './ColorPicker.vue';

export default {
    name: 'DsColorField',

    compatConfig: { MODE: 3 },

    components: {
        ColorPicker,
        Popover,
        Icon,
    },

    props: {
        /**
         * Hex value of the color field
         */
        modelValue: {
            type: String,
            required: true,
        },
    },

    emits: ['update:modelValue'],

    data() {
        return {
            isOpen: false,
            hexRegex: /^#?([a-fA-F0-9]{6})$/,
            lastValue: this.modelValue,
            delay: 300,
        };
    },

    computed: {
        valueInput: {
            get() {
                return this.modelValue;
            },

            set(value) {
                if (this.hexRegex.test(value)) {
                    const hexValue = value.startsWith('#') ? value : `#${value}`;

                    this.$emit('update:modelValue', hexValue);
                    this.focusInput();
                }
            },
        },
    },

    created() {
        this.focusInput = debounce(this.focusInput, this.delay);
    },

    methods: {
        updateValue({ hex }) {
            this.$emit('update:modelValue', hex);
        },

        openPopover() {
            this.lastValue = this.modelValue;
            this.isOpen = true;
            this.focusInput();
        },

        closePopover() {
            this.isOpen = false;
        },

        resetValue() {
            this.valueInput = this.lastValue;
        },

        focusInput() {
            this.$refs.hexInput.focus();
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .color-field-input {
        @include transition(border-color);

        color: $color-text-normal;
        display: grid;
        grid-template-columns: $input-height px-to-rem(104px) px-to-rem(40px);
        border: $input-border;
        border-color: $input-border-color;
        border-radius: $input-border-radius;
        background: $color-paper;
        overflow: hidden;
        height: $input-height;
        width: px-to-rem(200px);

        &:hover {
            cursor: text;
        }

        > * {
            height: $input-height;
        }
    }

    .color-text {
        padding: 0 $spacing-100;
    }

    .active {
        border-color: $input-color-active;

        .icon {
            display: block;
        }
    }

    .color-preview {
        background: $color-gray-200;

        &:hover {
            cursor: pointer;
        }
    }

    input {
        font-size: $font-size-lg;
        text-transform: uppercase;
        border: 0;
    }

    .icon {
        display: none;
        width: 100%;
        padding: $spacing-100;
        --icon-color: #{$color-text-normal};

        &:hover {
            background: $color-gray-100;
            cursor: pointer;
        }
    }
</style>

<style lang="scss">
    @import "../../styles/common";

    .color-field {
        .popover-container {
            .popover {
                margin-top: 0;

                .popover-content {
                    padding: $spacing-100;
                }
            }
        }
    }
</style>

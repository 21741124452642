<template>
    <section class="ds-card-header">
        <slot />
    </section>
</template>

<script>
export default {
    name: 'DsCardHeader',

    compatConfig: { MODE: 3 },
};
</script>

<style lang="scss" scoped>
@import "../../styles/common";

.ds-card-header {
    border-radius: $border-radius $border-radius 0 0;
    overflow: hidden;
}
</style>

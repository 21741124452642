<template>
    <div  :class="{ 'button-group':true,responsive }">
        <slot>
            <ButtonToggleOption
                v-for="(option, i) in options"
                :key="i"
                :selected="isSelected(option)"
                :block="block"
                :data-qa="option.value"
                @click="updateValue(option)"
            >
                {{ option.label }}
            </ButtonToggleOption>
        </slot>
    </div>
</template>

<script>
import ButtonToggleOption from './ButtonToggleOption.vue';

export default {
    name: 'DsButtonToggle',

    compatConfig: { MODE: 3 },

    components: { ButtonToggleOption },

    props: {
        /**
         * Allow the button toggle to expand to fit its container
         */
        block: Boolean,

        /**
         * Array of value/label options
         */
        options: {
            type: Array,
            default: () => [],
        },

        /**
         * Allow multiple selections in array format
         */
        multiple: Boolean,

        /**
         * (DEPRECATED) Prevent deselect on a single button toggle similar to how radio functions
         */
        preventSingleDeselect: Boolean,

        /**
         * Stack buttons on mobile screens because you have lots of buttons in the toggle
         */
        responsive: Boolean,

        /**
         * Array of selected values
         */
        modelValue: [String, Number, Array],
    },

    emits: ['update:modelValue'],

    methods: {
        isSelected({ value }) {
            if (!this.multiple) {
                return this.modelValue === value;
            }

            return this.modelValue.includes(value);
        },

        updateValue({ value }) {
            if (!this.multiple) {
                if (this.preventSingleDeselect && this.modelValue === value) {
                    return null;
                }

                return this.$emit('update:modelValue', this.modelValue === value ? null : value);
            }

            const options = [...this.modelValue];
            const index = options.indexOf(value);

            if (index !== -1) {
                options.splice(index, 1);
            } else {
                options.push(value);
            }

            return this.$emit('update:modelValue', options);
        },
    },
};
</script>

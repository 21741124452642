export default {
    'modal.action.text': 'Save',
    'modal.cancel.button': 'Cancel',
    'date.format': 'MM/DD/YYYY',
    'tablegrid.selected': 'Selected',
    'tablegrid.actions': 'Actions',
    'upload.label': 'Upload a file',
    'upload.complete.label': 'Upload complete',
    'upload.allowable': 'All file types are acceptable',
    'upload.instructions': 'Drag and drop here or browse',
    'select.add': 'Add',
    'select.empty.results': 'No results',
    'select.empty.options': 'No options',
    'errorbanner.dismiss': 'Dismiss',
    'phone.input.label': 'Phone',
    'datetime.start.label': 'Start',
    'datetime.starttime.label': 'Start time',
    'datetime.end.label': 'End',
    'datetime.endtime.label': 'End time',
};

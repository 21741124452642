/*
* Mixin to apply logic of navigating and selecting drop down menu options with keyboard
* 1. Import the mixin and add to the component
* 2. The options binding name must be configured in component created with optionsModel
* 3. Component is responsible for applying styling to the dropdown item with the active pointer
* 4. Component is responsible for resetting the pointer on hover over an item
* 5. Component requires you to bind your select method in component created
*/

import windowMixin from './windowMixin';

export default {
    mixins: [windowMixin],

    data() {
        return {
            dropdownKeys_pointer: -1,
            dropdownKeys_optionsModel: [],
        };
    },

    watch: {
        active(value) {
            if (value) {
                this.dropdownKeys_resetPointer();
            }
        },
    },

    methods: {
        dropdownKeys_adjustScroll() {
            const pixelsToPointerTop = this.dropdownKeys_pixelsToPointerTop();
            const pixelsToPointerBottom = this.dropdownKeys_pixelsToPointerBottom();
            const {
                scrollTop,
                offsetHeight,
                scrollHeight,
            } = this.dropdownKeys_getDropdownMenu();

            const firstOptionIsLabel = this.dropdownKeys_optionsModel[0] && this.dropdownKeys_optionsModel[0].isLabel;

            if (this.dropdownKeys_pointer === 0 || (this.dropdownKeys_pointer === 1 && firstOptionIsLabel)) {
                this.dropdownKeys_scrollTo(0);
            } else if (this.dropdownKeys_pointer === this.dropdownKeys_optionsModel.length - 1) {
                this.dropdownKeys_scrollTo(scrollHeight);
            } else if (pixelsToPointerTop <= scrollTop) {
                this.dropdownKeys_scrollTo(pixelsToPointerTop);
            } else if (pixelsToPointerBottom >= (offsetHeight + scrollTop)) {
                this.dropdownKeys_scrollTo(scrollTop + this.dropdownKeys_pointerHeight());
            }
        },

        dropdownKeys_isAllLabelsOnly() {
            return Array.isArray(this.dropdownKeys_optionsModel) && this.dropdownKeys_optionsModel.every((option) => option.isLabel);
        },

        dropdownKeys_arrowDown() {
            if (this.dropdownKeys_pointer < this.dropdownKeys_optionsModel.length - 1) {
                this.dropdownKeys_pointer++;
                this.dropdownKeys_adjustScroll();
            } else {
                this.dropdownKeys_pointer = this.dropdownKeys_optionsModel.length - 1;
            }

            if (this.dropdownKeys_optionsModel[this.dropdownKeys_pointer] && this.dropdownKeys_optionsModel[this.dropdownKeys_pointer].isLabel && !this.dropdownKeys_isAllLabelsOnly()) {
                this.dropdownKeys_arrowDown();
            }
        },

        dropdownKeys_arrowUp() {
            if (this.dropdownKeys_pointer > 0) {
                this.dropdownKeys_pointer--;
                this.dropdownKeys_adjustScroll();

                if (this.dropdownKeys_optionsModel[this.dropdownKeys_pointer].isLabel) {
                    this.dropdownKeys_arrowUp();
                }
            } else if (this.dropdownKeys_optionsModel[this.dropdownKeys_pointer] && this.dropdownKeys_optionsModel[this.dropdownKeys_pointer].isLabel) {
                this.dropdownKeys_arrowDown();
            } else {
                this.dropdownKeys_pointer = 0;
            }
        },

        dropdownKeys_enter() {
            if (this.dropdownKeys_optionsModel[this.dropdownKeys_pointer]) {
                const option = this.dropdownKeys_optionsModel[this.dropdownKeys_pointer];

                if (this.onEnter) {
                    this.onEnter(option);
                }
            }
        },

        dropdownKeys_getDropdownMenu() {
            return this.dropdownMenuSelector ? this.$el.querySelector(this.dropdownMenuSelector)
                : this.$el.querySelector('.dropdown-menu');
        },

        dropdownKeys_getDropdownMenuListItems() {
            return this.dropdownKeys_getDropdownMenu().querySelectorAll('li');
        },

        dropdownKeys_getListItemHeight(item) {
            if (!item || !item.nodeType) {
                return 0;
            }

            let height = item ? item.offsetHeight : 0;
            const style = this.window_getComputedStyle(item);

            height += parseInt(style.marginBottom, 10) + parseInt(style.marginTop, 10);

            return height;
        },

        dropdownKeys_pixelsToPointerTop() {
            let pixelsToPointerTop = 0;
            const dropdownMenu = this.dropdownKeys_getDropdownMenu();
            const dropdownMenuHeader = dropdownMenu.querySelector('.dropdown-header');
            const dropdownMenuListItems = this.dropdownKeys_getDropdownMenuListItems();
            const pointerStart = dropdownMenuHeader ? 1 : 0;

            for (let i = pointerStart; i < this.dropdownKeys_pointer + pointerStart; i++) {
                pixelsToPointerTop += this.dropdownKeys_getListItemHeight(dropdownMenuListItems[i]);
            }

            if (dropdownMenuHeader) {
                pixelsToPointerTop += this.dropdownKeys_getListItemHeight(dropdownMenuHeader);
            }

            return pixelsToPointerTop;
        },

        dropdownKeys_pixelsToPointerBottom() {
            return this.dropdownKeys_pixelsToPointerTop() + this.dropdownKeys_pointerHeight();
        },

        dropdownKeys_pointerHeight() {
            const element = this.dropdownKeys_getDropdownMenuListItems()[this.dropdownKeys_pointer];

            return element ? this.dropdownKeys_getListItemHeight(element) : 0;
        },

        dropdownKeys_resetPointer() {
            this.dropdownKeys_pointer = -1;
            this.dropdownKeys_arrowDown();

            if (this.selected) {
                const dropdownMenuListItems = this.dropdownKeys_getDropdownMenuListItems();
                let selectedIndex = -1;

                for (let i = 0; i < dropdownMenuListItems.length; i++) {
                    const item = dropdownMenuListItems[i];

                    if (item.classList.contains('selected')) {
                        selectedIndex = i;

                        break;
                    }
                }

                if (selectedIndex) {
                    if (this.allowAdd) {
                        selectedIndex -= 1;
                    }

                    if (this.showBlank) {
                        selectedIndex -= 1;
                    }

                    this.dropdownKeys_pointer = selectedIndex;
                }
            }
        },

        dropdownKeys_scrollTo(position) {
            this.dropdownKeys_getDropdownMenu().scrollTop = position;
        },
    },
};

export const isDocument = (target) => {
    return target === document || target === document.body;
};

export const getScrollParent = (element, horizontal) => {
    if (element === null || element === document.body) {
        return document.body;
    }

    if (!element) {
        return null;
    }

    if (element === document) {
        return document;
    }

    const {
        overflow,
        overflowY,
        overflowX,
    } = window.getComputedStyle(element, null);

    if (horizontal && /(auto|scroll)/.test([overflow, overflowX].join(''))) {
        return element;
    }

    if (/(auto|scroll)/.test([overflow, overflowY].join(''))) {
        return element;
    }

    return getScrollParent(element.parentNode);
};

export const distanceToTopEdge = (element, relativeElement) => {
    if (element && relativeElement) {
        const elementTop = element.getBoundingClientRect().top;

        if (relativeElement === document.body || relativeElement === document) {
            return elementTop;
        }

        const relativeElementTop = relativeElement.getBoundingClientRect().top;

        return elementTop - relativeElementTop;
    }

    return 0;
};

export const distanceToBottomEdge = (element, relativeElement, fromTop = false) => {
    const elementExists = element && typeof element.getBoundingClientRect === 'function';

    if (elementExists && relativeElement) {
        const elementPoint = fromTop ? element.getBoundingClientRect().top : element.getBoundingClientRect().bottom;

        if (relativeElement === document.body || relativeElement === document) {
            return window.innerHeight - elementPoint;
        }

        const relativeElementBottom = relativeElement.getBoundingClientRect().bottom;

        return relativeElementBottom - elementPoint;
    }

    return 0;
};

<template>
    <li
        class="tab-header-item"
        :class="{ 'tab-header-item-active': active }"
        @click="$emit('click', $event)"
    >
        <slot>
            <Icon v-if="icon" :name="icon" />

            {{ label }}
        </slot>
    </li>
</template>

<script>
import { Icon } from '../Icon';

export default {
    name: 'DsTabHeaderItem',

    components: { Icon },

    emits: ['click'],

    props: {
        active: Boolean,

        icon: String,

        label: String,
    },
};
</script>

<style lang="scss" scoped>
@import "../../styles/common";

$tab-bottom-border-size: px-to-rem(2);

.tab-header-item {
    @include transition(color, border-color);
    cursor: pointer;
    display: flex;
    gap: $spacing-100;
    align-items: center;
    height: px-to-rem(40);
    line-height: px-to-rem(40);
    color: $tab-color;
    border-bottom: #{$tab-bottom-border-size} solid transparent;
    padding: 0 $spacing-150;
    padding-top: #{$tab-bottom-border-size};
    --icon-transition: none;

    &:hover {
        border-color: $tab-border-color-hover;
    }

    &.tab-header-item-active {
        border-color: var(--primary-color, #{$tab-color-active});
        color: var(--primary-color, #{$tab-text-color-active});
        font-weight: $tab-font-weight;

        a {
            color: var(--primary-color, #{$tab-text-color-active});
            border-color: var(--primary-color, #{$tab-color-active});
        }
    }
}
</style>

<template>
    <div class="tabset">
        <div class="tab-header">
            <TabHeader>
                <TabHeaderItem
                    v-for="tab in tabs"
                    :key="uniqueKey_generate(tab)"
                    :active="tab.show"
                    :icon="tab.icon"
                    :label="tab.label"
                    :class="tab.classList"
                    :data-qa="tab.dataQa"
                    @click.prevent="select(tab)"
                />
            </TabHeader>

            <div v-if="hasOptions" class="tab-options">
                <!-- @slot Slot for options -->
                <slot name="options" />
            </div>
        </div>

        <div class="tab-content">
            <!-- @slot Slot for Tab components -->
            <slot />
        </div>
    </div>
</template>

<script>
import mitt from 'mitt';
import TabHeader from './TabHeader.vue';
import TabHeaderItem from './TabHeaderItem.vue';
import uniqueKeyMixin from '../../mixins/uniqueKey';

export default {
    name: 'DsTabset',

    compatConfig: { MODE: 3 },

    components: {
        TabHeader,
        TabHeaderItem,
    },

    mixins: [uniqueKeyMixin],

    provide() {
        return { emitter: this.emitter };
    },

    data() {
        return {
            activeTab: null,
            tabs: [],
            hasOptions: false,
        };
    },

    watch: {
        tabs: {
            handler() {
                this.emitter.emit('TAB_HEADER_TABS_UPDATED');
            },
            deep: true,
        },
    },

    beforeCreate() {
        this.emitter = mitt();
    },

    beforeUnmount() {
        this.emitter.off('*');
    },

    mounted() {
        this.checkOptions();
    },

    methods: {
        register(tab) {
            this.tabs.push(tab);
        },

        select(tab) {
            this.activeTab = tab;
            /**
             * Triggered when tab is selected
             */
            this.emitter.emit('activateTab', tab);
        },

        selectByIndex(index) {
            this.select(this.tabs[index]);
        },

        checkOptions() {
            this.$nextTick(() => {
                this.hasOptions = this.$slots.options !== undefined;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .tabset {
        @include tabs;
        @include prevent-select;
    }

    .tab-header {
        display: flex;
        align-items: center;

        @media($small) {
            display: block;
        }
    }

    .tab-options {
        @include margin-start($spacing-200);

        @media($small) {
            @include margin($spacing-100 0 $spacing-100);
        }
    }
</style>

export default {
    methods: {
        overlays_setActive(value) {
            if (value) {
                document.body.classList.add('overlay-active');

                if (this.$designSystem && typeof this.$designSystem.onOverlayActive === 'function') {
                    this.$designSystem.onOverlayActive();
                }
            } else {
                document.body.classList.remove('overlay-active');

                if (this.$designSystem && typeof this.$designSystem.onOverlayInactive === 'function') {
                    this.$designSystem.onOverlayInactive();
                }
            }
        },
    },
};

<template>
    <div :class="['avatar', type]" :style="computedStyle">
        <div
            v-if="hasImage"
            class="image"
            :style="computedImageStyle"
        />

        <!-- eslint-disable vue/no-v-html -->
        <div v-if="letter" class="letter" v-html="letter" />
        <!-- eslint-enable vue/no-v-html -->

        <Icon v-else-if="type == 'company'" name="company" />

        <Icon v-else name="user" />
    </div>
</template>

<script>
import md5 from 'md5';

import { Icon } from '../Icon';

import '../../assets/icons/user.svg';
import '../../assets/icons/company.svg';

export default {
    name: 'DsAvatar',

    compatConfig: { MODE: 3 },

    components: {
        Icon,
    },

    props: {
        /**
         * Directly use a url of an image you already have
         */
        imageUrl: {
            type: String,
            default: '',
        },

        /**
         * Use this to display the first character of their name
         */
        name: {
            type: String,
            default: '',
        },

        /**
         * Use this if you want to try and display their gravatar, you can use this together with name as a fallback
         */
        email: {
            type: String,
            default: '',
        },

        /**
         * Control the size of the avatar in px values
         */
        size: {
            type: Number,
            default: 40,
        },

        /**
         * Controls what type of avatar we have, which determines styling on the avatar
         */
        type: {
            type: String,
            default: 'default',
            options: ['default', 'user', 'company'],
        },
    },

    computed: {
        hasImage() {
            return Boolean(this.src);
        },

        letter() {
            if (!this.name || !this.name.length) {
                return null;
            }

            const names = this.name.split(' ');

            return names
                .map((name) => name.charAt(0))
                .slice(0, 2)
                .join('')
                .toUpperCase();
        },

        src() {
            return this.imageUrl ? this.imageUrl : this.gravatarImg;
        },

        gravatarImg() {
            if (this.email) {
                const hash = md5(this.email.trim().toLowerCase());

                return `//www.gravatar.com/avatar/${hash}?s=${this.size}&d=blank`;
            }

            return null;
        },

        computedStyle() {
            const pxSize = `${this.size}px`;

            return {
                height: pxSize,
                width: pxSize,
                fontSize: `${(this.size / 2 * 0.9)}px`,
            };
        },

        computedImageStyle() {
            return {
                backgroundImage: `url(${this.src})`,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .avatar {
        @include circle(px-to-rem(40px));
        --icon-size: 75%;

        overflow: hidden;
        flex-shrink: 0;
        position: relative;

        &.company {
            --icon-color: #{$avatar-color-company-text};
            background-color: $avatar-color-company;
            color: $avatar-color-company-text;
            border-radius: $border-radius;
        }

        &.user {
            --icon-color: #{$avatar-color-user-text};
            background-color: $avatar-color-user;
            color: $avatar-color-user-text;
        }

        &.default {
            --icon-color: #{$avatar-color-default-text};
            background-color: $avatar-color-default;
            color: $avatar-color-default-text;
        }
    }

    .image {
        background-size: cover;
        background-position: center center;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
</style>

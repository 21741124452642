<template>
    <div class="vc-chrome-saturation-wrap">
        <div
            ref="container"
            role="saturationPanel"
            class="vc-saturation"
            :style="{background: bgColor}"
            @mousedown="handleMouseDown"
            @touchmove="handleChange"
            @touchstart="handleChange"
        >
            <div class="vc-saturation--white" />
            <div class="vc-saturation--black" />
            <div
                role="currentSaturationPointer"
                class="vc-saturation-pointer"
                :style="{ top: pointerTop, left: pointerLeft }"
            >
                <div class="vc-saturation-circle" />
            </div>
        </div>
    </div>
</template>

<script>
import throttle from 'lodash.throttle';

// this file is from the vue-color repo
// https://github.com/xiaokaike/vue-color/blob/master/src/components/common/Saturation.vue
const handleChange = (e, skip, container) => {
    if (!skip) {
        e.preventDefault();
    }

    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;

    const xOffset = container.getBoundingClientRect().left + window.pageXOffset;
    const yOffset = container.getBoundingClientRect().top + window.pageYOffset;
    const pageX = e.pageX || (e.touches ? e.touches[0].pageX : 0);
    const pageY = e.pageY || (e.touches ? e.touches[0].pageY : 0);
    let left = pageX - xOffset;
    let top = pageY - yOffset;

    if (left < 0) {
        left = 0;
    } else if (left > containerWidth) {
        left = containerWidth;
    } else if (top < 0) {
        top = 0;
    } else if (top > containerHeight) {
        top = containerHeight;
    }

    const saturation = left / containerWidth;
    let bright = -(top / containerHeight) + 1;

    bright = bright > 0 ? bright : 0;
    bright = bright > 1 ? 1 : bright;

    return { bright, saturation };
};

export default {
    name: 'DsSaturation',

    compatConfig: { MODE: 3 },

    props: {
        value: Object,
    },

    emits: ['change'],

    computed: {
        bgColor() {
            return `hsl(${this.value.hsv.h}, 100%, 50%)`;
        },

        pointerTop() {
            return `${(-(this.value.hsv.v * 100) + 1) + 100}%`;
        },

        pointerLeft() {
            return `${this.value.hsv.s * 100}%`;
        },
    },

    beforeUnmount() {
        this.unbindEventListeners();
    },

    methods: {
        throttle: throttle(
            (fn, data) => {
                fn(data);
            },
            20,
            {
                leading: true,
                trailing: false,
            },
        ),

        handleChange(e, skip) {
            const { container } = this.$refs;
            const { bright, saturation } = handleChange(e, skip, container);

            this.throttle(this.onChange, {
                h: this.value.hsv.h,
                s: saturation,
                v: bright,
                a: this.value.hsv.a,
                source: 'hsva',
            });
        },

        onChange(param) {
            this.$emit('change', param);
        },

        handleMouseDown(e) {
            this.handleChange(e, true);
            this.bindEventListeners();
        },

        handleMouseUp() {
            this.unbindEventListeners();
        },

        bindEventListeners() {
            window.addEventListener('mousemove', this.handleChange);
            window.addEventListener('mouseup', this.handleChange);
            window.addEventListener('mouseup', this.handleMouseUp);
        },

        unbindEventListeners() {
            window.removeEventListener('mousemove', this.handleChange);
            window.removeEventListener('mouseup', this.handleChange);
            window.removeEventListener('mouseup', this.handleMouseUp);
        },
    },
};
</script>

<style lang="scss">
    @import "../../styles/common";

    .vc-chrome-saturation-wrap {
        margin-bottom: $spacing-200;
        width: 100%;
        padding-bottom: 55%;
        position: relative;
        border-radius: $border-radius;
        overflow: hidden;
    }

    .vc-saturation,
    .vc-saturation--white,
    .vc-saturation--black {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .vc-saturation--white {
        background: linear-gradient(to right, #fff, rgba(255,255,255,0));
    }

    .vc-saturation--black {
        background: linear-gradient(to top, #000, rgba(0,0,0,0));
    }

    .vc-saturation-pointer {
        cursor: pointer;
        position: absolute;
    }

    .vc-saturation-circle {
        cursor: head;
        width: px-to-rem(12px);
        height: px-to-rem(12px);
        box-shadow: 0 0 0 1.5px #fff, inset 0 0 1px 1px rgba(0,0,0,.3), 0 0 1px 2px rgba(0,0,0,.4);
        border-radius: 50%;
        transform: translate(-2px, -2px);
    }
</style>

<script>
import { h } from 'vue';

export default {
    compatConfig: { MODE: 3 },

    props: {
        /**
         * Data to display in a cell
         */
        data: Object,

        /**
         * Column data
         */
        column: Object,
    },

    render() {
        const { scopedSlots, property, numeric } = this.column;
        let content;

        if (scopedSlots) {
            content = scopedSlots.default(this.data);
        } else {
            content = this.data[property];
        }

        return h(
            'td',
            {
                class: {
                    numeric,
                },
            },
            [
                h(
                    'div',
                    {
                        class: {
                            cell: true,
                        },
                    },
                    content,
                ),
            ],
        );
    },
};
</script>

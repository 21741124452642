<template>
    <div class="status-container">
        <span v-if="showIcon" :class="['icon', type]">
            <Icon :name="iconName" />
        </span>

        <span :class="['text', 'semibold', {'high-emphasis': isHighEmphasis }, type]">
            <!-- @slot Slot holding status label -->
            <slot />
        </span>
    </div>
</template>

<script>
import { Icon } from '../Icon';

const EMPHASIS = {
    LOW: 'low',
    MEDIUM: 'medium',
    HIGH: 'high',
};

const TYPE = {
    DEFAULT: 'default',
    GOOD: 'good',
    WARNING: 'warning',
    ERROR: 'error',
};

export default {
    name: 'DsStatus',

    compatConfig: { MODE: 3 },

    components: {
        Icon,
    },

    props: {
        /**
         * Controls level of emphasis in the styling.
         * Must be 'low', 'medium', or 'high'
         */
        emphasis: {
            type: String,
            default: EMPHASIS.LOW,
            options: Object.values(EMPHASIS),
            validator: (value) => Object.values(EMPHASIS).includes(value),
        },

        /**
         * Controls the color of the status label.
         * Must be 'default', 'good', 'warning', or 'error'
         */
        type: {
            type: String,
            default: TYPE.DEFAULT,
            options: Object.values(TYPE),
            validator: (value) => Object.values(TYPE).includes(value),
        },
    },

    computed: {
        iconName() {
            let iconName;

            switch (this.type) {
            case (TYPE.GOOD):
                iconName = 'check-circle';
                break;
            case (TYPE.WARNING):
                iconName = 'alert-triangle';
                break;
            case (TYPE.ERROR):
                iconName = 'slash';
                break;
            default:
                iconName = 'info-circle';
                break;
            }

            return iconName;
        },

        isHighEmphasis() {
            return this.emphasis === EMPHASIS.HIGH;
        },

        showIcon() {
            return this.emphasis === EMPHASIS.MEDIUM;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .status-container {
        display: inline-flex;
        align-items: center;
    }

    .icon {
        @include margin($spacing-025 $spacing-025 0 0);

        --icon-size: 12px;
    }

    .icon,
    .text {
        font-size: $font-size-xs;

        &.default {
            color: $color-text-disabled;
            --icon-color: #{$color-ink-700};
        }

        &.good {
            color: $color-green-700;
            --icon-color: #{$color-green-700};
        }

        &.warning {
            color: $color-orange;
            --icon-color: #{$color-orange};
        }

        &.error {
            color: $color-red;
            --icon-color: #{$color-red};
        }
    }

    .text.high-emphasis {
        border-radius: .125rem;
        padding: 0 $spacing-050;

        &.default {
            color: $color-paper;
            background-color: $color-gray-700;
        }

        &.good {
            color: $color-paper;
            background-color: $color-green-700;
        }

        &.warning {
            color: $color-text-normal;
            background-color: $color-yellow;
        }

        &.error {
            color: $color-paper;
            background-color: $color-red;
        }
    }
</style>

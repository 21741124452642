import mitt from 'mitt';

export const createErrorBannerGlobal = (app) => {
    const emitter = mitt();

    const error = (options) => {
        emitter.emit('open', options);

        return null;
    };

    app.config.globalProperties.$errorBannerEmitter = emitter;
    app.config.globalProperties.$error = error;
};

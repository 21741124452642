<template>
    <component
        :is="markRaw(svg)"
        v-if="svg"
        :view-box.camel="viewBox"
        class="icon"
        :class="{ 'rtl-flip': rtlFlip }"
        aria-hidden="true"
    />
    <div v-else />
</template>

<script>

import { markRaw } from 'vue';

const cache = {};
export default {
    name: 'DsSvgIcon',
    methods: { markRaw },

    compatConfig: { MODE: 3 },

    props: {
        /**
         * The name of the icon
         */
        name: {
            type: String,
            required: true,
            default: '',
        },
    },

    data() {
        return {
            svg: cache[this.name],
        };
    },

    watch: {
        name: {
            immediate: true,
            handler(name, oldName) {
                // Refresh the svg if the name value changes, or if we don't have the svg loaded yet
                if(!this.svg || oldName) {
                    import(`../../assets/icons/${name}.svg?component`).then((loaded) => {
                        cache[name] = loaded.default;
                        this.svg = markRaw(loaded.default);
                    });
                }
            },
        }
    },

    computed: {
        rtlFlip() {
            const name = this.name.toLowerCase();

            return name.includes('left') || name.includes('right');
        },

        viewBox() {
            return '0 0 24 24';
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .icon {
        $size: #{var(--icon-size, #{$font-size-icons})};

        @include transition(var(--icon-transition, all));

        cursor: var(--icon-cursor, inherit);
        height: $size;
        width: $size;
        min-height: $size;
        min-width: $size;
        fill: var(--icon-color, currentcolor);
        margin: var(--icon-margin, 0);
        stroke: none;
    }

    .rtl-flip {
        @include rtl-flip;
    }
</style>

<style lang="scss">
    .is-icon-target,
    .is-icon-target * {
        stroke-width: inherit;
        fill: none;
        vector-effect: non-scaling-stroke;
    }
    .is-icon-dot {
        stroke-width: 0;
        fill: inherit;
    }
</style>

<template>
    <Trowser :show="show" class="table-grid-trowser">
        <div class="container">
            <div class="item-count">
                <span data-qa="selected-count">{{ selectedCount }}</span>

                <span class="item-label">
                    {{ selectedItemLabel || $designSystem.i18n.messages['tablegrid.selected'] }}
                </span>
            </div>

            <section v-if="selectedOptions">
                <OutlineButton
                    v-for="{ label, value, className } in selectedOptionsSorted"
                    :key="value"
                    :destructive="isDelete(value)"
                    :class="[className, {
                        'actions-individual': selectedOptions.length > 1,
                        'delete-button': isDelete(value),
                    }]"
                    :data-qa="label"
                    @click="handleButtonClick(value)"
                >
                    {{ label }}
                </OutlineButton>

                <Dropdown
                    v-if="selectedOptions.length > 1"
                    class="actions-grouped"
                >
                    <template #default>
                        <OutlineButton trailing-icon="chevron-down">
                            {{ actionsLabel || $designSystem.i18n.messages['tablegrid.actions'] }}
                        </OutlineButton>
                    </template>

                    <template #menu>
                        <ul class="dropdown-menu-list">
                            <li
                                v-for="option in selectedOptionsSorted"
                                :key="option.value"
                                class="dropdown-menu-item"
                                @click="handleButtonClick(option.value)"
                            >
                                {{ option.label }}
                            </li>
                        </ul>
                    </template>
                </Dropdown>
            </section>
        </div>
    </Trowser>
</template>

<script>
import { Trowser } from '../Trowser';
import { Dropdown } from '../Dropdown';
import { OutlineButton } from '../Button';

export default {

    name: 'DsTableGridTrowser',

    compatConfig: { MODE: 3 },

    components: {
        Trowser,
        Dropdown,
        OutlineButton,
    },

    props: {
        /**
         * Count of selected items
         */
        selectedCount: Number,

        /**
         * The label to display in trowser describing the selected items
         */
        selectedItemLabel: String,

        /**
         * The label to display in trowser actions button
         */
        actionsLabel: String,

        /**
         * Label value object array of options to take on selected rows
         */
        selectedOptions: Array,

        /**
         * Toggle to show the trowser or not
         */
        show: Boolean,
    },

    emits: ['selectedClicked'],

    computed: {
        selectedOptionsSorted() {
            const { selectedOptions } = this;

            let deleteOption = null;

            const sortedOptions = selectedOptions.filter((option) => {
                if (option.value === 'delete') {
                    deleteOption = option;
                }

                return option.value !== 'delete';
            });

            if (deleteOption) {
                sortedOptions.push(deleteOption);
            }

            return sortedOptions;
        },
    },

    methods: {
        handleButtonClick(event) {
            this.$emit('selectedClicked', event);
        },

        isDelete(value) {
            return value === 'delete';
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .table-grid-trowser {
        $trowser-height: px-to-rem(70px);

        min-height: var(--trowser-height, #{$trowser-height});
        display: flex;
        align-items: center;
    }

    .container {
        @include container;

        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $spacing-100;
    }

    .item-count {
        @include margin-end($spacing-400);

        font-size: $font-size-md;
    }

    .delete-button {
        margin: 0 $spacing-400;
    }

    .actions-individual {
        @media($medium-down) {
            display: none;
        }
    }

    .actions-grouped {
        display: none;

        @media($medium-down) {
            display: inline-flex;
        }
    }

    button:not(:last-of-type) {
        @include margin-end($spacing-100);
    }
</style>

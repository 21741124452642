<template>
    <InputField
        ref="input"
        v-model="phoneNumber"
        class="phone-input-field"
        type="tel"
        :name="name"
        :label="label || $designSystem.i18n.messages['phone.input.label']"
        :invalid="isInvalid"
        :maxlength="30"
        :submitted="submitted"
        :required="required"
        :readonly="readonly"
        :autocomplete="autocomplete"
        @blur="updatePhoneNumber"
    >
        <template #error>
            <slot name="error" />
        </template>

        <template #help>
            <slot name="help" />
        </template>
    </InputField>
</template>

<script>
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';

import { InputField } from '../InputField';

export default {
    name: 'DsPhoneInput',

    compatConfig: { MODE: 3 },

    components: {
        InputField,
    },

    props: {
        /**
         * Label to render above the phone field when active
         */
        label: String,

        /**
         * HTML input element name
         */
        name: {
            type: String,
            default: 'phone',
        },

        /**
         * Country to format the phone input with
         */
        country: {
            type: String,
            default: 'US',
        },

        /**
         * Controls visualization of rendering form validation error states
         */
        submitted: Boolean,

        /**
         * Required field for validation purposes
         */
        required: Boolean,

        /**
         * The value of the phone number
         */
        modelValue: [String, Number],

        /**
         * Forces invalid form field
         */
        invalid: Boolean,

        /**
         * Prevent user from typing in the input
         */
        readonly: Boolean,

        /**
         * Control whether you allow users to have the ability to autofill/autocomplete this input from things like Google
         */
        autocomplete: {
            type: [Boolean, String],
            default: false,
        },
    },

    emits: [
        'blur',
        'update:modelValue',
    ],

    data() {
        return {
            phoneNumber: this.modelValue,
            valid: true,
        };
    },

    computed: {
        isInvalid() {
            return !this.valid || this.invalid;
        },
    },

    watch: {
        phoneNumber(phoneNumber) {
            if (phoneNumber !== this.modelValue) {
                this.$emit('update:modelValue', this.phoneNumber ? phoneNumber : '');
            }
            this.checkIsValid();
        },

        modelValue(modelValue) {
            if (!modelValue) {
                this.phoneNumber = '';
            }

            if (modelValue !== this.phoneNumber) {
                this.phoneNumber = modelValue;
            }
        },
    },

    mounted() {
        this.load();
    },

    beforeUnmount() {
        this.phoneNumber = null;
    },

    methods: {
        updatePhoneNumber() {
            this.phoneNumber = new AsYouType(this.country).input(this.phoneNumber);
            this.$emit('blur');
            this.checkIsValid();
        },

        load() {
            let number = this.modelValue;

            if (this.modelValue) {
                const phoneNumber = parsePhoneNumber(this.modelValue, this.country);

                if (this.country === phoneNumber?.country) {
                    number = phoneNumber.formatNational();
                }
            }

            if (number) {
                this.phoneNumber = new AsYouType(this.country).input(number);
            }

            this.checkIsValid();
        },

        checkIsValid() {
            if (!this.phoneNumber) {
                this.valid = !this.required;

                return;
            }

            this.valid = true;
        },
    },
};
</script>

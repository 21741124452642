<template>
    <div class="editable" :class="{ active: isEditing }">
        <slot name="dimensions" />

        <section
            v-show="!isEditing"
            class="not-editing"
            :class="[iconEdit ? 'icon-edit' : 'not-editing', { 'placeholder-edit': iconEdit && hasPlaceholder }]"
            @click.stop="tryEdit"
        >
            <div ref="notEditingContainer" class="not-editing-container">
                <slot name="not-editing" />
            </div>

            <span
                v-if="showEditIcon"
                ref="iconContainer"
                class="edit-icon"
                :class="{ 'hover-icon': !alwaysShowIcon, 'icon-color': iconEdit }"
                @click.prevent.stop="tryEditIcon"
            >
                <Icon class="icon" name="edit" />
            </span>
        </section>

        <section v-show="isEditing" class="editing-container">
            <slot name="editing" />

            <span v-if="showEditIcon" class="edit-icon" :class="{ 'hover-icon': !alwaysShowIcon }">
                <Icon class="icon" name="edit" />
            </span>
        </section>
    </div>
</template>

<script>
import { Icon } from '../Icon';
import dom from '../../mixins/dom';

export default {
    name: 'DsEditable',

    compatConfig: { MODE: 3 },

    components: {
        Icon,
    },

    mixins: [dom],

    props: {
        isEditing: Boolean,
        disabled: Boolean,
        hideIcon: Boolean,
        iconEdit: Boolean,
        alwaysShowIcon: Boolean,
        hasPlaceholder: Boolean,
        text: [String, Number],
    },

    emits: [
        'cancel',
        'edit',
        'save',
        'try-edit',
    ],

    computed: {
        showEditIcon() {
            return !this.disabled && !this.hideIcon;
        },
    },

    watch: {
        isEditing(value) {
            if (value) {
                this.addCloseListeners();
            } else {
                this.removeCloseListeners();
            }
        },
    },

    mounted() {
        this.addTouchEvents();
    },

    beforeUnmount() {
        this.removeCloseListeners();
        this.removeTouchEvents();
    },

    methods: {
        addCloseListeners() {
            if (this.dom_isTouchDevice) {
                window.addEventListener('touchend', this.handleOutsideClick, true);
            }

            window.addEventListener('click', this.handleOutsideClick, true);
        },

        removeCloseListeners() {
            window.removeEventListener('touchend', this.handleOutsideClick, true);
            window.removeEventListener('click', this.handleOutsideClick, true);
        },

        handleOutsideClick(e) {
            if (!this.dom_clickedTarget(e, this.$el)) {
                this.save();
            }
        },

        cancel() {
            this.$emit('cancel');
        },

        edit() {
            this.$emit('edit');
        },

        tryEditIcon() {
            this.edit();
        },

        tryEdit() {
            this.$emit('try-edit');
        },

        save() {
            this.$emit('save');
        },

        addTouchEvents() {
            const { notEditingContainer, iconContainer } = this.$refs;

            if (notEditingContainer) {
                notEditingContainer.addEventListener('touchstart', this.editTouch);
            }

            if (iconContainer) {
                iconContainer.addEventListener('touchstart', this.editIconTouch);
            }
        },

        removeTouchEvents() {
            const { notEditingContainer, iconContainer } = this.$refs;

            if (notEditingContainer) {
                notEditingContainer.removeEventListener('touchstart', this.editTouch);
            }

            if (iconContainer) {
                iconContainer.removeEventListener('touchstart', this.editIconTouch);
            }
        },

        editTouch(event) {
            event.stopPropagation();
            this.tryEdit();
        },

        editIconTouch(event) {
            event.preventDefault();
            event.stopPropagation();
            this.edit();
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    @mixin changeIconColor {
        @include transition(opacity);

        --icon-size: #{px-to-rem(18px)};
        --icon-color: #{$color-blue};
    }

    .editable {
        --icon-color: #{$color-ink-600};
        --icon-size: #{px-to-rem(18px)};

        display: inline-block;
        z-index: var(--editable-not-editing-z-index);

        &:not(.disabled) {
            max-width: var(--editable-max-width, 100%);
            min-width: var(--editable-min-width, auto);
            cursor: pointer;
        }

        &.active {
            z-index: var(--editable-editing-z-index);
        }

        section {
            display: flex;
            align-items: center;
        }

        &:hover {
            .edit-icon {
                &.hover-icon {
                    opacity: 1;
                }
            }
        }
    }

    .not-editing-container {
        max-width: 100%;
    }

    .placeholder-edit,
    .not-editing {
        &:hover {
            .edit-icon {
                @include changeIconColor;
            }
        }
    }

    .icon-color {
        &:hover {
            @include changeIconColor;
        }
    }

    .edit-icon {
        @include prevent-select;
        @include margin-start(var(--editable-icon-margin, #{$spacing-200}));

        padding: var(--icon-padding, #{$spacing-050} #{$spacing-075});

        &.hover-icon {
            @include transition(opacity);

            --icon-size: #{px-to-rem(18px)};

            opacity: 0;
        }
    }

    .editing-container {
        width: 100%;
    }
</style>

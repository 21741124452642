<template>
    <section class="ds-card-footer">
        <slot />
    </section>
</template>

<script>
export default {
    name: 'DsCardFooter',

    compatConfig: { MODE: 3 },
};
</script>

<style lang="scss" scoped>
@import "../../styles/common";

.ds-card-footer {
    padding: $spacing-200;
    border-radius: 0 0 $border-radius $border-radius;
    border-top: solid px-to-rem(1) $color-ink-200;
}
</style>

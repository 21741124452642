<template>
    <component
        :is="rootElement"
        class="ds-card"
        :class="[`elevation-${elevation}`, { clickable }]"
    >
        <slot />
    </component>
</template>

<script>
export default {
    name: 'DsCard',

    compatConfig: { MODE: 3 },

    props: {
        /**
         * The base elevation level for the card (this affects the hover, focus, and active states as well).
        */
        elevation: {
            type: String,
            default: 'z1',
            options: ['z0', 'z1', 'z2', 'z3', 'z4', 'z6', 'z8', 'z12'],
        },

        /**
         * The element type the card will render for its root element.
        */
        as: {
            type: String,
            default: '',
        },
    },

    computed: {
        clickable() {
            return !!this.$attrs.onClick || this.as === 'a';
        },

        rootElement() {
            if (this.as) {
                return this.as;
            }

            if (this.$attrs.onClick) {
                return 'button';
            }

            return 'div';
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../styles/common";

.ds-card {
    background-color: $color-paper;
    border-radius: $border-radius;
    box-shadow: var(--box-shadow);
}

.clickable {
    @include transition(box-shadow);
    user-select: none;

    /* button style overrides, can be removed once global button styles are removed */
    display: block;
    width: 100%;
    color: currentColor;
    font-size: inherit;
    border: none;
    font-weight: inherit;
    text-align: inherit;
    line-height: inherit;
    padding: 0;
    text-decoration: none;

    &:hover {
        box-shadow: var(--box-shadow-hover);
    }

    &:focus {
        box-shadow: var(--box-shadow-focus);
    }

    &:active {
        box-shadow: var(--box-shadow-active);
    }
}

.elevation-z0 {
    --box-shadow: #{$elevation-z0};
    --box-shadow-hover: #{$elevation-z1};
    --box-shadow-focus: #{$elevation-z1};
    --box-shadow-active: #{$elevation-z4};
}

.elevation-z1 {
    --box-shadow: #{$elevation-z1};
    --box-shadow-hover: #{$elevation-z3};
    --box-shadow-focus: #{$elevation-z3};
    --box-shadow-active: #{$elevation-z6};
}

.elevation-z2 {
    --box-shadow: #{$elevation-z2};
    --box-shadow-hover: #{$elevation-z4};
    --box-shadow-focus: #{$elevation-z4};
    --box-shadow-active: #{$elevation-z8};
}

.elevation-z3 {
    --box-shadow: #{$elevation-z3};
    --box-shadow-hover: #{$elevation-z6};
    --box-shadow-focus: #{$elevation-z6};
    --box-shadow-active: #{$elevation-z12};
}

.elevation-z4 {
    --box-shadow: #{$elevation-z4};
    --box-shadow-hover: #{$elevation-z8};
    --box-shadow-focus: #{$elevation-z8};
    --box-shadow-active: #{$elevation-z12};
}

.elevation-z6 {
    --box-shadow: #{$elevation-z6};
    --box-shadow-hover: #{$elevation-z8};
    --box-shadow-focus: #{$elevation-z8};
    --box-shadow-active: #{$elevation-z16};
}

.elevation-z8 {
    --box-shadow: #{$elevation-z8};
    --box-shadow-hover: #{$elevation-z12};
    --box-shadow-focus: #{$elevation-z12};
    --box-shadow-active: #{$elevation-z16};
}

.elevation-z12 {
    --box-shadow: #{$elevation-z12};
    --box-shadow-hover: #{$elevation-z16};
    --box-shadow-focus: #{$elevation-z16};
    --box-shadow-active: #{$elevation-z24};
}
</style>

<template>
    <DsButton
        variant="icon"
        :as="as"
        :type="type"
        :dense="dense"
        :disabled="disabled"
        :aria-label="ariaLabel"
        class="icon-only"
        :class="{ 'ds-button--floating': floating, floating }"
    >
        <slot>
            <Icon :name="name" />
        </slot>
    </DsButton>
</template>

<script>
import DsButton from './Button.vue';
import { Icon } from '../Icon';

export default {
    name: 'DsIconButton',

    compatConfig: { MODE: 3 },

    components: { DsButton, Icon },

    props: {
        /**
         * Icon name to render
         */
        name: String,

        /**
         * Name of the element type to render the Button as.  Defaults to a button element, but can be used to render an anchor for links that look like buttons.
         */
        as: {
            type: String,
            default: 'button',
        },

        /**
         * The type attribute used by the rendered button, i.e. button, submit, reset.  Defaults to button.  This will not be used if the `as` prop is overriden.
         */
        type: String,

        /**
         * Style as floating
         */
        floating: Boolean,

        /**
         * Label to render for screen readers
         * https://dequeuniversity.com/rules/axe/3.5/button-name?application=axeAPI
         */
        ariaLabel: String,

        /**
         * Styles as a dense variant.
         */
        dense: Boolean,

        /**
         * Disables the button.
         */
        disabled: Boolean,
    },
};
</script>

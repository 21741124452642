import mitt from 'mitt';

export const createConfirmDialogGlobal = (app) => {
    const emitter = mitt();

    const confirm = (options = {}) => {
        const cleanupOnClose = () => {
            emitter.off('cancel');
            emitter.off('confirm');
        };

        emitter.emit('open', options);

        const { onConfirm, onCancel } = options;

        // use callbacks
        if (onConfirm) {
            emitter.on('cancel', () => {
                if (onCancel) {
                    onCancel();
                }
                cleanupOnClose();
            });

            emitter.on('confirm', () => {
                onConfirm();
                cleanupOnClose();
            });

            // for linting, return promise that will never resolve or reject
            return new Promise(() => {});
        }

        // use promises
        return new Promise((resolve, reject) => {
            emitter.on('cancel', () => {
                reject();
                cleanupOnClose();
            });

            emitter.on('confirm', () => {
                resolve();
                cleanupOnClose();
            });
        });
    };

    app.config.globalProperties.$confirmDialogEmitter = emitter;
    app.config.globalProperties.$confirm = confirm;
};

import ToastContainer from './ToastContainer.vue';
import mitt from 'mitt';

export const createToastGlobal = (app) => {
    const emitter = mitt();

    const toast = (options) => {
        emitter.emit('open', options);
    };

    app.config.globalProperties.$toastEmitter = emitter;
    app.config.globalProperties.$toast = toast;
};

<template>
    <div
        ref="overflowContainer"
        :class="['overflow-container', { 'has-overflow': hasOverflow && !dom_isTouchDevice() }]"
    >
        <div class="move move-left" @click="moveLeft">
            <Icon name="chevron-left" />
        </div>

        <div class="move move-right" @click="moveRight">
            <Icon name="chevron-right" />
        </div>

        <ul ref="tablist" class="tablist tabs">
            <slot />
        </ul>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { Icon } from '../Icon';
import dom from '../../mixins/dom';

import '../../assets/icons/chevron-left.svg';
import '../../assets/icons/chevron-right.svg';

export default {
    name: 'DsTabHeader',

    compatConfig: { MODE: 3 },

    components: {
        Icon,
    },

    mixins: [dom],

    inject: {
        emitter: {
            default() {
                return {
                    on() {},
                    off() {},
                };
            },
        },
    },

    props: {
        /**
         * Debounce delay for window resize hook
         */
        debounceDelay: {
            type: Number,
            default: 300,
            dimension: 'milliseconds',
        },
    },

    data() {
        return {
            hasOverflow: false,
        };
    },

    created() {
        if (this.debounceDelay) {
            this.checkOverflow = debounce(this.checkOverflow, this.debounceDelay);
        }
    },

    mounted() {
        this.checkOverflow();

        window.addEventListener('resize', this.checkOverflow);

        this.emitter.on('TAB_HEADER_TABS_UPDATED', this.checkOverflow);
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.checkOverflow);

        this.emitter.off('TAB_HEADER_TABS_UPDATED', this.checkOverflow);
    },

    methods: {
        checkOverflow() {
            this.$nextTick(() => {
                if (this.$refs.tablist) {
                    this.hasOverflow = this.$refs.tablist.clientWidth < this.$refs.tablist.scrollWidth;
                }
            });
        },

        moveLeft() {
            this.$refs.tablist.scrollLeft += -200;
        },

        moveRight() {
            this.$refs.tablist.scrollLeft += 200;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    $tab-bottom-border-size: px-to-rem(2px);
    $scrollbar-height: px-to-rem(12px);

    .tablist {
        width: 100%;
        overflow-y: hidden;
        overflow-x: auto;
    }

    .overflow-container {
        @include tabs;
        display: flex;
        flex: 1;
        overflow: hidden;
        position: relative;

        &.has-overflow {
            padding-left: $spacing-400;
            padding-right: $spacing-400;

            .tab {
                margin-bottom: #{$scrollbar-height};
            }
        }

        &:not(.has-overflow) {
            .move {
                display: none;
            }
        }
    }

    .move {
        --icon-color: #{$tab-icon-color};

        position: absolute;
        top: 0;
        height: 100%;
        width: 2rem;
        padding-bottom: #{$tab-bottom-border-size + $scrollbar-height};
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .move-left {
        left: 0;
    }

    .move-right {
        right: 0;
    }
</style>

<template>
    <div class="dropdown" @input="schedulePopper">
        <BodyClickHandler :enabled="isOpenInternal" :target="target" @click="onBodyClick" />

        <div
            ref="reference"
            class="dropdown-reference"
            @click="onReferenceClick"
        >
            <!-- @slot Default slot holding trigger content -->
            <slot />
        </div>

        <div
            ref="menu"
            class="dropdown-menu"
            :class="[{
                icons,
                touch,
                dense,
                open: isOpenInternal,
                'dropdown-block': dropdownBlock,
                'disable-scroll': disableScroll
            }]"
            @click="onMenuClick"
        >
            <div class="menu">
                <!-- @slot Menu content -->
                <slot name="menu" />
            </div>

            <div v-if="$slots.footer" class="footer">
                <!-- @slot Footer content -->
                <slot name="footer" :toggleable_close="close" />
            </div>
        </div>

        <TouchOverlay v-if="touch && isOpenInternal" @click.prevent.stop="onBodyClick" />
    </div>
</template>

<script>
import { createPopper } from '@popperjs/core';

import dom from '../../mixins/dom';

import POSITIONS from './dropdownPositions';
import { BodyClickHandler } from '../Dom';
import TouchOverlay from './TouchOverlay.vue';

export default {
    name: 'DsDropdown',

    compatConfig: { MODE: 3 },

    components: { BodyClickHandler, TouchOverlay },

    mixins: [dom],

    props: {
        /**
         * Controls the open state of the dropdown.  This makes the dropdown controlled and will not work with uncontrolled options.
         */
        isOpen: {
            type: Boolean,
            default: null,

        },

        /**
         * Enables dense menu items
         */
        dense: Boolean,

        /**
         * Disable scroll on the dropdown list options
         */
        disableScroll: Boolean,

        /**
         * Sets the dropdown width to the available container width
         */
        dropdownBlock: Boolean,

        /**
         * Will the list items have icons, if so needs more padding
         */
        icons: Boolean,

        /**
         * Pin the bottom of the menu to the bottom of the viewport for really long menus
         */
        pinBottom: Boolean,

        /**
         * Position the dropdown either left aligned or right aligned below its reference
         */
        position: {
            type: String,
            default: POSITIONS.bottomStart,
            options: [
                POSITIONS.rightStart,
                POSITIONS.rightEnd,
                POSITIONS.leftStart,
                POSITIONS.leftEnd,
                POSITIONS.bottomStart,
                POSITIONS.bottomEnd,
                POSITIONS.topStart,
                POSITIONS.topEnd,
            ],
        },

        /**
         * Keep inline instead of fixing to the bottom of the screen on small screens
         */
        smallInline: Boolean,
    },

    emits: [
        'change',
        'close',
    ],

    data() {
        return {
            isOpenInternal: false,
            popperJs: null,
            target: null,
        };
    },

    computed: {
        isControlled() {
            return this.isOpen === true || this.isOpen === false;
        },

        translatedPosition() {
            if (this.dom_isRtl()) {
                if (this.position === POSITIONS.bottomStart) {
                    return POSITIONS.bottomEnd;
                }

                if (this.position === POSITIONS.topStart) {
                    return POSITIONS.topEnd;
                }

                return POSITIONS.bottomStart;
            }

            return this.position;
        },

        touch() {
            return this.dom_isTouchDevice() && this.dom_isSmall() && !this.smallInline;
        },
    },

    watch: {
        isOpen: {
            handler(isOpen) {
                if (isOpen) {
                    this.open();
                } else {
                    this.close();
                }
            },
            immediate: true,
        },
    },

    mounted() {
        this.target = this.$el;
        this.setupLayout();
    },

    beforeUnmount() {
        if (this.popperJs && typeof this.popperJs.destroy === 'function') {
            this.popperJs.destroy();
        }

        this.popperJs = null;
    },

    methods: {
        close() {
            if (this.isOpenInternal) {
                this.isOpenInternal = false;

                this.$emit('change', false);
                this.$emit('close');

                this.schedulePopper();

                this.checkPinOnDelay();
            }
        },

        open() {
            this.isOpenInternal = true;

            this.$emit('change', true);

            this.schedulePopper();

            this.checkPinOnDelay();
        },

        toggle() {
            if (!this.isOpenInternal) {
                this.open();
            } else {
                this.close();
            }
        },

        onMenuClick() {
            if (this.isControlled) {
                return;
            }

            this.close();
        },

        onReferenceClick() {
            if (this.isControlled) {
                return;
            }

            this.toggle();
        },

        onBodyClick() {
            const { isControlled, isOpen } = this;

            if (isControlled) {
                if (isOpen) {
                    this.$emit('close');
                }

                return;
            }

            this.close();
        },

        setupLayout() {
            const {
                touch,
                translatedPosition,
            } = this;

            if (touch) {
                return;
            }

            this.popperJs = createPopper(this.$refs.reference, this.$refs.menu, {
                placement: translatedPosition,
                modifiers: [
                    {
                        name: 'eventListeners',
                        options: {
                            scroll: true,
                            resize: true,
                        },
                    },
                ],
            });
        },

        schedulePopper() {
            if (this.popperJs) {
                this.popperJs.update();
            }
        },

        checkPinOnDelay() {
            if (!this.pinBottom) {
                return;
            }

            // Need slight delay for popper to draw
            setTimeout(this.checkPin, 10);
        },

        checkPin() {
            this.$nextTick(() => {
                if (this.isOpenInternal) {
                    const distanceToWindowBottom = this.dom_distanceToBottomEdge(this.$refs.menu, document, true);
                    const remSize = window.parseFloat(getComputedStyle(document.documentElement).fontSize);
                    const pxSize = `${distanceToWindowBottom - 2 * remSize}px`;

                    this.$refs.menu.style.maxHeight = pxSize;
                } else {
                    this.$refs.menu.style.maxHeight = '100px';
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .dropdown {
        position: relative;
    }

    .footer {
        border-top: var(--dropdown-footer-border-color, #{$color-ink-200}) 1px solid;
        padding: $spacing-150 $spacing-200;
        margin: 0;
        display: flex;
        align-items: center;
        box-shadow: $elevation-z4;

        div {
            width: 100%;
        }
    }

    @include keyframes(mobilePopoverIn) {
        from {
            transform: translateY(100%);
        }

        to {
            transform: translateY(0);
        }
    }

    .dropdown-menu {
        background-color: $color-paper;
        box-shadow: $dropdown-shadow;
        border-radius: $border-radius;
        min-width: var(--dropdown-minwidth, #{$dropdown-minwidth});
        max-width: var(--dropdown-maxwidth, initial);
        max-height: var(--dropdown-height, #{$dropdown-maxheight});
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        z-index: $zindex-dropdown;
        position: absolute;
        display: none;

        &.open {
            display: flex;
            flex-direction: column;

            .menu {
                overflow-y: auto;
            }
        }

        &.disable-scroll {
            --dropdown-height: auto;
            overflow-y: visible;
        }

        :deep(.dropdown-menu-list) {
            @include dropdown-list;
        }

        :deep(.dropdown-menu-item) {
            @include dropdown-list-item;
        }

        &.dense {
            :deep(.dropdown-menu-item) {
                padding: $spacing-100 $spacing-200;
                font-size: $font-size-sm;
            }
        }

        &.icons {
            --icon-color: #{$color-ink-800};
            --icon-size: #{$icon-size};

            :deep(.dropdown-menu-item) {
                align-items: center;
                display: flex;
            }

            :deep(.icon) {
                @include margin-end($spacing-200);
            }
        }

        &[data-popper-placement^="top"] {
            top: -100%;
        }
    }

    .dropdown-block {
        width: 100%;
    }

    .dropdown-menu.touch {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        display: none;
        flex-direction: column;
        border-radius: 0;
        max-width: 100%;

        .menu {
            flex: 1;
        }

        &.open {
            @include animation('mobilePopoverIn');
            display: flex;
        }
    }
</style>

<template>
    <div class="popover-container" :class="[{ block }]">
        <BodyClickHandler :enabled="isOpenInternal" :target="$el" @click="onBodyClick" />

        <div
            ref="reference"
            class="popover-reference"
            @click="onReferenceClick"
        >
            <slot name="reference" />
        </div>

        <section
            ref="popover"
            class="popover"
            :class="[{ 'open': isOpenInternal }]"
        >
            <div
                v-if="!hideArrow"
                data-popper-arrow
                class="arrow"
            />
            <slot />
        </section>
    </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import { BodyClickHandler } from '../Dom';

export default {
    name: 'DsPopover',

    compatConfig: { MODE: 3 },

    components: { BodyClickHandler },

    props: {
        /**
         * Controls the open state of the popover.  This makes the popover controlled and will not work with uncontrolled options.
         */
        isOpen: {
            type: Boolean,
            default: null,
        },

        /**
         * Position of the popover, top bottom left right top-start top-end right-start right-end bottom-start bottom-end left-start left-end.
         */
        position: {
            type: String,
            default: 'bottom',
            options: ['top', 'right', 'bottom', 'left', 'top-start', 'top-end', 'right-start', 'right-end', 'bottom-start', 'bottom-end', 'left-start', 'left-end'],
        },

        /**
         * Make popover reference a block instead of default inline-block
         */
        block: Boolean,

        /**
         * Stick popper to viewport top when scrolling past view
         */
        sticky: Boolean,

        /**
         * Fix popover to window - great for getting around overflow: hidden containers
         */
        positionFixed: Boolean,

        /**
         * Whether or not to hide the arrow pointing to the reference
         */
        hideArrow: Boolean,
    },

    emits: [
        'close',
        'popover-active',
    ],

    data() {
        return {
            isOpenInternal: false,
            popperJs: null,
            target: null,
        };
    },

    computed: {
        isControlled() {
            return this.isOpen === true || this.isOpen === false;
        },
    },

    watch: {
        isOpen: {
            handler(isOpen) {
                if (isOpen) {
                    this.open();
                } else {
                    this.close();
                }
            },
            immediate: true,
        },

        position(value) {
            this.popperJs.options.placement = value;
        },
    },

    mounted() {
        const {
            position,
            positionFixed,
            sticky,
        } = this;

        this.target = this.$el;

        this.popperJs = createPopper(this.$refs.reference, this.$refs.popover, {
            placement: position,
            strategy: positionFixed ? 'fixed' : 'absolute',
            modifiers: [
                {
                    name: 'eventListeners',
                    options: {
                        scroll: true,
                        resize: true,
                    },
                },
                {
                    name: 'preventOverflow',
                    options: {
                        tether: !sticky,
                        boundary: sticky ? 'viewport' : 'scrollParent',
                    },
                },
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
            ],
        });
    },

    beforeUnmount() {
        if (this.popperJs && typeof this.popperJs.destroy === 'function') {
            this.popperJs.destroy();
        }

        this.popperJs = null;
    },

    methods: {
        open() {
            this.isOpenInternal = true;

            this.$emit('popover-active', true);

            if (this.popperJs) {
                this.popperJs.update();
            }
        },

        close() {
            if (this.isOpenInternal) {
                this.isOpenInternal = false;

                this.$emit('close');
                this.$emit('popover-active', false);

                if (this.popperJs) {
                    this.popperJs.update();
                }
            }
        },

        toggle() {
            if (this.isOpenInternal) {
                this.close();
            } else {
                this.open();
            }
        },

        onReferenceClick() {
            if (this.isControlled) {
                return;
            }

            this.toggle();
        },

        onBodyClick() {
            const { isControlled, isOpenInternal } = this;

            if (isControlled && isOpenInternal) {
                this.$emit('close');

                return;
            }

            this.close();
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    $background-color: var(--popover-background-color, #{$popover-background-color});

    .popover-container {
        display: inline-block;
        position: relative;

        &.block {
            display: block;

            .popover-reference {
                display: block;
            }
        }
    }

    .popover-reference {
        display: inline-block;
    }

    .popover {
        @include popover($background-color);

        box-shadow: $popover-shadow;
        min-width: var(--popover-min-width, #{$dropdown-minwidth});
        width: var(--popover-width, #{$dropdown-minwidth});
    }

    :slotted(.popover-content) {
        padding: $spacing-200;
    }
</style>

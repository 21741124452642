<template>
    <li
        class="menu-item-root"
        :data-qa="label"
        @click.prevent.stop="$emit('selectOption', option)"
        @mouseover="$emit('mouseover', index)"
    >
        <slot
            v-bind="$props"
            :highlight="highlight"
            :is-selected="isSelected"
        >
            <div
                :class="['menu-item', 'prevent-select', {
                    'label': isBreadcrumb || option.isLabel,
                    'option-text-disabled': option[disabledOptionProp],
                    'has-icon': showIconPrefix,
                    breadcrumb: isBreadcrumb,
                    selected: isSelected,
                    separator: option.separator,
                    highlight,
                    dense
                }]"
            >
                <div
                    v-if="option.groupLabel"
                    class="section-header prevent-select"
                    :data-qa="option.groupLabel"
                >
                    {{ option.groupLabel }}
                </div>

                <div
                    v-else
                    :class="['option-row', 'prevent-select', { 'has-help-text': helpText }]"
                    :data-qa="label"
                >
                    <Icon v-if="showIconPrefix" class="category-icon" :name="icon" />

                    <div :class="labelClass">
                        <TextHighlighter
                            v-if="label"
                            :highlight="filter"
                            :content="label"
                            :class="{'option-text-disabled-label': option[disabledOptionProp]}"
                        />

                        <MenuItemSubtext v-if="showHelpText">
                            <TextHighlighter
                                :highlight="filter"
                                :content="option[helpTextProp]"
                            />
                        </MenuItemSubtext>
                    </div>

                    <div v-if="option[showOptionModifierProp]">
                        <slot name="optionModifier" />
                    </div>

                    <Icon
                        v-if="showIconSuffix"
                        class="chevron category-arrow"
                        name="chevron-right"
                    />
                </div>
            </div>
        </slot>
    </li>
</template>

<script>
import { Icon } from '../Icon';
import { TextHighlighter } from '../Text';
import { MenuItemSubtext } from '../Menu';

import '../../assets/icons/chevron-left.svg';
import '../../assets/icons/chevron-right.svg';

export default {
    name: 'DsMultiSelectMenuItem',

    compatConfig: { MODE: 3 },

    components: {
        Icon,
        TextHighlighter,
        MenuItemSubtext,
    },

    props: {
        /**
         * Enables dense menu items
         */
        dense: Boolean,

        disabledOptionProp: {
            type: String,
            default: 'disabledOption',
        },

        filter: {
            type: String,
            default: '',
        },

        helpText: Boolean,

        helpTextProp: {
            type: String,
            default: 'helpText',
        },

        index: Number,

        isBreadcrumb: Boolean,

        isCategory: Boolean,

        labelProp: {
            type: String,
            default: 'label',
        },

        option: Object,

        pointer: Number,

        selected: [Object, Array],

        showOptionModifierProp: String,

        valueProp: {
            type: String,
            default: 'value',
        },
    },

    emits: [
        'mouseover',
        'selectOption',
    ],

    computed: {
        highlight() {
            const highlighted = !this.isBreadcrumb
                && !this.option.isLabel
                && (this.isCategory || !this.option[this.disabledOptionProp])
                && this.index === this.pointer;

            return highlighted ? true : undefined;
        },

        icon() {
            return this.isBreadcrumb ? 'chevron-left' : this.option.icon;
        },

        isSelected() {
            const selected = !this.isCategory
                && !this.option.isLabel
                && this.selected
                && this.selected[this.valueProp] === this.option[this.valueProp];
            return selected ? true : undefined;
        },

        label() {
            return this.isCategory ? this.option.label : this.option[this.labelProp];
        },

        labelClass() {
            return this.isCategory && !this.isBreadcrumb ? 'category-label' : 'option-text';
        },

        showHelpText() {
            return this.helpText && Boolean(this.option[this.helpTextProp]);
        },

        showIconPrefix() {
            return this.isBreadcrumb || Boolean(this.option.icon);
        },

        showIconSuffix() {
            return !this.isBreadcrumb && this.option.hasChildrenOptions;
        },

    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .category-icon {
        @include margin-end($spacing-200);
    }

    .prevent-select {
        @include prevent-select;
    }

    .category-label {
        @include prevent-select;
        flex: 1;
    }

    .category-arrow {
        --icon-color: #{$color-ink-600};
    }

    .selected {
        --text-highlighter-matching-color: #{$color-paper};
    }

    .option-row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .option-text {
        display: flex;
        flex-direction: column;
    }

    .option-text-disabled-label {
        color: $color-text-disabled;
    }

    .option-text-disabled {
        cursor: default;

        &:hover {
            background-color: $color-paper;
        }
    }

    .multiselect-menu li.menu-item-root {
        padding: 0;

        &:hover {
            background-color: $color-paper;
        }
    }

    :slotted(.menu-item) {
        @include dropdown-list-item;
        width: 100%;
        --icon-size: #{$font-size-lg};
        transform: translateZ(0);
        word-break: var(--menu-item-word-break, normal);

        &.label {
            cursor: default;
            padding: $spacing-100 $spacing-200;
            padding-bottom: $spacing-150;

            &:hover {
                background-color: $color-paper;
            }

            &:not(:first-child) {
                margin-top: $spacing-200;
            }
        }

        &.separator {
            border-bottom: 1px solid $color-ink-200;
        }

        &.dense  {
            padding: $spacing-100 $spacing-200;
            font-size: $font-size-sm;
            --icon-size: #{$font-size-md};
        }

        &.breadcrumb {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $color-ink-200;
            cursor: pointer;

            .option-text {
                font-weight: $font-weight-semibold;
                @include text-align-start;
                font-size: $font-size-sm;
                display: inline-flex;
                margin-top: $spacing-050;
            }

            .category-icon {
                @include margin-end($spacing-100);

                --icon-size: #{px-to-rem(24px)};
                --icon-color: #{$color-ink};

                margin-top: $spacing-050;
            }
        }
    }

    .section-header {
        color: $color-text-subtle;
        font-size: $font-size-sm;
    }
</style>

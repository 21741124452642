export default {
    methods: {
        dateTime_toEncodedTimeString(date) {
            if (!date) {
                return null;
            }

            let hour = date.getHours().toString();

            hour = hour.length === 1 ? `0${hour}` : hour;

            let minutes = date.getMinutes().toString();

            minutes = minutes.length === 1 ? `0${minutes}` : minutes;

            return hour + minutes;
        },

        dateTime_fromEncodedTimeString(timeString) {
            if (!timeString || typeof timeString !== 'string') {
                return null;
            }

            const hours = timeString.substr(0, 2);
            const minutes = timeString.substr(2, 4);

            const now = new Date();

            now.setHours(hours);
            now.setMinutes(minutes);

            return now;
        },
    },
};

<script>
const handleMousedown = () => {
    document.body.classList.add('using-mouse');
};
const handleKeydown = () => {
    document.body.classList.remove('using-mouse');
};

export default {
    name: 'DsFocusOnKeyboardOnly',

    compatConfig: { MODE: 3 },

    mounted() {
        document.body.addEventListener('mousedown', handleMousedown);
        document.body.addEventListener('keydown', handleKeydown);
    },

    beforeUnmount() {
        document.body.removeEventListener('mousedown', handleMousedown);
        document.body.removeEventListener('keydown', handleKeydown);
    },

    render() {
        return null;
    },
};
</script>

<style>
    body.using-mouse :focus {
        outline: none;
    }
</style>

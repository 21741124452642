<template>
    <div class="collapsible">
        <header v-if="hasHeader" @click="toggle">
            <!-- @slot Header content containing toggle trigger -->
            <slot name="header" />
        </header>

        <div ref="content" class="collapsible-content" :class="{ active: isOpenInternal }">
            <div ref="inner" class="collapsible-inner">
                <!-- @slot Collapsible content -->
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DsCollapsible',

    compatConfig: { MODE: 3 },

    props: {
        /**
         * Controls the open state of the collapsible.  This makes the collapsible controlled and will not work with uncontrolled options.
         */
        isOpen: {
            type: Boolean,
            default: null,
        },
    },

    emits: ['toggle'],

    data() {
        return {
            slotContentObserver: Object,
            isOpenInternal: false,
        };
    },

    computed: {
        isControlled() {
            return this.isOpen === true || this.isOpen === false;
        },

        hasHeader() {
            return this.$slots.header;
        },
    },

    watch: {
        isOpenInternal(value) {
            this.setContentHeight();

            if (!this.isControlled) {
            /**
             * Triggers toggle of open
             */
                this.$emit('toggle', value);
            }
        },

        isOpen: {
            handler(isOpen) {
                if (this.isControlled) {
                    this.isOpenInternal = isOpen;
                }
            },
            immediate: true,
        },
    },

    mounted() {
        this.slotContentObserver = new MutationObserver(this.setContentHeight);

        this.slotContentObserver.observe(this.$refs.inner, {
            attributes: true,
            subtree: true,
            childList: true,
        });

        this.setContentHeight();
    },

    beforeUnmount() {
        this.slotContentObserver.disconnect();
    },

    methods: {
        setContentHeight() {
            if (this.$refs.content) {
                this.$refs.content.style.height = this.isOpenInternal
                    ? `${this.$refs.inner.offsetHeight}px`
                    : 0;
            }
        },

        open() {
            this.isOpenInternal = true;
        },

        toggle() {
            if (this.isControlled) {
                this.$emit('toggle');

                return;
            }

            this.isOpenInternal = !this.isOpenInternal;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    @-webkit-keyframes collapsible {
        99.999% { overflow: hidden; }
        100% { overflow: visible; }
    }

    @keyframes collapsible {
        99.999% { overflow: hidden; }
        100% { overflow: visible; }
    }

    .collapsible {
        @include text-align-start;

        header {
            --icon-color: #{$color-ink-600};

            display: flex;
            flex-direction: row;
            align-items: center;
            padding: var(--collapsible-header-padding, 0);
            cursor: pointer;
        }

        .collapsible-content {
            @include transition(height);

            overflow: hidden;
            height: 0;

            &.active {
                animation: collapsible $animation-duration forwards;
                -webkit-animation: collapsible $animation-duration forwards;
            }
        }

        .collapsible-inner {
            padding: var(--collapsible-inner-padding, #{0 $spacing-200 $spacing-200});
        }
    }
</style>

<template>
    <div class="spinner" :style="styles" />
</template>

<script>
export default {
    name: 'DsSpinner',

    compatConfig: { MODE: 3 },

    props: {
        /**
         * Number value to set the width and height of the spinner
         */
        size: Number,

        /**
         * Number value to set the border width of the spinner
         */
        thickness: Number,
    },

    computed: {
        styles() {
            const { size, thickness } = this;

            const styles = {};

            if (size) {
                styles['--spinner-size'] = `${size}px`;
            }

            if (thickness) {
                styles['--spinner-thickness'] = `${thickness}px`;
            }

            return styles;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../styles/common";

$spinner-size: px-to-rem(40px);
$spinner-thickness: px-to-rem(3px);

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    border-radius: 50%;
    display: inline-block;
    animation: spin 1s infinite linear;
    width: var(--spinner-size, #{$spinner-size});
    height: var(--spinner-size, #{$spinner-size});
    border: solid var(--spinner-thickness, #{$spinner-thickness});
    border-color: var(--spinner-color, #{$color-blue});
    border-left-color: transparent;
}
</style>

<script>
import useMediaQuery from './useMediaQuery';

export default {
    name: 'DsMediaQuery',

    compatConfig: { MODE: 3 },

    props: {
        /**
         * Named breakpoint to match on.
         */
        breakpoint: String,

        /**
         * Whether or not to exactly match the breakpoint.  This should be used in conjunction with the `breakpoint` prop.
         */
        exact: Boolean,

        /**
         * Minimum width of the breakpoint.
         */
        width: {
            type: Number,
            default: 0,
        },

        /**
         * Custom media query string.
         */
        query: String,
    },

    setup(props) {
        const isMatch = useMediaQuery({
            breakpoint: props.breakpoint,
            exact: props.exact,
            width: props.width,
            query: props.query,
        });

        return { isMatch };
    },

    render() {
        const { isMatch } = this;

        return this.$slots.default({
            isMatch,
        });
    },
};
</script>

<template>
    <ConfirmDialog
        v-if="isOpen"
        is-open
        :title="title"
        :message="message"
        :confirm-label="confirmLabel"
        :cancel-label="cancelLabel"
        :confirm-only="confirmOnly"
        :destructive="destructive"
        :size="size"
        @cancel="handleCancel"
        @confirm="handleConfirm"
    />
</template>

<script>
import ConfirmDialog from './ConfirmDialog.vue';

export default {
    name: 'DsConfirmDialogContainer',

    compatConfig: { MODE: 3 },

    components: { ConfirmDialog },

    data() {
        return {
            isOpen: false,
            title: undefined,
            message: undefined,
            confirmLabel: undefined,
            cancelLabel: undefined,
            destructive: false,
            confirmOnly: false,
            size: undefined,
        };
    },

    created() {
        this.$confirmDialogEmitter.on('open', this.handleGlobalOpen);
    },

    beforeUnmount() {
        this.$confirmDialogEmitter.off('open', this.handleGlobalOpen);

        if (this.isOpen) {
            this.$confirmDialogEmitter.emit('cancel');
        }
    },

    methods: {
        handleGlobalOpen(options) {
            this.isOpen = true;
            this.title = options.title;
            this.message = options.message;
            this.confirmLabel = options.confirmLabel;
            this.cancelLabel = options.cancelLabel;
            this.destructive = options.destructive;
            this.confirmOnly = options.confirmOnly;
            this.size = options.size;
        },

        handleConfirm() {
            this.$confirmDialogEmitter.emit('confirm');
            this.close();
        },

        handleCancel() {
            this.$confirmDialogEmitter.emit('cancel');
            this.close();
        },

        close() {
            this.isOpen = false;
            this.title = undefined;
            this.message = undefined;
            this.confirmLabel = undefined;
            this.cancelLabel = undefined;
            this.destructive = false;
            this.confirmOnly = false;
            this.size = undefined;
        },
    },
};
</script>

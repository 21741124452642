<template>
    <component
        :is="as"
        v-bind="attributes"
        class="link ds-link"
    >
        <div v-if="hasLeading" class="leading">
            <!-- @slot Leading content such as an Icon. -->
            <slot name="leading">
                <Icon :name="leadingIcon" />
            </slot>
        </div>

        <div v-if="hasLeading || hasTrailing">
            <!-- @slot Content of the link. -->
            <slot />
        </div>

        <slot v-else />

        <div v-if="hasTrailing" class="trailing">
            <!-- @slot Trailing content such as an Icon. -->
            <slot name="trailing">
                <Icon :name="trailingIcon" />
            </slot>
        </div>
    </component>
</template>

<script>
import { Icon } from '../Icon';

export default {
    name: 'DsLink',

    compatConfig: { MODE: 3 },

    components: { Icon },

    props: {
        /**
         * Controls which element to render
         */
        as: {
            type: String,
            default: 'a',
            validator: ((as) => ['a', 'button'].includes(as)),
        },

        /**
         * Opens link in new window with security protections
         */
        external: Boolean,

        /**
         * URL or other valid path to visit when clicked
         */
        href: String,

        /**
         * Name of the icon to render in the leading slot.
         */
        leadingIcon: String,

        /**
         * Includes external link icon by default, or given icon.
         */
        trailingIcon: String,

        /**
         * Defines the button type when component is rendered as button
         */
        type: String,
    },

    computed: {
        attributes() {
            const {
                as,
                external,
                href,
                type,
                $attrs: attrs,
            } = this;

            if (as === 'button') {
                return {
                    ...attrs,
                    type: type === undefined ? 'button' : type || null,
                };
            }

            return {
                ...attrs,
                target: external ? '_blank' : null,
                rel: external ? 'noopener noreferrer' : null,
                href,
            };
        },

        hasLeading() {
            return this.$slots.leading || this.leadingIcon;
        },

        hasTrailing() {
            return this.$slots.trailing || this.trailingIcon;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../styles/common";

.link {
    display: inline-flex;
    color: $color-blue-900;

    &:active {
        color: $color-navy-800;
    }
}

button.link {
    padding: 0;
    background-color: transparent;
    font-weight: $font-weight-regular;
    border: none;

    &:hover {
        text-decoration: underline;
    }
}

.leading,
.trailing {
    --icon-size: 1rem;
    width: var(--icon-size);
    height: var(--icon-size);
}

.leading {
    @include margin-end($spacing-050);
}

.trailing {
    @include margin-start($spacing-050);
}
</style>

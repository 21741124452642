<template>
    <div class="subtext">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'DsMenuItemSubtext',

    compatConfig: { MODE: 3 },
};
</script>

<style lang="scss" scoped>
@import "../../styles/common";

.subtext {
    color: $color-text-subtle;
    font-size: $font-size-xs;
}
</style>

<template>
    <ErrorBanner
        :is-open="isOpen"
        :position="position"
        :message="message"
        :show-icon="showIcon"
        :critical="critical"
        :action-label="actionLabel"
        :close-label="closeLabel"
        @close="handleClose"
        @action="handleAction"
    />
</template>

<script>
import ErrorBanner from './ErrorBanner.vue';

const noop = () => {};

export default {
    name: 'DsErrorBannerContainer',

    compatConfig: { MODE: 3 },

    components: { ErrorBanner },

    data() {
        return {
            isOpen: false,
            position: undefined,
            message: undefined,
            showIcon: false,
            critical: false,
            actionLabel: undefined,
            closeLabel: undefined,
            action: noop,
        };
    },

    created() {
        this.$errorBannerEmitter.on('open', this.handleGlobalOpen);

        if (this.$bus) {
            this.$bus.$on('CLOSE_ERROR_BANNER', this.close);
        }
    },

    beforeUnmount() {
        this.$errorBannerEmitter.off('open', this.handleGlobalOpen);

        if (this.$bus) {
            this.$bus.$off('CLOSE_ERROR_BANNER', this.close);
        }
    },

    methods: {
        handleGlobalOpen(options) {
            const isOverlayActive = document.body.className.indexOf('overlay-active') >= 0;

            this.isOpen = true;
            this.position = options.bottom || isOverlayActive ? 'bottom' : 'top';
            this.message = options.message;
            this.showIcon = options.showIcon;
            this.critical = options.critical;
            this.actionLabel = options.actionText || options.actionLabel;
            this.closeLabel = options.closeLabel || this.$designSystem.i18n.messages['errorbanner.dismiss'];
            this.action = options.action || noop;
        },

        handleClose() {
            this.close();
        },

        handleAction() {
            this.action();
            this.close();
        },

        close() {
            this.isOpen = false;
        },
    },
};
</script>

<template>
    <div class="multiselect-menu">
        <template v-if="loading">
            <div class="menu-loading-container">
                <Spinner />
            </div>
        </template>

        <template v-else>
            <ul
                v-if="categoryViewActive"
                v-show="showEmptyOptionsLabel || categoryOptions.length > 0 || allowAdd"
                key="categories"
                :class="[{ dense }]"
            >
                <li v-if="categoriesLabel" class="label dropdown-header">
                    <label>{{ categoriesLabel }}</label>
                </li>

                <li
                    v-if="allowAdd"
                    class="add"
                    :data-qa="addText"
                    @click="add"
                >
                    <Icon name="add" />
                    <span>{{ addText || $designSystem.i18n.messages['select.add'] }}</span>
                </li>

                <li
                    v-if="showEmptyOptionsLabel && categoryOptions.length === 0"
                    class="label empty-options"
                >
                    {{ emptyOptionsLabel || $designSystem.i18n.messages['select.empty.options'] }}
                </li>

                <MultiSelectMenuItem
                    v-for="(option, i) in categoryOptions"
                    :key="i"
                    :index="i"
                    :pointer="localPointer"
                    :option="option"
                    :selected="selected"
                    :filter="filter"
                    is-category
                    @select-option="selectCategory"
                    @mouseover="handleMouseOver"
                >
                    <slot name="option" />
                </MultiSelectMenuItem>
            </ul>

            <ul
                v-if="!categoryViewActive"
                v-show="showEmptyOptionsLabel || dropdownOptions.length > 0 || allowAdd"
                key="options"
                :class="[{ dense }]"
            >
                <li
                    v-if="showBlank && !multiple && !categorized"
                    @click.prevent.stop="clearSelection"
                >
                    &nbsp;
                </li>

                <MultiSelectMenuItem
                    v-if="selectedCategory"
                    :option="selectedCategory"
                    is-category
                    is-breadcrumb
                    @select-option="backToCategory"
                />

                <li
                    v-if="allowAdd"
                    class="add"
                    :data-qa="addText"
                    @click="add"
                >
                    <Icon name="add" />
                    <span>{{ addText || $designSystem.i18n.messages['select.add'] }}</span>
                </li>

                <li
                    v-if="showEmptyOptionsLabel && dropdownOptions.length === 0"
                    class="label empty-options"
                >
                    {{ emptyOptionsLabel || $designSystem.i18n.messages['select.empty.options'] }}
                </li>

                <MultiSelectMenuItem
                    v-for="(option, i) in dropdownOptions"
                    :key="i"
                    :index="i"
                    :pointer="localPointer"
                    :option="option"
                    :selected="selected"
                    :value-prop="valueProp"
                    :label-prop="labelProp"
                    :disabled-option-prop="disabledOptionProp"
                    :show-option-modifier-prop="showOptionModifierProp"
                    :filter="filter"
                    :help-text="helpText"
                    :help-text-prop="helpTextProp"
                    @select-option="selectOption"
                    @mouseover="handleMouseOver"
                >
                    <template #default="slotProps">
                        <slot name="option" v-bind="slotProps" />
                    </template>

                    <template #optionModifier>
                        <slot name="optionModifier" />
                    </template>
                </MultiSelectMenuItem>
            </ul>
        </template>
    </div>
</template>

<script>
import { Icon } from '../Icon';
import { Spinner } from '../Spinner';
import MultiSelectMenuItem from './MultiSelectMenuItem.vue';

import '../../assets/icons/chevron-left.svg';

export default {
    name: 'DsMultiselectMenu',

    compatConfig: { MODE: 3 },

    components: {
        Icon,
        Spinner,
        MultiSelectMenuItem,
    },

    props: {
        /**
         * Text displayed on the add option
         */
        addText: String,

        helpText: Boolean,
        allowAdd: Boolean,
        categorized: Boolean,
        categoriesLabel: String,
        multiple: Boolean,
        limit: Number,

        options: {
            type: Array,
            default: () => [],
        },

        pointer: Number,

        filter: {
            type: String,
            default: '',
        },

        modelValue: [Object, Array],
        loading: Boolean,

        /**
         * Show blank menu option
         */
        showBlank: Boolean,

        helpTextProp: {
            type: String,
            default: 'helpText',
        },

        labelProp: {
            type: String,
            default: 'label',
        },

        valueProp: {
            type: String,
            default: 'value',
        },

        disabledOptionProp: {
            type: String,
            default: 'disabledOption',
        },

        showOptionModifierProp: String,

        /**
         * Disables category selection - for use with multi-level dropdown
         */
        disableCategorySelect: Boolean,

        /**
         * Enables dense menu items
         */
        dense: Boolean,

        /**
         * Text to display where there are no options
         */
        emptyOptionsLabel: String,

        /**
         * Whether to display empty options label (hidden with searchable multiselect, that has no results label)
         */
        showEmptyOptionsLabel: {
            type: Boolean,
            default: true,
        },
    },

    emits: [
        'add',
        'categoryView',
        'categoryMouseOver',
        'menuItemChanged',
        'selectCategory',
        'update:modelValue',
        'update:pointer',
        'updateVisibleOptions',
    ],

    data() {
        return {
            active: false,
            // So we know which dropdown options to show based on selected category
            activeCategoryIndex: -1,
            // Looking at category page or options
            categoryViewActive: this.categorized,
            closeOnBodyClick: true,
            localPointer: this.pointer,
            selected: this.modelValue,
            selectedCategory: null,
        };
    },

    computed: {
        categoryOptions() {
            let categoryOptions = [];

            if (this.categorized && this.options) {
                categoryOptions = this.options.map(({
                    category,
                    options,
                    groups,
                    separator,
                }, i) => ({
                    label: category.label,
                    value: category.value,
                    icon: category.icon,
                    separator: Boolean(separator),
                    index: i,
                    hasChildrenOptions: Boolean(options || groups),
                }));

                if (this.multiple) {
                    return categoryOptions.filter((categoryOption) => {
                        const value = categoryOption[this.valueProp];
                        const categoryIsSelected = this.selected.find(({ value: selectedValue }) => selectedValue === value);

                        return categoryOption.hasChildrenOptions || !categoryIsSelected;
                    });
                }
            }

            return categoryOptions;
        },

        dropdownOptions() {
            let { options } = this;
            const filter = `${this.filter}`.toLowerCase();

            if (this.categorized) {
                if (this.activeCategoryIndex > -1) {
                    options = this.options[this.activeCategoryIndex].groups
                        ? this.options[this.activeCategoryIndex].groups
                        : this.options[this.activeCategoryIndex].options;
                } else {
                    return [];
                }
            }

            if (options.length > 0) {
                const hasGroups = Array.isArray(options[0].options);

                options = hasGroups
                    ? this.filterAndFlattenGroups(options, filter)
                    : filter ? this.filterOptions(options, filter) : options;

                if (this.multiple) {
                    return options.filter((option) => {
                        const value = option[this.valueProp];

                        return !this.selected.find(({ value: selectedValue }) => selectedValue === value);
                    });
                }
            }

            return options;
        },

        visibleOptions() {
            return this.categoryViewActive ? this.categoryOptions : this.dropdownOptions;
        },
    },

    watch: {
        categoryViewActive(categoryViewActive) {
            if (categoryViewActive) {
                this.activeCategoryIndex = -1;
            }

            this.$emit('categoryView', categoryViewActive);
        },

        localPointer(localPointer) {
            this.$emit('update:pointer', localPointer);
        },

        pointer(pointer) {
            this.localPointer = pointer;
        },

        modelValue: {
            handler(modelValue) {
                this.selected = modelValue;
            },
            deep: true,
        },

        visibleOptions: {
            handler() {
                this.updateVisibleOptions();
            },
            deep: true,
        },
    },

    mounted() {
        this.updateVisibleOptions();
    },

    methods: {
        handleMouseOver(mouseOverIndex = null, category = null) {
            this.localPointer = mouseOverIndex;

            if (this.categorized && category) {
                const categoryReference = this.$refs.category[mouseOverIndex];

                this.$emit('categoryMouseOver', {
                    categoryReference,
                    optionIndex: mouseOverIndex,
                });
            }
        },

        add() {
            this.$emit('add');
        },

        arrowBack() {
            if (this.categorized && !this.categoryViewActive) {
                this.backToCategory();
            }
        },

        arrowForward() {
            if (this.categorized && this.categoryViewActive) {
                this.selectCategory(this.categoryOptions[this.localPointer]);
            }
        },

        clearSelection() {
            this.selected = null;
            this.$emit('update:modelValue', this.selected);
        },

        onEnter(option) {
            if (this.categoryViewActive) {
                this.selectCategory(option);
            } else {
                this.selectOption(option);
            }
        },

        backToCategory() {
            this.selectedCategory = null;
            this.categoryViewActive = this.categorized;
        },

        filterAndFlattenGroups(groups, filter) {
            groups = this.filterGroups(groups, filter);
            groups = this.flattenGroupOptions(groups);

            return groups;
        },

        filterGroups(groups, filter) {
            return groups.map(({ options, label, showLabelWhenEmpty }) => {
                const filteredOptions = this.filterOptions(options, filter);

                return filteredOptions && (filteredOptions.length || showLabelWhenEmpty) ? {
                    label,
                    showLabelWhenEmpty,
                    options: filteredOptions,
                } : [];
            });
        },

        filterOptions(options, filter) {
            if (!filter) {
                return options;
            }

            filter = `${filter}`.toLowerCase();

            return options.filter((option) => {
                const label = (option[this.labelProp] ? option[this.labelProp] : '').toLowerCase();
                const helpText = (option[this.helpTextProp] ? option[this.helpTextProp] : '').toLowerCase();

                return (helpText.length > 0 && helpText.includes(filter)) || (label.length > 0 && label.includes(filter));
            });
        },

        flattenGroupOptions(groups) {
            return groups.reduce((previous, current) => {
                const options = current.options
                    ? current.options
                    : [];

                if (options.length || current.showLabelWhenEmpty) {
                    if (current.label !== false) {
                        previous.push({
                            groupLabel: current.label,
                            isLabel: true,
                        });
                    }

                    return previous.concat(options);
                }

                return previous.concat(current);
            }, []);
        },

        selectOption(option) {
            if (!option.isLabel && !option[this.disabledOptionProp]) {
                if (this.multiple) {
                    if (!this.limit || this.selected.length < this.limit) {
                        this.selected.push(option);
                    }
                } else {
                    this.selected = option;
                }

                if (this.selectedCategory) {
                    this.$emit('selectCategory', this.selectedCategory);
                }

                this.$emit('update:modelValue', this.selected);
            }
        },

        selectCategory(category) {
            this.selectedCategory = category;

            if (!this.disableCategorySelect && category.hasChildrenOptions) {
                this.activeCategoryIndex = category.index;
                this.categoryViewActive = false;

                this.$emit('menuItemChanged');
            } else if (!category.hasChildrenOptions) {
                this.selectOption(category);
            }
        },

        updateVisibleOptions() {
            this.$emit('updateVisibleOptions', this.visibleOptions);
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .multiselect-menu {
        width: var(--multiselect-menu-width, 100%);

        .menu-loading-container {
            align-items: center;
            background-color: $color-paper;
            display: flex;
            justify-content: center;
            opacity: .8;
            padding: $spacing-200 0;
        }

        .dropdown-header label {
            @include prevent-select;
        }

        ul {
            @include dropdown-list;
        }

        li {
            @include dropdown-list-item;

            --icon-size: #{$font-size-lg};

            transform: translateZ(0);

            &.label {
                cursor: default;
                padding: $spacing-100 $spacing-200;
                margin-bottom: $spacing-050;

                &:hover {
                    background-color: $color-paper;
                }

                &:not(:first-child) {
                    margin-top: $spacing-200;
                }
            }

            &.add {
                --icon-color: #{$color-blue};

                color: $color-blue;
            }

            &.empty-options {
                @include prevent-select;
                color: $color-text-disabled;
                font-style: italic;
            }
        }

        .dense li {
            padding: $spacing-100 $spacing-200;
            font-size: $font-size-sm;
            --icon-size: #{$font-size-md};
        }
    }
</style>

<template>
    <div class="colorpicker">
        <section class="controls">
            <Saturation :value="colors" @change="handleSaturationChange" />

            <div class="control">
                <input
                    :value="h"
                    :style="gradientH"
                    type="range"
                    min="0"
                    max="360"
                    @input="handleHueChange"
                />
            </div>
        </section>
    </div>
</template>

<script>
import tinycolor from 'tinycolor2';

import { hexToHsl, hslToHex } from './color';
import Saturation from './Saturation.vue';

function colorChange(data, oldHue) {
    const alpha = data && data.a;
    let color;

    // hsl is better than hex between conversions
    if (data && data.hsl) {
        color = tinycolor(data.hsl);
    } else if (data && data.hex && data.hex.length > 0) {
        color = tinycolor(data.hex);
    } else {
        color = tinycolor(data);
    }

    if (color && (color.a === undefined || color.a === null)) {
        color.setAlpha(alpha || 1);
    }

    const hsl = color.toHsl();
    const hsv = color.toHsv();

    if (hsl.s === 0) {
        hsv.h = data.h || (data.hsl && data.hsl.h) || oldHue || 0;
    }

    return {
        hsl,
        hex: color.toHexString().toUpperCase(),
        rgba: color.toRgb(),
        hsv,
        oldHue: data.h || oldHue || hsl.h,
        source: data.source,
        a: data.a || color.getAlpha(),
    };
}

function hslCssString(h, s, l) {
    return `hsl(${parseFloat(h)}, ${s}%, ${l}%)`;
}

export default {
    name: 'DsColorPicker',

    compatConfig: { MODE: 3 },

    components: {
        Saturation,
    },

    props: {
        /**
         * Determines the current value of the color picker
         */
        modelValue: {
            type: String,
            default: '#58a1d8',
        },
    },

    emits: ['update:modelValue'],

    computed: {
        hsl() {
            return hexToHsl(this.modelValue);
        },

        h() {
            return this.hsl.h;
        },

        colors() {
            return colorChange(this.modelValue);
        },

        gradientH() {
            const stops = [];

            for (let i = 0; i < 7; i++) {
                const h = i * 60;

                stops.push(hslCssString(h, 100, 50));
            }

            return {
                backgroundImage: `linear-gradient(to right, ${stops.join(', ')})`,
            };
        },
    },

    methods: {
        handleHueChange(event) {
            const h = parseInt(event.target.value, 10);
            const { s, l } = this.hsl;

            const hex = hslToHex(h, s, l);

            this.$emit('update:modelValue', hex);
        },

        handleSaturationChange(data) {
            const { hex } = colorChange(data, this.h);

            this.$emit('update:modelValue', hex);
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .colorpicker {
        padding: $spacing-100;

        .swatch {
            border-radius: $border-radius;
            height: px-to-rem(120px);
            margin-bottom: $spacing-300;
            border: $input-border;
        }

        .control {
            margin-bottom: $spacing-200;
            width: 100%;
        }

        button {
            margin-top: $spacing-200;
        }
    }
</style>

<template>
    <transition name="slide-up" class="trowser">
        <div v-show="show" :class="['trowser-bar', { absolute }]">
            <slot />
        </div>
    </transition>
</template>

<script>

export default {
    name: 'DsTrowser',

    compatConfig: { MODE: 3 },

    props: {
        show: Boolean,
        absolute: Boolean,
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .trowser-bar {
        background-color: $trowser-background-color;
        box-shadow: $trowser-box-shadow;
        height: var(--trowser-height, #{$trowser-height});
        font-size: $font-size-md;

        border-radius: 0;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100;
        margin: 0;
        width: 100%;

        &.absolute {
            position: absolute;
        }

        @media($small) {
            height: var(--trowser-height, auto);
        }
    }
</style>

<template>
    <div class="toast-group">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'DsToastGroup',

    compatConfig: { MODE: 3 },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .toast-group {
        @include position(
            fixed auto var(--toast-right, #{$spacing-200}) var(--toast-bottom, #{$spacing-200})
            var(--toast-left, auto)
        );

        z-index: $zindex-toast;
        display: flex;
        flex-direction: column-reverse;

        @media ($small) {
            @include position-start(var(--toast-left, #{$spacing-200}));
            @include position-end(var(--toast-right, auto));
        }
    }
</style>

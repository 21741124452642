<script>
import { h } from 'vue';
import { Icon } from '../Icon';

export default {
    compatConfig: { MODE: 3 },

    props: {
        /**
         * Column data representation
         */
        column: Object,

        /**
         * Id of the data field you want to sort by
         */
        sortField: {
            type: String,
            default: '',
        },

        /**
         * Toggle to sort ascending/descending
         */
        sortAscending: Boolean,
    },

    render() {
        const { label } = this.column;

        let content = label;
        const {
            property,
            sortable,
            numeric,
            expand,
            width,
        } = this.column;

        if (sortable) {
            content = [
                label,
                h('div', { class: { 'sort-arrows': true } }, [
                    h(
                        Icon,
                        {
                            name: 'arrow-up',
                            class: {
                                active: this.sortField === property,
                                descending: !this.sortAscending,
                            },
                        },
                        '',
                    ),
                ]),
            ];
        }

        return h(
            'th',
            {
                class: {
                    sortable,
                    numeric,
                },
                style: {
                    'min-width': expand ? `${width}px` : '',
                },
            },
            [
                h(
                    'div',
                    {
                        class: {
                            'active-sortable': this.sortField && this.sortField === property,
                            cell: true,
                        },
                    },
                    content,
                ),
            ],
        );
    },
};
</script>

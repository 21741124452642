<template>
    <div :class="['date-picker', { 'has-selected': modelValue, active }, { 'has-custom-reference': customReference }]">
        <div v-if="readonly" class="readonly">
            <input :id="name" type="text" data-input />

            <InputField
                v-if="readonly"
                :name="name"
                :label="label"
                :required="required"
                :model-value="formattedDate"
                readonly
            />
        </div>

        <div
            v-else-if="showDefaultInput"
            :class="['date-picker-container', { 'no-label': !label, error: showError }]"
        >
            <label v-if="label" :for="name" :class="{ required }">
                {{ label }}
            </label>

            <div ref="dateInput" class="date-input">
                <input
                    :id="name"
                    type="text"
                    data-input
                    :placeholder="placeholder"
                />

                <div :class="['input-icon', { error: showError }]">
                    <Icon
                        v-if="timeOnly"
                        name="clock"
                        data-toggle
                    />

                    <Icon
                        v-else
                        name="calendar"
                        data-toggle
                    />
                </div>
            </div>

            <div v-if="$slots.help" class="assistive-text">
                <slot name="help" />
            </div>

            <div v-if="$slots.error && showError" class="error-text">
                <!-- @slot Error text -->
                <slot name="error" />
            </div>
        </div>

        <div v-else class="custom-reference" data-toggle>
            <input
                :id="name"
                type="text"
                data-input
            />

            <!-- @slot Custom reference content -->
            <slot />
        </div>
    </div>
</template>

<script>
import Flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';

import { Icon } from '../Icon';
import { InputField } from '../InputField';

import dateTime from './dateTimeMixin';

import '../../assets/icons/calendar.svg';

export default {
    name: 'DsDatePicker',

    compatConfig: { MODE: 3 },

    components: {
        Icon,
        InputField,
    },

    mixins: [dateTime],

    props: {
        /**
         * Determines the current value of the Date Field
         */
        modelValue: [Date, Object, String, Array],

        /**
         * Controls visualization of rendering form validation error states
         */
        submitted: Boolean,

        /**
         * Required field for validation purposes
         */
        required: Boolean,

        /**
         * HTML input element name
         */
        name: {
            type: String,
            default: '',
        },

        /**
         * Label to render above the Date field when active
         */
        label: {
            type: String,
            default: '',
        },

        /**
         * Controls if date input is modeling a range
         */
        range: Boolean,

        /**
         * Changes formatting to better accommodate birthday date
         */
        birthday: Boolean,

        /**
         * Allows user to manually input date
         */
        allowInput: Boolean,

        /**
         * Add time to the date picker
         */
        time: Boolean,

        /**
         * Time picker only without date calendar
         */
        timeOnly: Boolean,

        /**
         * Date picker only no time
         */
        dateOnly: Boolean,

        /**
         * Custom string to toggle date picker, must be inline-block
         */
        customReference: Boolean,

        /**
         * Use fixed position for datepicker input
         */
        static: Boolean,

        /**
         * Disable all dates before specifice, use "today" as shortcut
         */
        disableBeforeDate: {
            type: [Date, String, Object],
            default: null,
        },

        /**
         * Prevent user from updating the input
         */
        readonly: Boolean,

        /**
         * Force the error state to show if parent is controlling it
         */
        forceInvalid: Boolean,
    },

    emits: [
        'close',
        'update:modelValue',
    ],

    data() {
        return {
            fp: null,
            active: false,
        };
    },

    computed: {
        showError() {
            return this.forceInvalid || this.required && this.submitted && !this.modelValue;
        },

        placeholder() {
            return this.birthday && this.allowInput
                ? this.$designSystem.i18n.messages['date.format']
                : '';
        },

        showDefaultInput() {
            return this.static || !this.customReference;
        },

        formattedDate() {
            if (this.fp) {
                return this.fp.formatDate(this.fp.config.defaultDate, this.fp.config.altFormat);
            }

            return '';
        },
    },

    watch: {
        modelValue: {
            handler(newValue) {
                let value = newValue;
                const selectedDate = this.getValue(this.fp.selectedDates);

                if (this.timeOnly || this.range || this.dateOnly) {
                    if (value === selectedDate) {
                        return;
                    }
                } else {
                    if (typeof value === 'string') {
                        value = new Date(value);
                    }

                    if (value && selectedDate && value.getTime() === selectedDate.getTime()) {
                        return;
                    }
                }

                let fpValue = value;

                if (this.timeOnly) {
                    fpValue = this.dateTime_fromEncodedTimeString(fpValue);
                }

                if (this.fp) {
                    this.fp.setDate(fpValue, true);
                }
            },
            deep: true,
        },

        disableBeforeDate(disableBeforeDate) {
            if (this.fp) {
                this.fp.set('minDate', disableBeforeDate);
            }
        },

        range(range) {
            if (this.fp) {
                this.fp.set('mode', range ? 'range' : 'single');
            }
        },
    },

    mounted() {
        this.setupFlatpickr();
    },

    beforeUnmount() {
        this.destroyFlatpickr();
    },

    methods: {
        setupFlatpickr() {
            if (this.fp) {
                return;
            }

            let altFormat = 'F j, Y';

            if (this.time) {
                altFormat = 'F j, Y h:iK';
            }

            if (this.birthday) {
                altFormat = 'm/d/Y';
            }

            if (this.timeOnly) {
                altFormat = 'h:iK';
            }

            let defaultDate = this.modelValue;

            if (this.timeOnly) {
                defaultDate = this.dateTime_fromEncodedTimeString(this.modelValue);
            }

            const config = {
                onOpen: this.onOpen,
                onClose: this.onClose,
                onChange: this.onChange,
                altInput: true,
                altFormat,
                allowInput: this.allowInput,
                defaultDate,
                static: this.customReference || this.static,
                mode: this.range ? 'range' : 'single',
                minDate: this.disableBeforeDate,
                enableTime: this.time || this.timeOnly,
                noCalendar: this.timeOnly,
                wrap: true,
                disableMobile: true,
            };

            if (this.static) {
                this.fp = new Flatpickr(this.$refs.dateInput, config);
            } else {
                this.fp = new Flatpickr(this.$el, config);
            }

            const dateInputSection = this.$refs.dateInput;

            if (dateInputSection) {
                const shadowDateInput = dateInputSection.querySelector('.flatpickr-input');

                shadowDateInput.removeAttribute('type');
                shadowDateInput.setAttribute('style', 'display:none');

                if (this.required) {
                    shadowDateInput.setAttribute('required', true);
                }
            }
        },

        destroyFlatpickr() {
            if (this.fp) {
                this.fp.destroy();
            }

            this.fp = null;
        },

        onChange(selectedDates) {
            /**
             * Selected input changed
             */
            this.$emit('update:modelValue', this.getValue(selectedDates));
        },

        onOpen() {
            const dayChanged = this.fp.now.getDate() !== new Date().getDate();

            if (dayChanged) {
                this.fp.now = new Date();
                this.fp.redraw();
            }

            this.active = true;
        },

        onClose() {
            this.$emit('close');
            this.active = false;
        },

        getValue(selectedDates) {
            if (this.range) {
                return selectedDates;
            }

            if (Array.isArray(selectedDates) && selectedDates.length) {
                const selectedDate = selectedDates[0];

                if (this.timeOnly) {
                    return this.dateTime_toEncodedTimeString(selectedDate);
                }

                if (this.dateOnly && selectedDate) {
                    const dateString = new Date(selectedDate.getTime() - (selectedDate.getTimezoneOffset() * 60000))
                        .toISOString()
                        .split('T')[0];

                    return dateString;
                }

                return selectedDate;
            }

            return null;
        },
    },
};
</script>

<style lang="scss">
    @import "../../styles/common";

    .date-picker {
        &.has-selected {
            label {
                @include select-label;
            }
        }

        &.active {
            label {
                @include input-label-selected;
                color: $color-blue;
            }
        }

        .input-icon {
            @include position-end($spacing-150);

            --icon-color: #{$color-ink-600};
            --icon-size: #{$icon-size};

            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            line-height: 0;

            &.error {
                --icon-color: #{$input-error-color};
            }
        }

        .custom-reference {
            input {
                @include hidden-input;
            }
        }

        .error-text {
            cursor: default;
        }
    }

    .date-picker-container,
    .flatpickr-wrapper
    {
        @include input-field;

        --icon-color: #{$color-ink-600};

        cursor: pointer;
        display: flex;
        justify-content: space-between;

        .date-input {
            position: relative;
            width: 100%;
        }

        input {
            cursor: pointer;
            display: flex;

            &.active {
                @include selected-input-border($color-blue);
            }
        }

        label {
            @include label;

            z-index: 1;

            &.required:after {
                @include input-label-required;
            }
        }

        &.error {
            input {
                border: #{$input-border-thickness} solid #{$input-error-color};
            }

            label {
                color: $input-error-color;
            }
        }
    }

    .flatpickr-wrapper {
        .input-icon {
            transform: translateY(50%);
        }
    }

    // Flatpickr overrides

    .flatpickr-calendar {
        margin-top: $spacing-100;
        width: px-to-rem(324px);

        &.arrowTop:before,
        &.arrowTop:after {
            display: none;
        }

        .dayContainer {
            width: px-to-rem(324px);
        }

        .numInputWrapper:hover {
            background: $color-gray-050;
        }
    }

    .flatpickr-months {
        padding-top: $spacing-100;
    }

    .flatpickr-rContainer {
        padding: 0 $spacing-100 $spacing-100;
    }

    .flatpickr-current-month span.cur-month {
        font-weight: $font-weight-light;
    }

    .flatpickr-months .flatpickr-next-month,
    .flatpickr-months .flatpickr-prev-month {
        top: $spacing-050;
        padding: $spacing-200;

        &:hover {
            svg {
                fill: $color-blue;
            }
        }
    }

    .flatpickr-innerContainer {
        margin-top: $spacing-200;
    }

    .flatpickr-day {
        border-radius: $border-radius;

        &:hover {
            background-color: $color-gray-100;
            border-color: $color-gray-100;
            color: $color-text-disabled;
        }

        &.today {
            &:hover {
                background-color: $color-gray-200;
                border-color: $color-gray-200;
                color: $color-text-subtle;
            }
        }

        &.selected,
        &.selected.inRange,
        &.selected.nextMonthDay,
        &.selected.prevMonthDay,
        &.selected:focus,
        &.selected:hover,
        &.startRange,
        &.startRange.inRange,
        &.startRange.nextMonthDay,
        &.startRange.prevMonthDay,
        &.startRange:focus,
        &.startRange:hover,
        &.endRange,
        &.endRange.inRange,
        &.endRange.nextMonthDay,
        &.endRange.prevMonthDay,
        &.endRange:focus,
        &.endRange:hover {
            background: $color-blue;
            border-color: $color-blue;

            &:hover {
                background: $color-blue;
                border-color: $color-blue;
            }
        }
    }

    .flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n+1)),
    .flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n+1)),
    .flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n+1)) {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .flatpickr-time .flatpickr-am-pm:focus,
    .flatpickr-time .flatpickr-am-pm:hover,
    .flatpickr-time input:focus,
    .flatpickr-time input:hover {
        background: $color-gray-100;
    }

    .flatpickr-time input.flatpickr-hour {
        font-weight: $font-weight-regular;
    }

    .has-custom-reference + .flatpickr-input.form-control.input,
    .readonly .flatpickr-input.form-control.input {
        display: none;
    }
</style>

<template>
    <div class="table-grid">
        <div class="hidden-columns">
            <!-- @slot Slot for TableGridColumn components -->
            <slot />
        </div>

        <div :class="['table-wrapper', { 'trowser-active': allSelected }]">
            <table>
                <colgroup>
                    <col
                        v-if="selectable"
                        name="checkbox"
                        :width="70"
                    />

                    <col
                        v-for="(column, i) in columns"
                        :key="i"
                        :name="`col-th-${column.id}`"
                        :width="column.expand ? '' : column.width"
                    />
                </colgroup>

                <thead>
                    <tr>
                        <th v-if="selectable" class="column-checkbox">
                            <Checkbox
                                v-model="allSelected"
                                class="selectAll"
                                :partial="partialSelection"
                                @update:model-value="handleSelectAll"
                            />
                        </th>

                        <TableGridHeader
                            v-for="column in columns"
                            :key="column.id"
                            :column="column"
                            :sort-field="localSortField"
                            :sort-ascending="localSortAscending"
                            @click="sort(column)"
                        />
                    </tr>
                </thead>

                <tbody>
                    <tr
                        v-for="(row, rowIndex) in data"
                        :key="rowIndex"
                        ref="row"
                        :class="{ 'selected-row': isSelected(rowIndex), 'selectable-row': clickRow }"
                    >
                        <td
                            v-if="selectable"
                            :class="['column-checkbox', { selectable }]"
                            @click="handlerRowClick(rowIndex)"
                        >
                            <Checkbox
                                v-if="checkboxData[rowIndex]"
                                ref="checkbox"
                                v-model="checkboxData[rowIndex].checked"
                                :data-qa="row.fullName || rowIndex"
                                :error="checkboxData[rowIndex].error"
                                :disabled="checkboxData[rowIndex].disabled"
                            />
                        </td>

                        <TableGridCell
                            v-for="column in columns"
                            :key="column.id"
                            :data="row"
                            :column="column"
                            @click="event => performAction(row, event)"
                        />
                    </tr>
                </tbody>
            </table>

            <InfiniteScroll
                key="InfiniteScroll"
                v-model:limit="optimalLimit"
                :no-more-data="noMoreData"
                :loading="loading"
                :disabled="!infinite"
                :threshold="threshold"
                :debounce-delay="debounceDelay"
                @load="load"
            >
                <table>
                    <colgroup>
                        <col
                            v-if="selectable"
                            name="checkbox"
                            :width="70"
                        />

                        <col
                            v-for="(column, i) in columns"
                            :key="i"
                            :name="`col-th-${column.id}`"
                            :width="column.width"
                        />
                    </colgroup>

                    <tbody>
                        <tr
                            v-for="n in 3"
                            :key="n"
                            :style="{ height: `${placeholderHeight}px` }"
                            class="placeholder"
                        >
                            <td v-if="selectable">
                                <Checkbox disabled />
                            </td>

                            <td v-for="{ id } in columns" :key="id">
                                <Placeholder :rows="placeholder" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </InfiniteScroll>
        </div>

        <TableGridTrowser
            :selected-count="selectedCount"
            :selected-item-label="selectedItemLabel || $designSystem.i18n.messages['tablegrid.selected']"
            :selected-options="selectedOptions"
            :show="someSelected"
            @selected-clicked="selectedAction"
        />
    </div>
</template>

<script>
import Checkbox from '../Checkbox/Checkbox.vue';
import { Placeholder } from '../Placeholder';
import { InfiniteScroll } from '../InfiniteScroll';
import TableGridHeader from './TableGridHeader.vue';
import TableGridCell from './TableGridCell.vue';
import TableGridTrowser from './TableGridTrowser.vue';

export default {
    name: 'DsTableGrid',

    compatConfig: { MODE: 3 },

    components: {
        TableGridHeader,
        TableGridCell,
        TableGridTrowser,
        Checkbox,
        Placeholder,
        InfiniteScroll,
    },

    props: {
        /**
         * Array of data to render in the table
         */
        data: Array,

        /**
         * Number of infinite scroll elements at a time
         */
        limit: Number,

        /**
         * Two way binding of the select all option being active
         */
        selectAll: Boolean,

        /**
         * Allow rows to be selectable
         */
        selectable: Boolean,

        /**
         * Toggle to show loading state
         */
        showLoading: Boolean,

        /**
         * Id of the data field you want to sort by
         */
        sortField: {
            type: String,
            default: '',
        },

        /**
         * Toggle to sort ascending/descending
         */
        sortAscending: Boolean,

        /**
         * Infinite scrolling of loading data
         */
        infinite: Boolean,

        /**
         * Px threshold of when to load more rows in table
         */
        threshold: Number,

        /**
         * Debounce delay of loading more rows in infinite srolling
         */
        debounceDelay: Number,

        /**
         * Height in px of the placeholder row
         */
        placeholderHeight: Number,

        /**
         * Label of the selected items to display in the table
         */
        selectedItemLabel: String,

        /**
         * Label value event names of options to take with selected rows
         * */
        selectedOptions: Array,

        /**
         * Total number of rows for when select all is checked
         */
        total: Number,

        /**
         * Initial value of selected rows or unselected if selecting all with infinite
         */
        modelValue: {
            type: Array,
            default: () => [],
        },

        /**
         * Action taken when row is clicked
         */
        clickRow: Function,
    },

    emits: [
        'action',
        'load',
        'sort',
        'update:limit',
        'update:modelValue',
        'update:selectAll',
    ],

    data() {
        return {
            optimalLimit: this.limit || 1,
            loading: true,
            noMoreData: false,
            allSelected: this.selectAll,
            checkboxData: [],
            columns: [],
            localSortField: this.sortField,
            localSortAscending: this.sortAscending,
            placeholder: [{ height: '0.5rem', boxes: [1] }],
            sortable: Boolean(this.sortField),
            selectedRows: this.modelValue,
        };
    },

    computed: {
        someSelected() {
            return this.selectedCount > 0;
        },

        selectedCount() {
            return this.trackUnselected
                ? this.total - this.selectedRows.length
                : this.selectedRows.length;
        },

        trackUnselected() {
            return this.infinite && this.allSelected;
        },

        partialSelection() {
            const infiniteWithUnslectedRows = this.infinite && this.selectedRows.length > 0;
            const notInfiniteWithUnselectedRows = !this.infinite && this.selectedRows.length < this.data.length;

            return this.allSelected && (infiniteWithUnslectedRows || notInfiniteWithUnselectedRows);
        },
    },

    watch: {
        checkboxData: {
            handler(checkboxes) {
                this.selectedRows = checkboxes.reduce((boxes, { checked, rowIndex }) => {
                    if (checked && !this.trackUnselected || !checked && this.trackUnselected) {
                        boxes.push(rowIndex);
                    }

                    return boxes;
                }, []);

                /**
                 * Triggers input vmodel value changes
                 */
                this.$emit('update:modelValue', this.selectedRows);
            },
            deep: true,
        },

        allSelected(allSelected) {
            /**
             * Triggers when the value of the selectAll is changed internally
             */
            this.$emit('update:selectAll', allSelected);
        },

        data: {
            handler() {
                if (this.selectable) {
                    this.updateCheckboxData();
                }
            },
            deep: true,
        },

        showLoading(showLoading) {
            this.loading = showLoading;
        },

        sortField(sortField) {
            this.localSortField = sortField;
            this.emitSort();
        },

        sortAscending(sortAscending) {
            this.localSortAscending = sortAscending;
            this.emitSort();
        },
    },

    created() {
        this.loading = Boolean(!this.data.length);

        if (this.selectable) {
            this.updateCheckboxData();
        }
    },

    methods: {
        isSelected(index) {
            return this.trackUnselected
                ? !this.selectedRows.includes(index)
                : this.selectedRows.includes(index);
        },

        updateCheckboxData() {
            if (this.data.length) {
                this.checkboxData = this.data.map((row, rowIndex) => {
                    const checked = this.trackUnselected
                        ? !this.selectedRows.includes(rowIndex)
                        : this.selectedRows.includes(rowIndex);

                    return {
                        rowIndex,
                        checked,
                        error: false,
                        disabled: false,
                    };
                });
            }
        },

        selectedAction(event) {
            /**
             * Triggers when an action is selected
             */
            this.$emit('action', event);
        },

        handlerRowClick(row) {
            this.$refs.checkbox[row].change();
        },

        handleSelectAll(allSelected) {
            this.checkboxData.forEach((box) => {
                box.checked = allSelected;
            });
        },

        reset() {
            this.noMoreData = false;
            this.resetSelections();
        },

        finishLoading() {
            this.noMoreData = true;
        },

        load() {
            if (this.limit < this.optimalLimit) {
                /**
                 * Triggers when the value of the limit is changed internally by the infinite scrolling
                 */
                this.$emit('update:limit', this.optimalLimit);
                this.$nextTick(() => {
                    /**
                     * Triggers when the table is ready for the parent to load more rows
                     */
                    this.$emit('load');
                });
            } else {
                /**
                 * Triggers when the table is ready for the parent to load more rows
                 */
                this.$emit('load');
            }
        },

        register(column) {
            this.columns.push(column);
        },

        resetSelections() {
            this.selectedRows = [];
            this.allSelected = false;
            this.updateCheckboxData();
        },

        sort(column) {
            if (this.sortable && column.sortable) {
                this.localSortAscending = column.property === this.localSortField
                    ? !this.localSortAscending : true;

                this.localSortField = column.property;

                this.emitSort();
            }
        },

        emitSort() {
            this.resetSelections();

            /**
             * Triggers when the user is sorting by a column, passes the field id and ascending or not
             */
            this.$emit('sort', {
                field: this.localSortField,
                ascending: this.localSortAscending,
            });
        },

        performAction(row, event) {
            if (typeof this.clickRow === 'function') {
                this.clickRow(row, event);
            }
        },
    },
};
</script>

<style lang="scss">
    /* unscoped because these styles need to apply to slotted items */
    .table-grid .table-wrapper tbody {
        @import "../../styles/common";

        a {
            &,
            &:visited,
            &:hover,
            &:active {
                color: $color-text-normal;
            }
        }

        tr:hover a {
            text-decoration: underline;
        }
    }
</style>

<style lang="scss" scoped>
    @import "../../styles/common";

    .table-grid {
        --checkbox-padding: #{$spacing-100};
        position: relative;
        width: 100%;

        .hidden-columns {
            display: none;
        }

        .column-checkbox {
            width: $table-grid-checkbox-height;
        }

        td.numeric {
            font-family: $font-table-number;
            font-size: $font-size-sm;
            @include text-align-end;
        }

        th.numeric {
            @include text-align-end;
        }

        .selectable {
            cursor: pointer;
        }

        .selected-row {
            background-color: $table-grid-selected-background-color;
            border-bottom: 1px solid $table-grid-selected-border-color;
        }

        tbody {
            tr {
                &:hover {
                    @include transition(background-color);

                    background-color: var(--table-grid-row-hover-background-color, $color-ink-100);
                }
            }
        }

        .selectable-row {
            cursor: pointer;
        }
    }

    .trowser-active {
        $trowser-height: px-to-rem(70px);

        margin-bottom: var(--trowser-height, #{$trowser-height});
    }
</style>

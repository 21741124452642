<template>
    <div v-if="hasBadge" class="icon-with-badge">
        <span v-if="showBadge" class="badge badge-without-icon" />

        <span v-if="badgeIcon" class="badge badge-with-icon">
            <SvgIcon :name="badgeIcon" />
        </span>

        <SvgIcon :name="name" />
    </div>

    <SvgIcon v-else :name="name" />
</template>

<script>
import SvgIcon from './SvgIcon.vue';

export default {
    name: 'DsIcon',

    compatConfig: { MODE: 3 },

    components: {
        SvgIcon,
    },

    props: {
        /**
         * The name of the icon
         */
        name: {
            type: String,
            required: true,
        },

        /**
         * Displays a badge on the top right corner of the icon box
         */
        showBadge: Boolean,

        /**
         * Name of the icon to display in the badge on the top right corner of the icon box
         */
        badgeIcon: String,
    },

    computed: {
        hasBadge() {
            return Boolean(this.showBadge || this.badgeIcon);
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .icon-with-badge {
        display: inline-flex;
        position: relative;
    }

    .badge {
        position: absolute;
        top: 0;
        @include position-end(0);

        z-index: 1;
        border-radius: 50%;
    }

    .badge-without-icon {
        background-color: $color-red;
        height: px-to-rem(10px);
        width: px-to-rem(10px);
    }

    .badge-with-icon {
        --icon-color: #{$color-paper};
        --icon-size: #{px-to-rem(12px)};

        background-color: $color-blue;
        height: var(--icon-size);
        width: var(--icon-size);
        padding: 0 $spacing-025;
    }
</style>

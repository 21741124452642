<template>
    <DsButton v-bind="$attrs" variant="text">
        <template v-for="(_, slot) in $slots" #[slot]>
            <slot :name="slot" />
        </template>
    </DsButton>
</template>

<script>
import DsButton from './Button.vue';

export default {
    name: 'DsTextButton',
    compatConfig: { MODE: 3 },
    components: { DsButton },
    inheritAttrs: false,
};
</script>

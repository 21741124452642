import { computed } from 'vue';
import { useMediaQuery as useMediaQueryCore } from '@vueuse/core';

function computeMediaQuery(options) {
    const {
        width,
        breakpoint,
        query,
        exact,
    } = options;

    if (query) {
        return query;
    }

    if (breakpoint) {
        if (exact) {
            switch (breakpoint) {
            case 'extra-small':
                return '(max-width: 749px)';
            case 'small':
                return '(min-width: 750px) and (max-width: 949px)';
            case 'medium':
                return '(min-width: 950px) and (max-width: 1199px)';
            case 'large':
                return '(min-width: 1200px) and (max-width: 1349px)';
            case 'extra-large':
                return '(min-width: 1350px)';
            default:
                return '';
            }
        }

        switch (breakpoint) {
        case 'small':
            return '(min-width: 750px)';
        case 'medium':
            return '(min-width: 950px)';
        case 'large':
            return '(min-width: 1200px)';
        case 'extra-large':
            return '(min-width: 1350px)';
        default:
            return '';
        }
    }

    return `(min-width: ${width}px)`;
}

export default function useMediaQuery(options) {
    const mediaQuery = computed(() => computeMediaQuery(options));

    return useMediaQueryCore(mediaQuery);
}

<template>
    <div
        class="modal-overlay"
        :class="{ dialog, open: isOpenInternal, fullscreen: isFullscreen }"
        @mousedown="overlayClick"
    >
        <div class="modal-dialog" :class="[computedSize, { dialog, center }]">
            <div ref="modalContentWrapper" class="modal-content-wrapper" :class="{ dialog }">
                <slot name="heading">
                    <ModalHeader v-if="hasHeader" :sticky="stickyDesktopHeader" :fullscreen="isFullscreen">
                        <template #leading>
                            <IconButton
                                v-if="showBack"
                                id="back"
                                name="arrow-left"
                                class="navigation-button"
                                @click.stop="tryBack"
                            />

                            <IconButton
                                v-else-if="showClose"
                                id="close"
                                name="x"
                                class="navigation-button"
                                @click.stop="tryClose"
                            />
                        </template>

                        <template #title>
                            <slot name="title">
                                <ModalHeaderTitle :title="title" :subtitle="subTitle" />
                            </slot>
                        </template>

                        <template #trailing>
                            <slot name="header" />
                        </template>
                    </ModalHeader>
                </slot>

                <div class="modal-body" :class="{ 'has-header': hasHeader }">
                    <div class="modal-body-inner">
                        <slot />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dom from '../../mixins/dom';

import { IconButton } from '../Button';
import ModalHeader from './ModalHeader.vue';
import ModalHeaderTitle from './ModalHeaderTitle.vue';

import scrollbarWidth from '../../mixins/scrollbarWidth';

import '../../assets/icons/x.svg';
import '../../assets/icons/arrow-left.svg';

export default {
    name: 'DsModal',

    compatConfig: { MODE: 3 },

    components: {
        IconButton,
        ModalHeader,
        ModalHeaderTitle,
    },

    mixins: [dom],

    props: {
        /**
         * Controls the open state of the modal.
         */
        isOpen: Boolean,

        /**
         * Determines if this is a dialog modal or not
         */
        dialog: Boolean,

        /**
         * Is the close visible
         */
        showClose: Boolean,

        /**
         * Is the back button visible
         */
        showBack: Boolean,

        /**
         * Center the modal vertically
         */
        center: Boolean,

        /**
         * The title of the modal
         */
        title: {
            type: String,
            default: '',
        },

        /**
         * A string below the title
         */
        subTitle: {
            type: String,
            default: '',
        },

        /**
         * Determines the size of the modal
         */
        size: {
            type: String,
            default: null,
            options: ['xs', 'sm', 'med', 'lg', 'xl', 'fluid', 'fullscreen'],
        },

        /**
         * Use sticky header on desktop as well as mobile
         */
        stickyDesktopHeader: Boolean,
    },

    emits: [
        'back',
        'close',
    ],

    data() {
        return {
            isOpenInternal: false,
        };
    },

    computed: {
        hasHeader() {
            return Boolean(this.title) || this.showBack || this.showClose;
        },

        computedSize() {
            if (this.size) {
                return this.size;
            }

            return this.dialog ? 'xs' : 'med';
        },

        isFullscreen() {
            return this.computedSize === 'fullscreen';
        },
    },

    watch: {
        isOpen: {
            handler(isOpen) {
                this.isOpenInternal = isOpen;

                if (isOpen) {
                    this.onOpen();
                } else {
                    this.onClose();
                }
            },
            immediate: true,
        },
    },

    beforeUnmount() {
        this.onClose();
    },

    methods: {
        tryBack() {
            this.$emit('back');
        },

        tryClose() {
            this.$emit('close');
        },

        overlayClick(e) {
            const shouldTryClose = !this.dialog
                && !this.dom_clickedTarget(e, this.$refs.modalContentWrapper)
                && document.documentElement.clientWidth - e.pageX > scrollbarWidth();

            if (shouldTryClose) {
                this.tryClose();
            }
        },

        onOpen() {
            document.body.classList.add('overlay-active');

            if (this.$designSystem && typeof this.$designSystem.onOverlayActive === 'function') {
                this.$designSystem.onOverlayActive();
            }
        },

        onClose() {
            document.body.classList.remove('overlay-active');

            if (this.$designSystem && typeof this.$designSystem.onOverlayInactive === 'function') {
                this.$designSystem.onOverlayInactive();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    @include keyframes(modalSlide) {
        from {
            transform: scale(0.8);
        }

        to {
            transform: scale(1);
        }
    }

    .modal-overlay {
        @include overlay-background;

        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        outline: 0;
        display: none;

        &.open {
            display: block;

            .modal-content-wrapper {
                @include animation('modalSlide');
            }
        }

        @media($small) {
            &:not(.dialog) {
                background-color: transparent;
            }

            &.dialog {
                padding-left: $spacing-200;
                padding-right: $spacing-200;
            }
        }

        @media($medium) {
            padding-left: $spacing-200;
            padding-right: $spacing-200;

            &.fullscreen {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .modal-dialog {
        @include text-align-start;
        position: relative;
        margin: $spacing-400 auto;
        display: flex;
        min-height: auto;
        max-width: 100%;
        width: var(--modal-width, 100%);

        &.dialog,
        &.center {
            display: flex;
            align-items: center;
            min-height: calc(100% - #{$spacing-800});
        }

        @media($small) {
            &:not(.dialog) {
                margin: 0 auto;
                min-height: 100%;
            }
        }

        @media($medium) {
            &.xs {
                max-width: $modal-size-xs;
                width: var(--modal-width, #{$modal-size-xs});
            }

            &.sm {
                max-width: $modal-size-sm;
                width: var(--modal-width, #{$modal-size-sm});
            }

            &.med {
                max-width: $modal-size-med;
                width: var(--modal-width, #{$modal-size-med});
            }

            &.fluid {
                max-width: $modal-size-fluid;
                width: var(--modal-width, #{$modal-size-fluid});
            }

            &.fullscreen {
                margin: 0;
                min-height: 100vh;
            }
        }

        &.lg {
            @media(min-width: $modal-size-lg + $spacing-400) {
                max-width: $modal-size-lg;
                width: var(--modal-width, #{$modal-size-lg});
            }
        }

        &.xl {
            @media(min-width: $modal-size-xl + $spacing-400) {
                max-width: $modal-size-xl;
                width: var(--modal-width, #{$modal-size-xl});
            }
        }
    }

    .modal-content-wrapper {
        background-color: var(--modal-background-color, #{$modal-background-color});
        box-shadow: $elevation-z16;
        overflow: var(--modal-overflow, visible);
        width: 100%;

        &.dialog {
            box-shadow: $elevation-z24;
        }

        @media($medium) {
            border-radius: $border-radius;
        }

        @media($small) {
            display: flex;
            flex-direction: column;
        }
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        flex: 1;

        @media($medium) {
            border-bottom-right-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
        }

        &:not(.has-header) {
            @media($medium) {
                border-top-right-radius: $border-radius;
                border-top-left-radius: $border-radius;
            }
        }
    }

    .modal-body-inner {
        padding: var(--modal-padding, #{$spacing-300});
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .fullscreen {
        .modal-content-wrapper,
        .modal-header,
        .modal-body {
            border-radius: 0;
        }
    }

    @media print {
        .modal-content-wrapper {
            box-shadow: none;
        }

        .modal-overlay {
            position: absolute;
            overflow: visible;
        }
    }
</style>

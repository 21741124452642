<template>
    <label
        class="checkbox"
        :class="{
            selected: modelValue,
            partial,
            error,
            disabled,
            task
        }"
        :data-qa="label"
    >
        <div class="checkbox-container">
            <input
                type="checkbox"
                :value="modelValue"
                :disabled="disabled"
                :required="required"
                class="visually-hidden"
                @change="change"
            />

            <div class="box" :style="boxStyle">
                <Icon
                    v-show="!partial"
                    name="check"
                    class="box-icon"
                    :style="iconStyle"
                />
                <Icon
                    v-show="partial && modelValue"
                    name="minus"
                    class="box-icon"
                    :style="iconStyle"
                />
            </div>
        </div>

        <div class="label-container">
            <slot>
                {{ label }}
            </slot>
        </div>
    </label>
</template>

<script>
import { Icon } from '../Icon';

import '../../assets/icons/check.svg';
import '../../assets/icons/minus.svg';

export default {
    name: 'DsCheckbox',

    compatConfig: { MODE: 3 },

    components: {
        Icon,
    },

    props: {
        /**
         * Boolean checked value.
         */
        modelValue: Boolean,

        /**
         * String value that appears next to the checkbox.
         */
        label: {
            type: String,
            default: '',
        },

        /**
         * Boolean value that controls the error state of the checkbox.
         */
        error: Boolean,

        /**
         * Boolean value that determines whether a user can interact with the checkbox.
         */
        disabled: Boolean,

        /**
         * Displays whether or not the checkboxes in a group are partially selected.
         */
        partial: Boolean,

        /**
         * Determines whether or not the checkbox will have a background color.
         */
        task: Boolean,

        /**
         * Custom hex color for the checkbox background when checked. Only when not in error or disabled state.
         */
        color: {
            type: String,
            default: '',
        },

        /**
         * Custom hex color for the checkbox border. Only when not in error state.
         */
        borderColor: {
            type: String,
            default: '',
        },

        /**
         * Custom hex color for the indicator icon when checked. Only when not in disabled state.
         */
        iconColor: {
            type: String,
            default: '',
        },

        /**
         * Required field for validation purposes
         */
        required: Boolean,
    },

    emits: ['update:modelValue'],

    computed: {
        boxStyle() {
            const {
                modelValue,
                error,
                disabled,
                color,
                borderColor,
            } = this;
            const style = {};

            if (error || disabled) {
                return style;
            }

            if (modelValue && color) {
                style.backgroundColor = color;
            }

            if (borderColor) {
                style.borderColor = borderColor;
            }

            return style;
        },

        iconStyle() {
            const style = {};

            if (this.disabled) {
                return style;
            }

            if (this.iconColor) {
                style.color = this.iconColor;
            }

            return style;
        },
    },

    methods: {
        change() {
            if (!this.disabled) {
                this.$emit('update:modelValue', !this.modelValue);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .checkbox {
        @include prevent-select;

        --checkbox-size: 1.25rem;
        --checkbox-check-size: 1.25rem;
        --checkbox-border-color: #{$color-gray-800};
        --checkbox-check-color: #{$color-paper};
        --checkbox-background-color: transparent;

        display: flex;
        align-items: var(--checkbox-align-items, center);
        padding: var(--checkbox-padding, #{$spacing-200 $spacing-100});
        cursor: pointer;
        font-weight: inherit;
        text-transform: inherit;
        text-align: inherit;
        font-size: inherit;

        &:hover:not(.disabled),
        &:focus-within {
            --checkbox-border-color: #{$color-navy};
        }

        &:active:not(.disabled) {
            --checkbox-background-color: #{$color-navy};

            .box {
                transform: scale3d(0.9, 0.9, 1);
            }
        }
    }

    .checkbox-container {
        @include padding-end($spacing-100);
        display: flex;
        align-items: center;
    }

    .box {
        @include transition(transform, border-color, background-color);

        display: flex;
        justify-content: center;
        align-items: center;

        height: var(--checkbox-size);
        width: var(--checkbox-size);

        background-color: var(--checkbox-background-color);
        border: 2px solid var(--checkbox-border-color);
        border-radius: 2px;
    }

    .box-icon {
        @include transition(transform, border-color, background-color);

        --icon-size: var(--checkbox-check-size);

        transform: scale(0);
        height: var(--checkbox-size);
        color: var(--checkbox-check-color);
    }

    .label-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .selected .box-icon,
    .partial .box-icon {
        transform: scale(1);
    }

    .selected,
    .partial {
        --checkbox-border-color: #{$color-blue};
        --checkbox-background-color: #{$color-blue};
    }

    .disabled {
        cursor: not-allowed;
    }

    .disabled,
    .disabled.error {
        --checkbox-border-color: #{$color-gray-600};
        --checkbox-background-color: #{$color-ink-200};
    }

    .disabled.selected,
    .disabled.partial {
        --checkbox-border-color: transparent;
        --checkbox-background-color: #{$color-ink-200};
    }

    .error {
        --checkbox-border-color: #{$color-red};

        &:hover:not(.disabled),
        &:focus-within {
            --checkbox-border-color: #{$color-red-900};
        }

        &:active:not(.disabled) {
            --checkbox-background-color: #{$color-red-900};
        }
    }

    .error.selected,
    .error.partial {
        --checkbox-border-color: transparent;
        --checkbox-background-color: #{$color-red};
    }

    .task {
        &.selected,
        &.partial {
            --checkbox-border-color: #{$color-gray-600};
            --checkbox-check-color: #{$color-ink-600};
            --checkbox-background-color: #{$color-gray-100};
            --checkbox-check-size: 1rem;

            &:hover:not(.disabled),
            &:focus-within {
                --checkbox-check-color: #{$color-gray};
            }

            &:active {
                --checkbox-check-color: #{$color-gray-900};
                --checkbox-background-color: #{$color-gray-100};
            }
        }

        &.selected {
            &:hover:not(.disabled),
            &:focus-within {
                --checkbox-border-color: #{$color-gray-600};
                --checkbox-check-color: #{$color-gray};
            }
        }
    }
</style>

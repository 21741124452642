<template>
    <div class="modal-header" :class="{ sticky, fullscreen, }">
        <div class="modal-header-leading">
            <slot name="leading" />
        </div>

        <div class="modal-header-main">
            <slot name="title" />
        </div>

        <div class="modal-header-trailing">
            <slot name="trailing" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'DsModalHeader',

    compatConfig: { MODE: 3 },

    props: {
        /**
         * Use sticky header on desktop as well as mobile
         */
        sticky: Boolean,

        /**
         * Use fullscreen header
         */
        fullscreen: Boolean,
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .modal-header {
        --hover-icon-color: #{$color-gray};
        background-color: $color-paper;
        border-bottom: var(--modal-header-border, 1px solid #{$color-ink-200});
        display: flex;
        align-items: center;
        height: $modal-header-height;
        min-height: $modal-header-height;
        padding: $spacing-100;

        @media($medium) {
            border-top-right-radius: $border-radius;
            border-top-left-radius: $border-radius;
        }

        @media($small) {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            right: 0;
            left: 0;
            z-index: 10;
        }

        &.fullscreen {
            border-radius: 0;
        }

        &.sticky {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 10;
        }

        .modal-header-main {
            flex: 1;
            padding: 0 $spacing-100;

            // Deprecated style left to support existing slot usage
            h4 {
                margin: 0;
                max-height: px-to-rem(24px);
            }

            // Deprecated style left to support existing slot usage
            p {
                margin: 0;
                color: $color-text-subtle;
                font-size: $font-size-xs;
            }
        }

        .modal-header-trailing {
            display: flex;
            align-items: center;
        }
    }
</style>

<template>
    <div class="html-preview">
        <header v-if="!hideHeader">
            <div v-show="$slots.left">
                <slot name="left" />
            </div>

            <div :class="{ center: !$slots.left }">
                <Icon
                    :class="['desktop', { active: isDesktop }]"
                    name="view-desktop"
                    @click="setView('desktop')"
                />

                <Icon
                    :class="{ active: isMobile }"
                    name="view-mobile"
                    @click="setView('mobile')"
                />
            </div>
        </header>

        <div v-show="$slots.context">
            <slot name="context" />
        </div>

        <div :class="['iframe-wrapper', view]">
            <div class="phone">
                <div v-if="loading" class="frame placeholder">
                    <div class="title" :style="{ width: `${random(30, 50)}%` }">
                        <Placeholder :rows="tall" />
                    </div>

                    <div v-for="n in random(4, 8)" :key="n" class="section">
                        <div
                            class="sub-title"
                            :style="{ width: `${random(25, 35)}%` }"
                        >
                            <Placeholder :rows="medium" />
                        </div>

                        <div
                            v-for="m in random(4, 6)"
                            :key="m"
                            class="content"
                            :style="{ width: `${random(90, 100)}%` }"
                        >
                            <Placeholder :rows="short" />
                        </div>
                    </div>
                </div>

                <iframe
                    v-else
                    ref="frame"
                    class="frame"
                    :srcdoc="html"
                    sandbox="allow-scripts allow-popups"
                    frameborder="0"
                />
                <div
                    v-if="interceptIframeClicks"
                    class="iframe-click-interceptor"
                    @click="onClickIframeClickInterceptor"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { Icon } from '../Icon';
import { Placeholder } from '../Placeholder';

import '../../assets/icons/view-desktop.svg';
import '../../assets/icons/view-mobile.svg';

export default {
    name: 'DsHtmlPreview',

    compatConfig: { MODE: 3 },

    components: {
        Icon,
        Placeholder,
    },

    props: {
        html: String,
        disableScripts: Boolean,
        hideHeader: Boolean,
        loading: Boolean,
        interceptIframeClicks: Boolean,
    },

    emits: ['click'],

    data() {
        return {
            view: 'desktop',
            short: [{ height: '.5rem', boxes: [1] }],
            medium: [{ height: '0.8rem', boxes: [1] }],
            tall: [{ height: '1.25rem', boxes: [1] }],
        };
    },

    computed: {
        isDesktop() {
            return this.view === 'desktop';
        },

        isMobile() {
            return this.view === 'mobile';
        },
    },

    methods: {
        random(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },

        setView(view) {
            this.view = view;
        },

        onClickIframeClickInterceptor() {
            this.$emit('click');
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .html-preview {
        background-color: $color-paper;
        border: var(--html-preview-border, $input-border);
        border-radius: var(--html-preview-border-radius, $border-radius);
        box-shadow: $elevation-z1;

        .placeholder {
            padding: $spacing-400;

            .title {
                width: 30%;
                padding-bottom: $spacing-200;
            }

            .sub-title {
                width: 50%;
                padding: $spacing-200 0;
            }

            .content {
                padding-bottom: $spacing-150;
            }
        }

        .iframe-wrapper {
            width: 100%;
            height: var(--html-preview-frame-height, #{px-to-rem(746px)});
            padding: 0;
            position: relative;
            overflow: hidden;
            -webkit-overflow-scrolling: touch;

            .frame {
                @include transition(width);

                padding: var(--html-preview-padding, 0);
                overflow: scroll;
                width: 100%;
                height: 100%;
            }

            .phone {
                -webkit-overflow-scrolling: touch;
                bottom: 0;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
            }

            &.mobile {
                overflow: auto;

                @media($medium) {
                    background: url(../../assets/images/phone.png) no-repeat top center;

                    .phone {
                        width: px-to-rem(315px);
                        height: px-to-rem(565px);
                        margin: px-to-rem(83px) auto 0;
                    }
                }
            }

            .iframe-click-interceptor {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }

        header {
            --icon-color: #{$color-ink-200};

            border-bottom: $input-border;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: $spacing-200;

            > div {
                flex: 1;
            }

            > div:nth-child(2) {
                @include text-align-end;
            }

            .icon {
                &:not(:last-child) {
                    @include margin-end($spacing-200);
                }

                &.active {
                    --icon-color: #{$color-blue};
                }

                @media($small) {
                    --icon-color: #{$color-blue};

                    &.desktop {
                        display: none;
                    }
                }
            }

            .center {
                display: flex;
                justify-content: center;
            }
        }
    }
</style>

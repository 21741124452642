<template>
    <section class="ds-card-body">
        <slot />
    </section>
</template>

<script>
export default {
    name: 'DsCardBody',

    compatConfig: { MODE: 3 },
};
</script>

<style lang="scss" scoped>
@import "../../styles/common";

.ds-card-body {
    padding: $spacing-200;
}
</style>

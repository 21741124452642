export default {
    rightStart: 'right-start',
    rightEnd: 'right-end',
    leftStart: 'left-start',
    leftEnd: 'left-end',
    bottomStart: 'bottom-start',
    bottomEnd: 'bottom-end',
    topStart: 'top-start',
    topEnd: 'top-end',
};

<template>
    <label
        class="radio"
        :class="{ selected, error, disabled }"
    >
        <div class="radio-container">
            <input
                type="radio"
                :value="val"
                :checked="selected"
                :disabled="disabled"
                :required="required"
                :name="name"
                class="visually-hidden"
                @change="change"
            />

            <div class="dot" />
        </div>

        <div class="label-container">
            <!-- @slot Default slot holding radio label -->
            <slot>
                {{ label || val }}
            </slot>
        </div>
    </label>
</template>

<script>
export default {
    name: 'DsRadio',

    compatConfig: { MODE: 3 },

    props: {
        /**
         * Selected value for the radio
         */
        modelValue: String,

        /**
         * Actual value for the radio
         */
        val: {
            type: [String, Number],
            required: true,
        },

        /**
         * Required value for validation
         */
        required: Boolean,

        /**
         * Name value for validation
         */
        name: String,

        /**
         * Label for radio, value is a backup if not passed in
         */
        label: String,

        /**
         * True if selection is an error
         */
        error: Boolean,

        /**
         * Disables the radio
         */
        disabled: Boolean,
    },

    emits: ['update:modelValue'],

    computed: {
        selected() {
            return this.modelValue === this.val;
        },
    },

    methods: {
        change() {
            if (!this.disabled && !this.selected) {
                /**
                 * Emits the radio value
                */
                this.$emit('update:modelValue', this.val);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .radio {
        @include prevent-select;

        --radio-size: 1rem;
        --radio-padding: #{$spacing-200 $spacing-100};
        --radio-border-width: .125rem;
        --radio-border-color: #{$color-gray-500};
        --radio-background-color: transparent;

        display: flex;
        align-items: center;
        cursor: pointer;
        padding: var(--radio-padding);
        font-weight: inherit;
        text-transform: inherit;
        text-align: inherit;
        font-size: inherit;

        &:hover:not(.disabled),
        &:focus-within {
            --radio-border-color: #{$color-blue};
        }
    }

    .radio-container {
        @include padding-end($spacing-100);

        display: flex;
        align-items: center;
    }

    .dot {
        border-radius: 50%;
        border: 3px solid $color-paper;
        box-shadow: 0 0 0 var(--radio-border-width) var(--radio-border-color);
        background-color: var(--radio-background-color);
        width: var(--radio-size);
        height: var(--radio-size);
    }

    .label-container {
        flex: 1;
    }

    .selected {
        --radio-border-color: #{$color-blue};
        --radio-background-color: #{$color-blue};

        &:hover:not(.disabled),
        &:focus-within {
            --radio-border-color: #{$color-navy};
            --radio-background-color: #{$color-navy};
        }
    }

    .disabled {
        cursor: not-allowed;

        --radio-border-color: #{$color-gray-500};
    }

    .disabled.selected {
        --radio-background-color: #{$color-gray-500};
    }

    .error {
        --radio-border-color: #{$color-red};

        &:hover:not(.disabled),
        &:focus-within:not(.disabled) {
            --radio-border-color: #{$color-red-950};
        }
    }

    .error.selected {
        --radio-background-color: #{$color-red};

        &:hover:not(.disabled),
        &:focus-within:not(.disabled) {
            --radio-background-color: #{$color-red-950};
        }
    }
</style>

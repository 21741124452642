<template>
    <div
        :class="['dropdown-menu', { icons, touch, dense, open, 'dropdown-block': dropdownBlock }]"
    >
        <div class="menu">
            <slot name="menu" />
        </div>

        <div class="footer">
            <slot name="footer" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'DsDropdownMenu',

    compatConfig: { MODE: 3 },

    props: {
        open: Boolean,
        touch: Boolean,

        /**
         * Will the list items have icons, if so needs more padding'
         */
        icons: Boolean,

        /**
         * Enables dense menu items
         */
        dense: Boolean,

        /**
         * Sets the dropdown width to the available container width.
         */
        dropdownBlock: Boolean,
    },
};
</script>

<style lang="scss" scoped>
    @import "../../styles/common";

    .dropdown-menu {
        background-color: $color-paper;
        box-shadow: $dropdown-shadow;
        border-radius: $border-radius;
        min-width: var(--dropdown-minwidth, #{$dropdown-minwidth});
        max-width: var(--dropdown-maxwidth, initial);
        max-height: var(--dropdown-height, #{$dropdown-maxheight});
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        z-index: $zindex-dropdown;
        display: none;

        &.open {
            display: flex;
            flex-direction: column;

            .menu {
                overflow-y: auto;
            }
        }

        &-list {
            @include dropdown-list;
        }

        &-item {
            @include dropdown-list-item;
        }

        &.dense {
            .dropdown-menu-item {
                padding: $spacing-100 $spacing-200;
                font-size: $font-size-sm;
            }
        }

        &.icons {
            --icon-color: #{$color-ink-600};
            --icon-size: #{$icon-size};

            .dropdown-menu-item {
                align-items: center;
                display: flex;
            }

            .icon {
                margin-right: $spacing-200;
            }
        }

        &[data-popper-placement^="bottom-start"],
        &[data-popper-placement^="bottom-end"],
        &[data-popper-placement^="bottom"] {
            margin-top: $spacing-100;
        }

        &[data-popper-placement^="top"] {
            top: -100%;
            margin-bottom: $spacing-100;
        }
    }

    .dropdown-menu.touch {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        display: none;
        flex-direction: column;
        border-radius: 0;
        max-width: 100%;

        .menu {
            flex: 1;
        }

        &.open {
            @include animation('mobilePopoverIn');
            display: flex;
        }
    }

    .footer {
        box-shadow: $elevation-z4;
    }
</style>
